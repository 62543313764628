import React from "react";
import { __ } from "services/Translator";
import { Redirect, Route, Link, useParams } from "react-router-dom";
export default function Collection(props) {
  return (
    <div className="collection">
      <div className="container-md">
        <div className="collection_heading">{__("Collection")}</div>
        <div className="items">
          <div className="row">
            <div className="col-md-4">
              <Link to="/games/slots">
                <div className="single_item">
                  <div className="item_top">
                    <img
                      src={
                        require("../assets/images/v1/collection/thumb1.png")
                          .default
                      }
                    />
                    <div className="item_top_text">
                      {__("Biggest Slots Collection")}
                    </div>
                  </div>
                  <div className="item_bottom">
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games1.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games2.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games3.png").default
                        }
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="#">
                <div className="single_item">
                  <div className="item_top">
                    <img
                      src={
                        require("../assets/images/v1/collection/thumb2.png")
                          .default
                      }
                    />
                    <div className="item_top_text">{__("Drop'n Win")}</div>
                  </div>
                  <div className="item_bottom">
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games1.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games2.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games3.png").default
                        }
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/games/live-casino">
                <div className="single_item">
                  <div className="item_top">
                    <img
                      src={
                        require("../assets/images/v1/collection/thumb3.png")
                          .default
                      }
                    />
                    <div className="item_top_text">
                      {__("Live Games Collection")}
                    </div>
                  </div>
                  <div className="item_bottom">
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games1.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games2.png").default
                        }
                      />
                    </div>
                    <div className="game_thumb">
                      <img
                        src={
                          require("../assets/images/games/games3.png").default
                        }
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
