import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { Badge, Button } from "react-bootstrap";
import { __ } from "services/Translator";

const Banner = (props) => {

  const propsName = props.pageName;
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    setPageName(propsName);
  }, [propsName]);

  return (
    <>
      <section className={"banner " + pageName}>
        {pageName === "vip" && (
          <div className="mask_layer">
            <img
              src={
                require("assets/images/banners/vip-banner-layer.png").default
              }
            /> 
          </div>
        )}
        <div className="container-md">
          <div className="banner_text_section text-center">
            <div className="banner_headline">&nbsp;</div>
            <div className="banner_description">&nbsp;</div>

            {props.isAuthenticated ? (
              <Link
                to="/games/live-casino"
                onClick={() => {
                  props.setModalSignUpPropsData(true);
                  setTimeout(function () {
                    props.setModalSignUpPropsData(false);
                  }, 500);
                }}
                className="common_btn"
              >
                {__("Start Winning Now")}
              </Link>
            ) : (
              <>
                <Link
                  to=""
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn"
                >
                  {__("Start Winning Now")}
                </Link>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
