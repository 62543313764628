import React, { useState, useEffect, useContext } from "react";
import api from "services/api";
import GamesFilter from "elements/games_elements/GamesFilter";
import CategoryGames from "elements/games_elements/CategoryGames";
import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import { Helmet } from "react-helmet";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { __ } from "services/Translator";
import Collection from "../elements/Collection";

import ProvidersFilter from "../elements/games_elements/ProvidersFilter";
import ProviderText from "../elements/ProviderText";

export default function Games(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    "bonus-buy": "bonus_buys",
    megaways: "megaways",
    jackpot: "jackpot",
    "table-games": "table_games",
    "crash-games": "crash_games",
    popular: "popular",
    new: "new",
    search: "search_results",
    "provably-fair": "provably-fair",
    "drops-and-wins": "drops_and_wins",
    irish: "irish",
    christmas: "christmas",
  };

  const gameTitles = {
    slots: __("Slots"),
    blackjack: __("Blackjack"),
    "video-poker": __("Video Poker"),
    "live-dealer": __("Live Dealer"),
    "live-casino": __("Live Casino"),
    "bonus-buy": __("Bonus Buys"),
    megaways: __("Megaways"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    popular: __("Popular"),
    new: __("New"),
    search_results: __("Search Results"),
    "provably-fair": __("Provably Fair"),
    "crash-games": __("Crash Games"),
    "drops-and-wins": __("Drops & Wins"),
    irish: __("Irish"),
  };

  const pageTitles = {
    slots: __("Blizz Casino | Choose slots from Microgaming, Netent, Pragmatic Play & More!"),
    "live-casino": __("Blizz Casino | See all our Live Casino Games with our partners at Evolution and more!"),
    "bonus-buy": __("Blizz Casino | Buy yourself a Bonus game with these Bonus Buys!"),
    megaways: __("Blizz Casino | All your favourite Megaways Games, all in one place!"),
    jackpot: __("Blizz Casino | Change your life with our huge Progressive slots!"),
    "table-games": __("Blizz Casino | Choose from Blackjack, Roulette, Baccarat and more!"),
    popular: __("Blizz Casino | See the most popular Slots with our players today!"),
    new: __("Blizz Casino | See our Newest Games, all in one handy place!"),
    search_results: __("Blizz Casino | Play our wide range of slots and table games!"),
    "provably-fair": __("Blizz Casino | Play our Provably fair Slots - Fairness is guaranteed!"),
    all: __("Blizz Casino | Play our wide range of slots and table games!"),
    oaks: __("3 Oaks Gaming - Next-Level Online Slot Games and Entertainment"),
    bgaming: __("B Gaming - Provider of Engaging Online Slot Games"),
    belatra: __("Belatra Games - Provider of Classic Slot Games and More"),
    bsg: __("Betsoft – Best Provider of High-Quality Online Slot Games"),
    booming: __("Booming Games - Engaging and Innovative Online Slot Games"),
    booongo: __("Boongo Games - Provider of High-Quality Online Slot Games"),
    technology: __("Casino Technology - Leader in Online Slot Games and Entertainment"),
    endorphina: __("Endorphina – Play Innovative and Rewarding Online Slot Games"),
    evoplay: __("Evoplay - Innovative Provider of Online Slot Games and More"),
    fazi: __("Fazi - Play Classic Slot Games and More"),
    gamebeat: __("Gamebeat - Provider of Engaging Online Slot Games and More"),
    gamevy: __("Gamevy - Provider of Innovative and Rewarding Online Slot Games"),
    gamzix: __("Gamezix - Unique and Engaging Online Slot Games"),
    habanero: __("Habanero Games - Provider of High-Quality Online Slot Games"),
    "irondog-studios": __("Irondog Studios - Innovative Provider of Online Slot Games"),
    kagaming: __("KA Gaming - Provider of Engaging Online Slot Games and More"),
    mancala: __("Mancala Gaming - Provider of Unique and Engaging Online Slot Games"),
    mascot: __("Mascot Gaming - Engaging and Rewarding Online Slot Games"),
    merkur: __("Merkur Gaming - Innovative and Exciting Online Slot Games"),
    netgame: __("Netgame Entertainment - High-Quality Online Slot Games"),
    nolimit: __("No Limit City - Innovative Provider of Online Slot Games"),
    onlyplay: __("Onlyplay - Provider of Engaging Online Slot Games and More"),
    playson: __("Playson - High-Quality Online Slot Games and More"),
    pragmaticplay: __("Pragmatic Play - Provider of Engaging Online Slot Games and More"),
    redrakegamming: __("Red Rake Gaming - Provider of High-Quality Online Slot Games"),
    reflexgaming: __("Reflex Gaming - Provider of Engaging Online Slot Games"),
    slingshot: __("SlingShot - Innovative Online Slot Games"),
    slotfactory: __("Slotfactory - Provider of Engaging Online Slot Games and More"),
    slotmill: __("Slotmill - Provider of High-Quality Online Slot Games"),
    spinomenal: __("Spinomenal - Provider of Engaging Online Slot Games and More"),
    spribe: __("Spribe - Innovative Provider of Online Slot Games and More"),
    swintt: __("Swintt - Provider of Engaging Online Slot Games and More"),
    switchstudios: __("Switchstudios - Provider of High-Quality Online Slot Games"),
    thunderkick: __("Thunderkick - Innovative Provider of Online Slot Games"),
    tomhorn: __("Tom Horn Gaming - Provider of Engaging Online Slot Games and More"),
    truelab: __("Provider of High-Quality Online Slot Games and More"),
    wazdan: __("Wazdan - Provider of High-Quality Online Slot Games and More"),
    wizard: __("Wizzard - Innovative Provider of Online Slot Games"),
    yggdrasil: __("Yggdrassil - Provider of High-Quality Online Slot Games and More"),
    zillion: __("Zillion Games - Provider of Engaging Online Slot Games"),
  };

  const metaDescriptions = {
    slots: __(
      "Blizz Casino! All of your favourite slots in one place including Fruit Party, Rick Wilde in Book of the Dead, Gates of Olympus and more from providers such as Pragmatic Play, Microgaming and Netent!"
    ),
    "live-casino": __(
      "Blizz Casino! A wide range of games you can play as if you were in the casino itself - interact with the Dealers and Croupiers and take part in our Game Shows such as Crazy Time - all thanks to our friends at Evolution!"
    ),
    "bonus-buy": __(
      "Blizz Casino! Fed up of playing Slots where you never get the feature? With these games, you can buy the feature and with it a real chance to win big!"
    ),
    megaways: __(
      "Blizz Casino! Megaways give you a huge numbers of ways to win, each spin is different - with massive Jackpots across many of the Slots, what are you waiting for? Play today!"
    ),
    jackpot: __(
      "Blizz Casino! These Progressive Jackpots are not to be sniffed at, with many into 7 figures, one win on any of these and your life will never be the same again!"
    ),
    "table-games": __(
      "Blizz Casino! All our popular Table Games in one section, choose from different variations of Blackjack, Roulette, Baccarat and more!"
    ),
    popular: __("Blizz Casino! See what Slots your fellow players are using most and join them on an epic journey!"),
    new: __("Blizz Casino! All our newest games in one handy section - check back regularly to see what is new!"),
    search_results: __(
      "Blizz Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need to go anywhere else!"
    ),
    "provably-fair": __(
      "Blizz Casino! These Provably Fair slots have been tried and tested to ensure fairness for all - play them now, it could be you!"
    ),
    all: __(
      "Blizz Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need "
    ),
    oaks: __(
      "3 Oaks Gaming offers a next-level gaming experience with their innovative online slot games and entertainment options. Play now and discover what sets 3 Oaks Gaming apart from the competition."
    ),
    bgaming: __(
      "B Gaming is a provider of engaging online slot games that offer big rewards and exciting gameplay. From stunning graphics to innovative features, B Gaming slots are a must-try for any gaming enthusiast."
    ),
    belatra: __(
      "Belatra Games is a provider of classic slot games and more, offering a range of engaging and exciting online slots. Discover their portfolio of games and play now for a chance to win big."
    ),
    bsg: __(
      "Betsoft is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big!"
    ),
    booming: __(
      "Booming Games is a provider of engaging and innovative online slot games that offer big rewards and exciting gameplay. Discover their portfolio of games and play now for a chance to win big."
    ),
    booongo: __(
      "Boongo Games is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    technology: __(
      "Casino Technology is a leader in online slot games and entertainment, offering a range of engaging and exciting slots. Play now and discover what sets Casino Technology apart from the competition."
    ),
    endorphina: __(
      "Endorphina is a provider of innovative and rewarding online slot games that offer engaging gameplay, stunning graphics, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    evoplay: __(
      "Evoplay is an innovative provider of online slot games and more, offering a range of exciting and engaging games. Discover their portfolio of games and play now for a chance to win big."
    ),
    fazi: __(
      "Fazi is a provider of classic slot games and more, offering a range of engaging and exciting online slots. Discover their portfolio of games and play now for a chance to win big."
    ),
    gamebeat: __(
      "Gamebeat is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    gamevy: __(
      "Gamevy is a provider of innovative and rewarding online slot games that offer engaging gameplay, stunning graphics, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    gamzix: __(
      "Gamezix is a provider of unique and engaging online slot games that offer big rewards and exciting gameplay. Discover their portfolio of games and play now for a chance to win big."
    ),
    habanero: __(
      "Habanero Games is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    "irondog-studios": __(
      "Irondog Studios is an innovative provider of online slot games that offer unique gameplay, stunning graphics, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    kagaming: __(
      "KA Gaming is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    mancala: __(
      "Mancala Gaming is a provider of unique and engaging online slot games that offer big rewards and exciting gameplay"
    ),
    mascot: __(
      "Mascot Gaming is a provider of engaging and rewarding online slot games that offer big rewards, immersive gameplay, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
    merkur: __(
      "Merkur Gaming is a provider of innovative and exciting online slot games that offer engaging gameplay, big rewards, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
    netgame: __(
      "Netgame Entertainment is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    nolimit: __(
      "No Limit City is an innovative provider of online slot games that offer unique gameplay, stunning graphics, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    onlyplay: __(
      "Onlyplay is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    playson: __(
      "Playson is a provider of high-quality online slot games and more, offering a range of engaging and exciting games. Discover their portfolio of games here at Blizz.io!"
    ),
    pragmaticplay: __(
      "Pragmatic Play is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    redrakegamming: __(
      "Red Rake Gaming is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    reflexgaming: __(
      "Reflex Gaming is a provider of engaging online slot games that offer unique gameplay, big rewards, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
    slingshot: __(
      "SlingShot is an innovative provider of online slot games that offer unique gameplay, big rewards, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
    slotfactory: __(
      "Slotfactory is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    slotmill: __(
      "Slotmill is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    spinomenal: __(
      "Spinomenal is a provider of high quality slot games. Check out their library here at Blizz.io today and find you new favorite slot!"
    ),
    spribe: __(
      "Spribe is an innovative provider of online slot games and more, offering a range of exciting and unique games. Discover their portfolio of games and play now for a chance to win big."
    ),
    swintt: __(
      "Swintt is a provider of engaging online slot games and more, offering a range of exciting and immersive games. Discover their portfolio of games and play now for a chance to win big."
    ),
    switchstudios: __(
      "Switchstudios is a provider of high-quality online slot games that offer stunning graphics, immersive gameplay, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    thunderkick: __(
      "Thunderkick is an innovative provider of online slot games that offer unique gameplay, big rewards, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
    tomhorn: __(
      "Tom Horn Gaming is a provider of engaging online slot games and more, offering a range of exciting and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    truelab: __(
      "Truelab is a provider of high-quality online slot games and more, offering a range of engaging and innovative games. Discover their portfolio of games and play now for a chance to win big."
    ),
    wazdan: __(
      "Wazdan is a provider of high-quality online slot games and more, offering a range of immersive and engaging games. Discover their portfolio of games and play now for a chance to win big."
    ),
    wizard: __(
      "Wizzard is an innovative provider of online slot games that offer unique gameplay, stunning graphics, and big rewards. Discover their portfolio of games and play now for a chance to win big."
    ),
    yggdrasil: __(
      "Yggdrassil is a provider of high-quality online slot games and more, offering a range of engaging and immersive games. Discover their portfolio of games and play now for a chance to win big."
    ),
    zillion: __(
      "Zillion Games is a provider of engaging online slot games that offer unique gameplay, big rewards, and stunning graphics. Discover their portfolio of games and play now for a chance to win big."
    ),
  };

  var gameType = params.category ? params.category : "all";
  var providerId = params.provider ? params.provider : null;

  useEffect(() => {}, []);

  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [perPage, setPerPage] = useState(24);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getGames = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      // setGameLoaded(page * perPage);

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);

      setTotalGame(response.data.gameCount);
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  return (
    <>
      {providerId ? (
        <Helmet>
          <title>{pageTitles[providerId] ? pageTitles[providerId] : pageTitles["all"]}</title>
          <meta
            name="description"
            content={metaDescriptions[providerId] ? metaDescriptions[providerId] : metaDescriptions["all"]}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>{pageTitles[gameType] ? pageTitles[gameType] : pageTitles["all"]}</title>
          <meta
            name="description"
            content={metaDescriptions[gameType] ? metaDescriptions[gameType] : metaDescriptions["all"]}
          />
        </Helmet>
      )}

      <GamesFilter {...props} />
      {/* {gameType === "all" && !props.isMobileDevice && (
        <CategoryGameSlider categoryName="Recommended Games" />
      )} */}

      <ProvidersFilter {...props} />

      <CategoryGames
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        categoryName={gameTitles[gameType] ?? ""}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        isAuthenticated={props.isAuthenticated}
        setSearchStr={props.setSearchStr}
        {...props}
      />

      <ProviderText {...props} />
      <Collection {...props} />
    </>
  );
}
