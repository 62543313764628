import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory, useLocation } from "react-router-dom";
import { __ } from "services/Translator";
import { Table, Modal } from "react-bootstrap";
import moneyFormatter from "services/moneyFormatter";

export default function PlayerStats(props) {
  const closePlayerStats = () => {
    props.setShowPlayerStats(false);
  };

  useEffect(() => {
    setShowAllWallets(false);
  }, [props.showPlayerStats]);

  const [showAllWallets, setShowAllWallets] = useState(false);

  return (
    <>
      <Modal show={props.showPlayerStats} onHide={closePlayerStats}>
        <Modal.Body className="cashier_modal">
          <div onClick={closePlayerStats} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="modal_headline text-center">
            {!!props.playerStatData.player_data?.player_activated && (
              <svg
                id="Layer_2"
                height="15"
                viewBox="0 0 48 48"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 2"
              >
                <path
                  d="m44.6807 19.2949a8.1106 8.1106 0 0 1 -1.6538-1.4749 7.5758 7.5758 0 0 1 .4687-2.3008c.5166-1.749 1.16-3.9257-.08-5.6289-1.249-1.7168-3.5307-1.7744-5.3638-1.8213a7.86 7.86 0 0 1 -2.2895-.2461 7.7833 7.7833 0 0 1 -.9307-2.081c-.6116-1.7419-1.3736-3.9099-3.4141-4.573-1.98-.6435-3.7569.5791-5.3237 1.6543a7.4606 7.4606 0 0 1 -2.0933 1.1768 7.4483 7.4483 0 0 1 -2.0942-1.1768c-1.5669-1.0761-3.3443-2.2949-5.3238-1.6543-2.04.6631-2.8017 2.83-3.414 4.5723a7.9346 7.9346 0 0 1 -.9209 2.0742 7.6822 7.6822 0 0 1 -2.2994.253c-1.833.0468-4.1147.1045-5.3637 1.8212-1.24 1.7042-.5967 3.8809-.08 5.63a7.7906 7.7906 0 0 1 .4731 2.2862 7.8263 7.8263 0 0 1 -1.6587 1.4892c-1.4789 1.1279-3.3189 2.5321-3.3189 4.705s1.84 3.5771 3.3193 4.7051a8.1106 8.1106 0 0 1 1.6538 1.4749 7.5758 7.5758 0 0 1 -.4687 2.3008c-.5166 1.749-1.16 3.9258.08 5.6289 1.249 1.7168 3.5307 1.7744 5.3638 1.8213a7.86 7.86 0 0 1 2.2895.2461 7.7833 7.7833 0 0 1 .9307 2.081c.6123 1.7422 1.374 3.91 3.4145 4.5733a3.5928 3.5928 0 0 0 1.12.1787c1.5357 0 2.938-.9639 4.2037-1.833a7.4589 7.4589 0 0 1 2.0934-1.1771 7.45 7.45 0 0 1 2.0943 1.1768c1.5668 1.0761 3.3437 2.2939 5.3237 1.6543 2.04-.6631 2.8017-2.83 3.414-4.5723a7.9346 7.9346 0 0 1 .9209-2.0742 7.6822 7.6822 0 0 1 2.2994-.253c1.833-.0468 4.1147-.1045 5.3637-1.8212 1.24-1.7041.5967-3.8809.08-5.63a7.7906 7.7906 0 0 1 -.4731-2.2862 7.8263 7.8263 0 0 1 1.6587-1.4892c1.4784-1.1279 3.3184-2.5321 3.3184-4.705s-1.84-3.5771-3.3193-4.7051zm-11.7666 1.1192-10 10a1.9995 1.9995 0 0 1 -2.8282 0l-5-5a2 2 0 1 1 2.8282-2.8282l3.5859 3.586 8.5859-8.586a2 2 0 1 1 2.8282 2.8282z"
                  fill="#ffc107"
                />
              </svg>
            )}{" "}
            {props.playerStatData.player_data?.nickname}
          </div>
          <div className="paragraph text-center">
            {__("Joined on") + " " + props.playerStatData.player_data?.registration_date}
          </div>

          <br />
          {!!props.playerStatData.player_data?.anonymous || (
            <div className="otherLinkIcon">
              <Link
                onClick={() => props.setShowTipModal(props.playerStatData.player_data?.nickname)}
                title={__("Send this player a TIP")}
              >
                <svg className="svg_icon">
                  <use xlinkHref={"/icons.svg#icon-coins"} />
                </svg>
              </Link>
            </div>
          )}
          <br />
          <div className="modal_header_v1 leaderboardBtn">
            <div className="login_mode in_leaderboard">
              <Link
                onClick={() => {
                  props.setPlayerStatsTab("statistics");
                }}
                className={props.playerStatsTab === "statistics" ? "mode_btn active" : "mode_btn"}
              >
                {__("Statistics")}
              </Link>

              <Link
                onClick={() => {
                  props.setPlayerStatsTab("races");
                }}
                className={props.playerStatsTab === "races" ? "mode_btn active" : "mode_btn"}
              >
                {__("Races")}
              </Link>
            </div>
          </div>
          <div className="vip_modal_content">
            {props.playerStatsTab === "statistics" && (
              <>
                <div className="dataset heading">
                  <div className="item">Bets</div>
                  <div className="item">Wins</div>
                  <div className="item">Losses</div>
                  <div className="item">Wagered</div>
                </div>
                {!!props.playerStatData.game_stats &&
                  props.playerStatData.game_stats
                    .filter((item, index) => !!item.wallet_used)
                    .map((item, index) => (
                      <div key={index} className="dataset">
                        <div className="item">{item.total_bet_count}</div>
                        <div className="item">{item.total_win_count}</div>
                        <div className="item">{item.total_loss_count}</div>
                        <div className="item ammountDataArea">
                          <span className="ammountData">
                            {props.playerStatData.player_data.player_currency + item.total_wagered_amount}{" "}
                          </span>{" "}
                          {moneyFormatter.currencyIcon(item.wallet_used)}
                        </div>
                      </div>
                    ))}

                {showAllWallets &&
                  !!props.playerStatData.game_stats &&
                  !!props.userBalance.wallets?.length &&
                  props.userBalance.wallets
                    .filter(
                      (item, index) =>
                        props.playerStatData.game_stats.map((i, idx) => i.wallet_used).indexOf(item.currency) === -1
                    )
                    .map((item, index) => {
                      // if (!item.balance && hideEmptyWallets && index !== 0) {
                      //   return null;
                      // }
                      return (
                        <div key={index} className="dataset">
                          <div className="item">0</div>
                          <div className="item">0</div>
                          <div className="item">0</div>
                          <div className="item ammountDataArea">
                            <span className="ammountData">0 </span> {moneyFormatter.currencyIcon(item.currency)}
                          </div>
                        </div>
                      );
                    })}

                {!showAllWallets ? (
                  <>
                    <Link className="white" onClick={() => setShowAllWallets(true)} to="#">
                      {__("Show more")}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link className="white" onClick={() => setShowAllWallets(false)} to="#">
                      {__("Show less")}
                    </Link>
                  </>
                )}
              </>
            )}

            {props.playerStatsTab === "trophies" && <>Trophies</>}
            {props.playerStatsTab === "races" && (
              <>
                <div className="dataset heading">
                  <div className="item">Race</div>
                  <div className="item">Place</div>
                  <div className="item">Wagered</div>
                  <div className="item">Reward</div>
                </div>
                {!!props.playerStatData.race_stats &&
                  props.playerStatData.race_stats.map((item, index) => (
                    <div key={index} className="dataset">
                      <div className="item">{item.race_title}</div>
                      <div className="item">{item.winner_place}</div>
                      <div className="item">{item.winner_wagered}</div>
                      <div className="item">
                        {__("Cash")}: {props.playerStatData.player_data.player_currency + item.cash} <br />
                        {__("Bonus")}: {props.playerStatData.player_data.player_currency + item.bonus}
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
