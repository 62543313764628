import Translator, { __ } from "services/Translator";

//Auth module
import PrivateRoute from "routes/PrivateRoute";
import OpenRoute from "routes/OpenRoute";
import ChatRoute from "routes/ChatRoute";

// import external modules
import React, { Component, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Landing from "pages/Landing";
import SignUp from "pages/Landing";
import UserDashboard from "pages/UserDashboard";
import Promotions from "pages/Promotions";
import MonthlyRaffleLottery from "../pages/MonthlyRaffleLottery";
import MonthlyBonus from "pages/MonthlyBonus";
import Games from "pages/Games";
import GamePlay from "pages/GamePlay";
import FAQ from "pages/FAQ";
import Vip from "pages/Vip";
import VipCard from "../pages/VipCard";
import Page from "pages/Page";
import Races from "pages/Races";
import LangFixer from "pages/LangFixer";
import About from "../pages/About";
import Gifts from "../pages/Gifts";
import Chat from "elements/Chat";

// auth pages
// import Login from "pages/auth/Login";
// import Register from "pages/auth/Register";
// import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";

import Blogs from "pages/Blogs";
import BlogDetails from "pages/BlogDetails";
import CustomBonusTerms from "pages/CustomBonusTerms";
import Careers from "pages/Careers";

import Bitcoin from "pages/Bitcoin";
import Cashback from "pages/Cashback";

import RewardSystem from "pages/RewardSystem";
// const Landing = lazy(() => import("pages/Landing"));
// import { BrowserRouter, Switch, Redirect } from "react-router-dom";
function OpenRoutes() {
  // const match = useRouteMatch("/:lang/:rest");
  // console.log(match);

  return (
    <BrowserRouter basename={"/" + Translator.getSelectedLanguage().code + "/"}>
      <Switch>
        <OpenRoute exact path="/" component={Landing}></OpenRoute>
        <OpenRoute exact path="/account-activated" component={Landing}></OpenRoute>
        <OpenRoute exact path="/claim_cash" component={Landing}></OpenRoute>
        <OpenRoute exact path="/bitcoin" component={Bitcoin}></OpenRoute>
        <OpenRoute exact path="/signup" component={SignUp}></OpenRoute>
        <OpenRoute exact path="/careers" component={Careers}></OpenRoute>
        <OpenRoute exact path="/promotions" component={Promotions}></OpenRoute>
        <OpenRoute exact path="/monthly-raffle-lottery" component={MonthlyRaffleLottery}></OpenRoute>
        <OpenRoute exact path="/monthly-bonus" component={MonthlyBonus}></OpenRoute>
        <OpenRoute exact path="/terms" component={Page}></OpenRoute>
        <OpenRoute exact path="/bonus-terms" component={Page}></OpenRoute>
        <OpenRoute exact path="/bonus-terms/:promotion_id" component={CustomBonusTerms}></OpenRoute>
        <OpenRoute exact path="/about" component={About}></OpenRoute>
        <OpenRoute exact path="/privacy" component={Page}></OpenRoute>
        <OpenRoute exact path="/aml-policy" component={Page}></OpenRoute>
        <OpenRoute exact path="/legal" component={Page}></OpenRoute>
        <OpenRoute exact path="/vip-program" component={Page}></OpenRoute>
        <OpenRoute exact path="/loyalty" component={VipCard}></OpenRoute>
        {/* <OpenRoute exact path="/vip-card" component={VipCard}></OpenRoute> */}
        <OpenRoute exact path="/faq" component={FAQ}></OpenRoute>

        <OpenRoute exact path="/games/:category" component={Games}></OpenRoute>
        <OpenRoute exact path="/provider/:provider" component={Games}></OpenRoute>
        <OpenRoute exact path="/play/:slug/:mode?" component={GamePlay}></OpenRoute>

        {/* auth pages */}
        <OpenRoute exact path="/login" component={Landing}></OpenRoute>
        <OpenRoute exact path="/forgot-password" component={Landing}></OpenRoute>
        <OpenRoute exact path="/reset-password/:userId/:code" component={ResetPassword}></OpenRoute>
        <OpenRoute exact path="/races" component={Races}></OpenRoute>
        <OpenRoute exact path="/blogs" component={Blogs}></OpenRoute>
        <OpenRoute exact path="/blog-details/:slug" component={BlogDetails}></OpenRoute>
        <OpenRoute exact path="/litecoincasino" component={Cashback}></OpenRoute>
        <OpenRoute exact path="/litecoincasino" component={Cashback}></OpenRoute>
        <OpenRoute exact path="/gifts" component={Gifts} />
        <OpenRoute path="*" component={LangFixer} />
      </Switch>
    </BrowserRouter>
  );
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename={"/" + Translator.getSelectedLanguage().code + "/"}>
        <Switch>
          <PrivateRoute exact path="/" component={Landing}></PrivateRoute>

          <PrivateRoute exact path="/careers" component={Careers}></PrivateRoute>
          <PrivateRoute exact path="/bitcoin" component={Bitcoin}></PrivateRoute>
          <PrivateRoute exact path="/games/:category" component={Games}></PrivateRoute>
          <PrivateRoute exact path="/provider/:provider" component={Games}></PrivateRoute>
          <PrivateRoute exact path="/play/:slug/:mode?" component={GamePlay}></PrivateRoute>
          <PrivateRoute exact path="/dashboard/:tab?" component={UserDashboard}></PrivateRoute>

          <PrivateRoute exact path="/promotions" component={Promotions}></PrivateRoute>
          <PrivateRoute exact path="/monthly-bonus" component={MonthlyBonus}></PrivateRoute>

          <PrivateRoute exact path="/loyalty" component={VipCard}></PrivateRoute>
          {/* <PrivateRoute exact path="/vip-card" component={VipCard}></PrivateRoute> */}
          <PrivateRoute exact path="/faq" component={FAQ}></PrivateRoute>
          <PrivateRoute exact path="/terms" component={Page}></PrivateRoute>
          <PrivateRoute exact path="/bonus-terms" component={Page}></PrivateRoute>
          <PrivateRoute exact path="/bonus-terms/:promotion_id" component={CustomBonusTerms}></PrivateRoute>
          <PrivateRoute exact path="/privacy" component={Page}></PrivateRoute>
          <PrivateRoute exact path="/aml-policy" component={Page}></PrivateRoute>
          <PrivateRoute exact path="/legal" component={Page}></PrivateRoute>
          <PrivateRoute exact path="/vip-program" component={Page}></PrivateRoute>
          <ChatRoute exact path="/chat" component={Chat}></ChatRoute>
          <PrivateRoute exact path="/races" component={Races}></PrivateRoute>

          <PrivateRoute exact path="/blogs" component={Blogs}></PrivateRoute>
          <PrivateRoute exact path="/blog-details/:slug" component={BlogDetails}></PrivateRoute>
          <PrivateRoute exact path="/litecoincasino" component={Cashback}></PrivateRoute>
          <PrivateRoute exact path="/cashback" component={Cashback}></PrivateRoute>
          <PrivateRoute exact path="/rewards" component={RewardSystem}></PrivateRoute>
          <PrivateRoute exact path="/gifts" component={Gifts}></PrivateRoute>
          <PrivateRoute path="*" component={LangFixer} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
