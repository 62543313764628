import React, { useState, useEffect } from "react";
import api from "services/api";
import ls from "services/ls";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Spinner from "elements/Spinner";
import GeoRestriction from "elements/GeoRestriction";
import LeftSidebar from "elements/LeftSidebar";
import Chat from "elements/Chat";
import swal from "sweetalert";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { isMobile, isMobileByViewPort, cauculateLandScape } from "services/isMobile";
import SecurityPopup from "elements/SecurityPopup";
import StickyFooterMenu from "elements/StickyFooterMenu";
import packageJson from "base/package.json";
import moment from "moment";

// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import socket from "services/socket";

const FullLayout = ({ children, ...rest }) => {
  const [spinner, setSpinner] = useState(false);

  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(false);
  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  const [modalLoginPropsData, setModalLoginPropsData] = useState(false);
  const [modalForgotPasswordPropsData, setModalForgotPasswordPropsData] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(true);

  const showAlert = (title, message, isError, callback) => {
    swal({
      title: title,
      text: message,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  //Get Total Jackpot
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [totalJackpotReLoaded, setTotalJackpotReLoaded] = useState(Date.now());
  const getTotalJackpot = async (ev) => {
    var response = await api.get("/total-jackpot-amount");
    console.log(response);
    if (response.status === 200) {
      console.log("total-jackpot-amount", response.data.totalJackpot);
      // setUserBalance(response.data);
      setTotalJackpot(response.data.totalJackpot);
      //if total jackpot amount stays the same, it won't trigger the useEffect to re-set the dummy amount
      //We need a flag variable to trigger that
      setTotalJackpotReLoaded(Date.now());
    }
  };

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    if (response.status === 200) {
      ls.set("conversionRates", response.data.rates);
      setUserBalance(response.data);
    }
  };

  const [notificationCount, setNotifictionCount] = useState(0);
  const getNotificationsCount = async (ev) => {
    var response = await api.get("/player/notifications-count");
    if (response.status === 200) {
      setNotifictionCount(response.data);
    }
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  //JackpotTicker
  useEffect(() => {
    var interval;

    getTotalJackpot();

    interval = setInterval(() => {
      getTotalJackpot();
    }, 9000000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getNotificationsCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        // if (!socket?.client?.connected) {
        //Not all balance altering processes report balance via socket yet
        //Only game transactiond do to this point
        getUserBalance();
        // }
        getNotificationsCount();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  useEffect(() => {
    // if (isMobile()) {
    //   setShowLeftSidebar(false);
    // } else {
    //   setShowLeftSidebar(false);
    // }
  }, []);

  const readBalanceFromSocket = (balance) => {
    if (isAuthenticated) {
      var balance = JSON.parse(balance);
      ls.set("conversionRates", balance.rates);
      setUserBalance(balance);
    }
  };

  useEffect(() => {
    socket.client.on("balance", readBalanceFromSocket);
    return () => {
      socket.client.off("balance");
    };
  }, [socket?.client?.connected]);

  const [searchStr, setSearchStr] = useState(""); //Used in Header.js and Games.js
  const [providers, setProviders] = useState([]); //Used in Header.js and Games.js
  const [selectedProviders, setSelectedProviders] = useState([]); //Used in Header.js and Games.js
  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  useEffect(() => {
    getProviders();
  }, []);

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);
  const [showVaultModal, setShowVaultModal] = useState(false);
  const [showTipModal, setShowTipModal] = useState(false);

  // const [isChatVisible, setIsChatVisible] = useState(
  //   isMobileDeviceByViewPort ? false : true
  // );
  const [isChatVisible, setIsChatVisible] = useState(false);
  useEffect(() => {
    // setIsChatVisible(!isMobileDeviceByViewPort);
  }, [isMobileDeviceByViewPort]);
  const [chatInPopup, setChatInPopup] = useState(null);
  const [trigger, setTrigger] = useState(Math.random());

  const [initialSettings, setInitialSettings] = useState([]);
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  const [isRacesPageActive, setIsRacesPageActive] = useState(false);
  const getIsRacesPageActive = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setIsRacesPageActive(true);
    }
  };
  useEffect(() => {
    getIsRacesPageActive();
  }, []);

  const [showPlayerStats, setShowPlayerStats] = useState(false);
  const [playerStatsTab, setPlayerStatsTab] = useState("statistics");
  const [playerStatData, setPlayerStatData] = useState({});

  const getPlayerStatData = async (tr_id, field) => {
    setPlayerStatData({});
    setShowPlayerStats(false);
    setPlayerStatsTab("statistics");
    var response = await api.post("/player/public-statistics", {
      id: tr_id,
      id_field: field,
    });
    if (response.status === 200) {
      setPlayerStatData(response.data);
      setShowPlayerStats(true);
    }
  };

  const passableParams = {
    showPlayerStats,
    setShowPlayerStats,
    playerStatsTab,
    setPlayerStatsTab,
    playerStatData,
    setPlayerStatData,
    getPlayerStatData,
    isRacesPageActive,
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setSpinner,
    userDetails,
    userBalance,
    getUserBalance,
    setUsersWallet,
    accountProgress,
    getAccountProgress,
    searchStr,
    setSearchStr,
    providers,
    selectedProviders,
    setSelectedProviders,
    openWalletModalTab,
    setOpenWalletModalTab,
    isChatVisible,
    setIsChatVisible,
    showVaultModal,
    setShowVaultModal,
    showTipModal,
    setShowTipModal,
    supportSidebarOpen,
    setSupportSidebarOpen,
    modalSignUpPropsData,
    setModalSignUpPropsData,
    modalLoginPropsData,
    setModalLoginPropsData,
    modalForgotPasswordPropsData,
    setModalForgotPasswordPropsData,
    notificationCount,
    socket,
    showLeftSidebar,
    setShowLeftSidebar,
    trigger,
    setTrigger,
    isAnonymous,
    setIsAnonymous,
    totalJackpot,
    setTotalJackpot,
    totalJackpotReLoaded,
    initialSettings,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const location = useLocation();
  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setInPlay(playPath.includes("play"));
    setIsMobileDevice(isMobile());
  }, [playPath]);

  useEffect(() => {
    if (isAuthenticated && isChatVisible) {
      setShowLeftSidebar(false);
    }
  }, [isChatVisible, isAuthenticated]);

  useEffect(() => {
    if (showLeftSidebar) {
      setIsChatVisible(false);
    }
  }, [showLeftSidebar]);

  return (
    <>
      {spinner && <Spinner />}
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LfdbZMhAAAAADuKG8xtFhv9P1I-6PCXkBY5KnZZ"> */}
      <SecurityPopup {...passableParams} />
      <div className={"home " + (isChatVisible && isAuthenticated ? "chat_open" : "") + (inPlay ? "in_play" : "")}>
        <div className={"main_content " + (showLeftSidebar && !isMobileDevice ? "showedLeftSidebar" : "")}>
          {/* <div className="left_content"> */}
          <LeftSidebar {...passableParams} />
          <Header {...passableParams} />
          {/* </div> */}
          <div className="right_content">
            <main className={inPlay ? "in_play" : ""}>{childrenWithProps}</main>
            {(isMobileDevice && inPlay) || <Footer {...passableParams} />}
          </div>
        </div>
      </div>
      {isAuthenticated && <Chat {...passableParams} chatInPopup={chatInPopup} setChatInPopup={setChatInPopup} />}
      {isMobileDevice && (!inPlay || !isLandscape) && <StickyFooterMenu {...passableParams} />}
      <GeoRestriction {...passableParams} />
      {/* </GoogleReCaptchaProvider> */}
      <span className="d-none">{moment(packageJson.buildDate).format("DD-MM-YYYY HH:MM")}</span>
      <ScrollTo />
    </>
  );
};

export default FullLayout;
