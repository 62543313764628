import React from "react";
import { __ } from "services/Translator";
import { Link } from "react-router-dom";

export default function ThrillingBanner(props) {
  return (
    <>
      <section className="thirilling_banner">
        {props.isMobileDevice ? (
          <img
            className="thirilling_banner_bg"
            src={require("assets/images/v1/banners/quick-banner-mobile.png").default}
            alt="Blizz Casino Logo"
          />
        ) : (
          <img
            className="thirilling_banner_bg"
            src={require("assets/images/v1/banners/quick-banner.png").default}
            alt="Blizz Casino Logo"
          />
        )}

        <div className="thrilling_banner_text_area">
          <div className="quick_banner_text_section">
            <div className="headline">{__("The place for online gambling with Crypto")}</div>
            {/* <div className="title">{__("Casino Games")}</div> */}
            <div className="paragraph white">
              {__(
                "Blizz Casino gives you a great all round online casino experience. From a fantastic welcome bonus on your first 2 deposits, regular reload bonuses, online slot tournaments with extra prizes and a VIP Scheme, there is something for everyone."
              )}
              <br />
              <br />
              {__(
                "We have over 4.000 great casino games to choose from including jackpot slots which can change your life with one win, table games including many variations of Blackjack, Roulette, Baccarat and more, Live Casino and Bonus Buy slots, you will never get bored when playing with us. We work with all the big slot providers to ensure you have all the most popular and newest online slots at your fingertips, including the likes of Netent, Microgaming, Betsoft and more."
              )}
              <br />
              <br />
              {__(
                "Finally, our world class customer service team are at your disposal 24 hours a day, 7 days a week for any questions you may have!"
              )}
            </div>
            <div className="text-center">
              {props.isAuthenticated ? (
                <Link to="/games/all" className="common_btn_v1 width_100 with_shadow">
                  {__("Play Now")}
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="common_btn_v1 width_100 with_shadow"
                >
                  {__("Quick Registration")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="sports_section d-none">
        <div className="sports_betting">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                <div className="sports_betting_text">
                  <br />
                  <div className="headline">
                    <span className="light_blue">{__("T")}</span>
                    {__("hrilling")}
                  </div>
                  <div className="title">{__("Casino Games")}</div>
                  <div className="paragraph white">
                    {__(
                      "Blizz offers over 4.000 (!) of exciting slots and live casino games, provided by the world's best provider such as Pragmatic Play, NetEnt, Microgaming, Evolution, Betsoft, and many more!"
                    )}
                  </div>
                  {props.isAuthenticated ? (
                    <Link to="/games/all" className="common_btn no_margin">
                      {__("Play Now")}
                    </Link>
                  ) : (
                    <Link
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn no_margin"
                    >
                      {__("Quick Registration")}
                    </Link>
                  )}

                  <img
                    className="sports_betting_img d-block d-md-none"
                    src={require("assets/images/banners/sports_banner2.png").default}
                  />
                </div>
              </div>
              <div className="col-12 d-none d-md-block col-md-7 col-lg-7 col-xl-8">
                <div className="sports_betting_img">
                  <img src={require("assets/images/banners/sports_banner2.png").default} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
