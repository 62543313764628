import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PromotionBanner from "elements/PromotionBanner";

import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function MonthlyBonus(props) {
  const isAuthenticated = props.isAuthenticated;
  const [promoPage, setPromoPage] = useState({});
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPromoPage(response.data.page);
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    promotionsClone[index].expanded = !promotionsClone[index].expanded;
    setPromotions(promotionsClone);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Blizz Casino | Get Bonuses on your first 2 Deposits, see what else is available here!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "Blizz Casino! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
      </Helmet>

      {/* <PromotionBanner pageName="promotion" {...props} /> */}
      <div className="promotions">
        <section className="header_banner_area">
          <div className="single_banner_area">
            <img className="banner_bg desktop_banner" src={promoPage?.banner} />

            <img className="banner_bg tab_banner" src={promoPage?.banner_tab} />

            <img
              className="banner_bg mobile_banner"
              src={promoPage?.banner_mobile}
            />
            <div className="banner_musk"></div>
          </div>
        </section>

        <div className="container-md">
          <br />
          <div className="promotions_heading">Monthly raffle</div>
          <div className="new_promotions_list">
            <div className="single_item">
              <div className="row">
                <div className="col-xl-10 offset-xl-1">
                  <div className="promotions_content_area padding_30">
                    <div className="row align-items-center">
                      <div className="col-md-7 col-lg-12 col-xl-7">
                        <div className="promotion_title text-start">
                          {__("Monthly Raffle")}
                        </div>
                        <div className="promotion_title cashback text-start">
                          {__("Blizz $10k Raffle")}
                        </div>
                        <div className="promotion_link">
                          {/* <Link to="#">{__("Get The Deal!")}</Link> */}
                          {props.isAuthenticated ? (
                            <Link className="" to="/games/slots">
                              {__("Play Now")}
                            </Link>
                          ) : (
                            <Link
                              className=""
                              onClick={() => {
                                props.setModalSignUpPropsData(true);
                                setTimeout(function () {
                                  props.setModalSignUpPropsData(false);
                                }, 500);
                              }}
                            >
                              {__("Get the Deal!")}
                            </Link>
                          )}
                        </div>
                        <div className="promotion_label on_monthly_promotions">
                          <Link className="d-inline-block" to="/bonus-terms">
                            {__("Terms")}
                          </Link>
                          <Link className="d-inline-block" to="#">
                            {__("Learn more")}
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-12 col-xl-5">
                        <div className="promotions_icon margin_top_20">
                          <img
                            src={
                              require("assets/images/new-design/promotions/monthly-raffle.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="right_color_dashed"></div>
                  </div>

                  <div className="promotions_text_area promotions_content_area margin_top_70 padding_30">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="promotion_title paragraph text-start">
                          We are delighted to introduce another great way to pay
                          back to <br /> our valued players – the Blizz $10K
                          Raffle! <br />
                          Every month, we will give away prizes to 100 lucky
                          players <br />
                          including a top prize of $2,500.
                        </div>
                        <div className="more">
                          <Link to="#"> {__("Read more")}</Link>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="promotion_title paragraph text-start font_23">
                          {__("Prize breakdowns are as follows:")}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="colored_item">
                              {" "}
                              {__("1st $2,500")}
                            </div>
                            <div className="colored_item">
                              {__("2nd $1,700")}
                            </div>
                            <div className="colored_item">
                              {__("3rd $1,200")}
                            </div>
                            <div className="colored_item">
                              {__("4th-10th $200")}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="colored_item">
                              {__("11th – 20th $100")}
                            </div>
                            <div className="colored_item">
                              {__("21st – 40th $50")}
                            </div>
                            <div className="colored_item">
                              {__("41st – 100th $20")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
