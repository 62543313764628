import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import Pagination from "elements/Pagination";
import CategoryGames from "elements/games_elements/CategoryGames";
import GameItem from "elements/games_elements/GameItem";
import NoRecordsFound from "elements/NoRecordsFound";
import { __ } from "services/Translator";

export default function FavoriteGames(props) {
  // const __ = (text) => text;
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/favorites", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <h3 className="tabName white d-flex justify-content-between">
        <div className="">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.00693254 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77486 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77486 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.00693254 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C31.9951 20.242 30.3079 24.3088 27.3083 27.3083C24.3088 30.3079 20.242 31.9951 16 32ZM16 1.60001C13.152 1.60001 10.3679 2.44455 7.99979 4.02684C5.63173 5.60914 3.78604 7.85811 2.69614 10.4894C1.60624 13.1206 1.32107 16.016 1.8767 18.8093C2.43233 21.6026 3.80379 24.1685 5.81767 26.1823C7.83154 28.1962 10.3974 29.5677 13.1907 30.1233C15.984 30.6789 18.8794 30.3938 21.5106 29.3039C24.1419 28.214 26.3909 26.3683 27.9732 24.0002C29.5555 21.6321 30.4 18.8481 30.4 16C30.3956 12.1823 28.877 8.52214 26.1774 5.82258C23.4779 3.12302 19.8178 1.60445 16 1.60001Z"
              fill="white"
            />
            <path
              d="M16 28C13.3835 27.9943 10.8401 27.136 8.75521 25.5552C6.67026 23.9743 5.15733 21.757 4.4456 19.2392C4.39579 19.0374 4.42649 18.8241 4.53121 18.6445C4.63592 18.465 4.80642 18.3332 5.00659 18.2771C5.20677 18.2211 5.4209 18.2452 5.60363 18.3443C5.78637 18.4433 5.92336 18.6097 5.9856 18.808C6.66359 21.228 8.19494 23.3206 10.2964 24.6989C12.398 26.0771 14.9275 26.6478 17.4172 26.3053C19.9069 25.9629 22.1884 24.7304 23.8397 22.836C25.4911 20.9415 26.4006 18.5132 26.4 16C26.3991 15.7857 26.4825 15.5796 26.6321 15.4262C26.7818 15.2728 26.9857 15.1844 27.2 15.18C27.304 15.1778 27.4074 15.1963 27.5041 15.2344C27.6009 15.2726 27.6891 15.3296 27.7636 15.4023C27.838 15.4749 27.8973 15.5616 27.9379 15.6573C27.9785 15.7531 27.9996 15.856 28 15.96C28.0047 19.1477 26.7432 22.2068 24.4929 24.4646C22.2426 26.7224 19.1877 27.9941 16 28ZM4.8 16.8C4.58783 16.8 4.38434 16.7157 4.23431 16.5657C4.08429 16.4157 4 16.2122 4 16C4 15.7176 4.0096 15.44 4.036 15.1704C4.23011 12.4184 5.36652 9.81739 7.25389 7.80521C9.14126 5.79304 11.6643 4.49263 14.3982 4.12295C17.1322 3.75327 19.91 4.33689 22.264 5.77554C24.618 7.2142 26.4044 9.42002 27.3224 12.0216C27.359 12.1211 27.3755 12.2268 27.371 12.3327C27.3664 12.4386 27.3408 12.5425 27.2957 12.6385C27.2506 12.7344 27.1869 12.8204 27.1083 12.8915C27.0297 12.9626 26.9377 13.0174 26.8377 13.0526C26.7378 13.0879 26.6318 13.1029 26.526 13.0968C26.4201 13.0908 26.3166 13.0637 26.2213 13.0173C26.126 12.9709 26.0409 12.906 25.9709 12.8264C25.9009 12.7468 25.8474 12.6541 25.8136 12.5536C25.0177 10.2972 23.468 8.38429 21.426 7.13733C19.384 5.89036 16.9745 5.38563 14.6037 5.70824C12.2329 6.03084 10.0458 7.16104 8.41122 8.9083C6.77663 10.6556 5.79449 12.913 5.6304 15.3C5.6064 15.548 5.6 15.7704 5.6 16C5.6 16.2122 5.51571 16.4157 5.36568 16.5657C5.21566 16.7157 5.01217 16.8 4.8 16.8Z"
              fill="white"
            />
            <path
              d="M4.8 16.8H0.8C0.587827 16.8 0.384344 16.7157 0.234315 16.5657C0.0842856 16.4157 0 16.2122 0 16C0 15.7878 0.0842856 15.5843 0.234315 15.4343C0.384344 15.2843 0.587827 15.2 0.8 15.2H4.8C5.01217 15.2 5.21565 15.2843 5.36568 15.4343C5.51571 15.5843 5.6 15.7878 5.6 16C5.6 16.2122 5.51571 16.4157 5.36568 16.5657C5.21565 16.7157 5.01217 16.8 4.8 16.8Z"
              fill="white"
            />
            <path
              d="M8.08002 8.88C7.86787 8.87996 7.66442 8.79564 7.51442 8.6456L4.68642 5.81761C4.5407 5.66672 4.46006 5.46464 4.46188 5.25488C4.46371 5.04513 4.54784 4.84448 4.69617 4.69615C4.84449 4.54782 5.04514 4.46369 5.2549 4.46187C5.46466 4.46004 5.66674 4.54068 5.81762 4.68641L8.64642 7.5152C8.75804 7.62718 8.83398 7.76971 8.86465 7.92481C8.89533 8.07991 8.87935 8.24062 8.81875 8.38665C8.75815 8.53268 8.65563 8.65748 8.52415 8.74528C8.39267 8.83309 8.23813 8.87997 8.08002 8.88Z"
              fill="white"
            />
            <path
              d="M16 5.60001C15.7878 5.60001 15.5844 5.51572 15.4343 5.36569C15.2843 5.21566 15.2 5.01218 15.2 4.80001V0.800007C15.2 0.587834 15.2843 0.384351 15.4343 0.234322C15.5844 0.0842932 15.7878 7.62939e-06 16 7.62939e-06C16.2122 7.62939e-06 16.4157 0.0842932 16.5657 0.234322C16.7157 0.384351 16.8 0.587834 16.8 0.800007V4.80001C16.8 5.01218 16.7157 5.21566 16.5657 5.36569C16.4157 5.51572 16.2122 5.60001 16 5.60001Z"
              fill="white"
            />
            <path
              d="M23.92 8.88001C23.7618 8.87997 23.6072 8.83303 23.4756 8.74513C23.3441 8.65722 23.2416 8.53229 23.1811 8.38613C23.1205 8.23997 23.1047 8.07914 23.1355 7.92398C23.1664 7.76882 23.2426 7.62629 23.3544 7.51441L26.1832 4.68561C26.3341 4.53988 26.5362 4.45924 26.7459 4.46107C26.9557 4.46289 27.1563 4.54702 27.3047 4.69535C27.453 4.84368 27.5371 5.04433 27.5389 5.25408C27.5408 5.46384 27.4601 5.66592 27.3144 5.81681L24.4856 8.64561C24.3356 8.79564 24.1322 8.87996 23.92 8.88001Z"
              fill="white"
            />
            <path
              d="M31.2 16.8H27.2C26.9878 16.8 26.7843 16.7157 26.6343 16.5657C26.4843 16.4157 26.4 16.2122 26.4 16C26.4 15.7878 26.4843 15.5843 26.6343 15.4343C26.7843 15.2843 26.9878 15.2 27.2 15.2H31.2C31.4122 15.2 31.6156 15.2843 31.7657 15.4343C31.9157 15.5843 32 15.7878 32 16C32 16.2122 31.9157 16.4157 31.7657 16.5657C31.6156 16.7157 31.4122 16.8 31.2 16.8Z"
              fill="white"
            />
            <path
              d="M26.748 27.548C26.5358 27.548 26.3324 27.4636 26.1824 27.3136L23.3536 24.4848C23.2079 24.3339 23.1272 24.1318 23.1291 23.9221C23.1309 23.7123 23.215 23.5117 23.3633 23.3633C23.5117 23.215 23.7123 23.1309 23.9221 23.1291C24.1318 23.1272 24.3339 23.2079 24.4848 23.3536L27.3136 26.1824C27.4254 26.2943 27.5016 26.4368 27.5325 26.592C27.5633 26.7471 27.5475 26.908 27.4869 27.0541C27.4264 27.2003 27.3239 27.3252 27.1924 27.4131C27.0608 27.501 26.9062 27.548 26.748 27.548Z"
              fill="white"
            />
            <path
              d="M16 32C15.7878 32 15.5844 31.9157 15.4343 31.7657C15.2843 31.6157 15.2 31.4122 15.2 31.2V27.2C15.2 26.9878 15.2843 26.7843 15.4343 26.6343C15.5844 26.4843 15.7878 26.4 16 26.4C16.2122 26.4 16.4157 26.4843 16.5657 26.6343C16.7157 26.7843 16.8 26.9878 16.8 27.2V31.2C16.8 31.4122 16.7157 31.6157 16.5657 31.7657C16.4157 31.9157 16.2122 32 16 32Z"
              fill="white"
            />
            <path
              d="M5.25204 27.548C5.09384 27.548 4.9392 27.501 4.80767 27.4131C4.67614 27.3252 4.57363 27.2003 4.5131 27.0541C4.45256 26.908 4.43672 26.7471 4.46757 26.592C4.49843 26.4368 4.57459 26.2943 4.68644 26.1824L7.51524 23.3536C7.66612 23.2079 7.8682 23.1272 8.07796 23.1291C8.28772 23.1309 8.48837 23.215 8.63669 23.3633C8.78502 23.5117 8.86915 23.7123 8.87098 23.9221C8.8728 24.1318 8.79216 24.3339 8.64644 24.4848L5.81764 27.3136C5.66764 27.4636 5.46419 27.548 5.25204 27.548Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.3325 17.2386C9.62328 16.5231 9.22501 15.556 9.22501 14.5478C9.22501 13.5339 9.62747 12.5613 10.3444 11.8444C11.0613 11.1275 12.0339 10.725 13.0478 10.725C14.0616 10.725 15.0343 11.1275 15.7512 11.8444L16.0125 12.1057L16.2738 11.8444C16.9907 11.1275 17.9627 10.725 18.9765 10.725C19.9911 10.725 20.963 11.1275 21.6799 11.8444C22.3968 12.5613 22.8 13.5339 22.8 14.5478C22.8 15.556 22.4017 16.5231 21.6918 17.2386L16.5058 22.7299C16.3779 22.8655 16.1991 22.9424 16.0125 22.9424C15.8259 22.9424 15.6471 22.8655 15.5192 22.7299L10.3325 17.2386ZM16.0125 21.2752L20.7066 16.3051L20.7206 16.2911C21.1824 15.8285 21.4424 15.2018 21.4424 14.5478C21.4424 13.8938 21.1824 13.267 20.7206 12.8044C20.258 12.3419 19.6305 12.0819 18.9765 12.0819C18.3232 12.0819 17.6958 12.3419 17.2332 12.8044L16.4925 13.5458C16.227 13.8106 15.7973 13.8106 15.5325 13.5458L14.7911 12.8044C14.3292 12.3419 13.7018 12.0819 13.0478 12.0819C12.3938 12.0819 11.767 12.3419 11.3044 12.8044C10.8419 13.267 10.5826 13.8938 10.5826 14.5478C10.5826 15.2018 10.8419 15.8285 11.3044 16.2911C11.3093 16.296 11.3135 16.3002 11.3177 16.3051L16.0125 21.2752Z"
              fill="white"
            />
          </svg>{" "}
          {__("My games")}
        </div>
        <div className="notification_badge">{results.length}</div>
      </h3>
      <div className="games">
        {!!results.length ? (
          <>
            <div className="game_list my_games">
              {results.map((game, index) => (
                <GameItem
                  key={game.id}
                  isAuthenticated={props.isAuthenticated}
                  game={game}
                />
              ))}
            </div>

            <br />
            <br />
            <Pagination data={pagination} onPageChange={onPageChange} />
          </>
        ) : (
          <NoRecordsFound
            content={__("Looks like you did not add any favorite games.")}
          />
        )}
      </div>
    </>
  );
}
