import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

export default function LanguageSwitcher(props) {
  const history = useHistory();
  const [languages, setLanguages] = useState([]);
  const selectedLanguage = Translator.getSelectedLanguage();
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const switchLanguage = (lang) => {
    // var homeUrl = history.createHref({ pathname: "/" });
    // alert(homeUrl)
    var currentUrl = window.location.href;
    // alert(currentUrl);
    var currentLang = Translator.getSelectedLanguage();
    // alert(currentLang.code);
    // alert(lang.code);
    // var newUrl = currentUrl.replace(homeUrl, "/" + lang.code + "/");
    var newUrl = currentUrl.replace(
      "/" + currentLang.code + "/",
      "/" + lang.code + "/"
    );
    if (newUrl === currentUrl) {
      newUrl = currentUrl.replace("/" + currentLang.code, "/" + lang.code);
    }
    // alert(newUrl);
    Translator.setSelectedLanguage(lang);
    window.setTimeout(() => {
      window.location.href = newUrl;
    }, 1000);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Dropdown className="language_dropdown">
      <Dropdown.Toggle as={Link} className="common_btn language_dropdown_btn">
        <div className="lang_content">
          <div className="lang_img">
            <img alt="" src={selectedLanguage.flag_path} />
          </div>
          {/* <div className="lang_text">Eng</div> */}
          <div className="lang_text">{selectedLanguage.code}</div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.length > 0 &&
          languages.map((value, index) => (
            <Dropdown.Item
              key={index}
              href="javascript:"
              onClick={() => switchLanguage(value)}
            >
              <div className="lang_content">
                {/* <div className="lang_img">
                  <img alt="" src={value.flag_path} />
                </div>
                <div className="lang_text">Eng</div> */}
                <div className="lang_text">
                  {value.alias ? value.alias : value.language}
                </div>
              </div>
              <div className="clearfix"></div>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
