import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import { Button, Modal } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";

export default function Messages(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/player/messages", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/message/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  const [fullMessage, setFullMessage] = useState(null);
  const showItem = async (message) => {
    setFullMessage(message);
    var response = await api.post("/player/message/mark-read", {
      id: message.id,
    });
    if (response.status !== 200) {
      props.showAlert(null, response.data.message, true);
    }
    getResults();
  };

  return (
    <>
      <h3 className="tabName white d-flex justify-content-between">
        <div className="">
          <svg
            width="35"
            height="25"
            viewBox="0 0 35 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.1333 0.5H3C1.3492 0.5 0 1.84327 0 3.5V21.5C0 23.1573 1.35007 24.5 3 24.5H31.1333C32.7841 24.5 34.1333 23.1567 34.1333 21.5V3.5C34.1333 1.84287 32.7835 0.5 31.1333 0.5ZM30.6727 2.5C29.7027 3.47313 18.3097 14.9033 17.8416 15.3729C17.4507 15.7651 16.6829 15.7653 16.2917 15.3729L3.46067 2.5H30.6727ZM2 21.1323V3.86767L10.6043 12.5L2 21.1323ZM3.46067 22.5L12.0163 13.9165L14.8753 16.7849C16.0469 17.9603 18.0869 17.9598 19.2581 16.7849L22.1171 13.9166L30.6727 22.5H3.46067ZM32.1333 21.1323L23.529 12.5L32.1333 3.86767V21.1323Z"
              fill="white"
            />
          </svg>{" "}
          {__("Messages")}
        </div>
        <div className="notification_badge">7</div>
      </h3>
      <div className="user_blocks">
        <div className="width_100 results_v1">
          {results.length ? (
            <>
              <div className="notification_lists">
                {results.map((val, index) => (
                  <div key={index} className="single_notification">
                    <div className="notification_text">
                      <div className="notification_sub">
                        {__("Subject")}:
                        <span className="colored"> {" "}{val.message_subject}</span>
                        {!val.message_was_read && (
                          <>
                            <i className="fa fa-envelope unread_mark"></i>
                          </>
                        )}
                      </div>
                      <p
                        className="notification_date"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            val.message_body.substr(0, 150)
                          ),
                        }}
                      ></p>
                      <div className="notification_date">
                        <b>{__("From")}: </b>
                        {val.sender_name} on {val.added_time}
                      </div>
                    </div>
                    <div className="notification_action">
                      <Link onClick={() => showItem(val)} className="btn">
                        <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="25px"
                          height="20px"
                          viewBox="0 0 41.915 41.916"
                          // style="enable-background:new 0 0 41.915 41.916;"
                          space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M11.214,20.956c0,3.091-2.509,5.589-5.607,5.589C2.51,26.544,0,24.046,0,20.956c0-3.082,2.511-5.585,5.607-5.585
			C8.705,15.371,11.214,17.874,11.214,20.956z"
                                fill="#040F46"
                              />
                              <path
                                d="M26.564,20.956c0,3.091-2.509,5.589-5.606,5.589c-3.097,0-5.607-2.498-5.607-5.589c0-3.082,2.511-5.585,5.607-5.585
			C24.056,15.371,26.564,17.874,26.564,20.956z"
                                fill="#040F46"
                              />
                              <path
                                d="M41.915,20.956c0,3.091-2.509,5.589-5.607,5.589c-3.097,0-5.606-2.498-5.606-5.589c0-3.082,2.511-5.585,5.606-5.585
			C39.406,15.371,41.915,17.874,41.915,20.956z"
                                fill="#040F46"
                              />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </Link>
                      <Link onClick={() => deleteItem(val.id)} className="btn">
                        <svg
                          width="22"
                          height="26"
                          viewBox="0 0 22 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.03557 11.2929C7.38533 11.2929 7.66891 11.5765 7.66891 11.9263V19.9684C7.66891 20.3182 7.38533 20.6017 7.03557 20.6017C6.68581 20.6017 6.40224 20.3182 6.40224 19.9684V11.9263C6.40224 11.5765 6.68581 11.2929 7.03557 11.2929ZM10.3667 11.9263C10.3667 11.5765 10.6502 11.2929 11 11.2929C11.3498 11.2929 11.6333 11.5765 11.6333 11.9263V19.9684C11.6333 20.3182 11.3498 20.6017 11 20.6017C10.6502 20.6017 10.3667 20.3182 10.3667 19.9684V11.9263ZM14.3311 11.9263C14.3311 11.5765 14.6147 11.2929 14.9644 11.2929C15.3142 11.2929 15.5978 11.5765 15.5978 11.9263V19.9684C15.5978 20.3182 15.3142 20.6017 14.9644 20.6017C14.6147 20.6017 14.3311 20.3182 14.3311 19.9684V11.9263ZM2.27758 8.02785L3.25901 24.1239C3.29835 24.7692 3.83138 25.2707 4.47794 25.2707H17.522C18.1685 25.2707 18.7017 24.7692 18.7409 24.1239L19.7223 8.02785H2.27758ZM2.12289 3.10487H7.26864V2.10634C7.26864 1.34777 7.88733 0.729156 8.64582 0.729156H13.3542C14.1127 0.729156 14.7314 1.34777 14.7314 2.10634V3.10487H19.877C20.5497 3.10487 21.0983 3.65349 21.0983 4.32609V6.36543C21.0983 6.58345 20.9205 6.76126 20.7024 6.76126H1.29741C1.07939 6.76126 0.901581 6.58345 0.901581 6.36543V4.32609C0.901581 3.65341 1.45029 3.10487 2.12289 3.10487V3.10487Z"
                            fill="#040F46"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
      {fullMessage && (
        <>
          <Modal show={fullMessage} onHide={() => setFullMessage(null)}>
            <Modal.Body className="login_modal message_modal">
              <div onClick={() => setFullMessage(null)} className="modal_close">
                <i className="fal fa-times"></i>
              </div>

              <div className="message_content">
                <div className="modal_headline">{__("Message")}</div>
                <div className="message-from">
                  <b>{__("From")}: </b>
                  {fullMessage.sender_name} on {fullMessage.added_time}
                </div>
                <strong>
                  {__("Subject")}:{fullMessage.message_subject}
                </strong>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(fullMessage.message_body),
                  }}
                ></p>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
