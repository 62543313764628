import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import { __ } from "services/Translator";
export default function FreeSpins(props) {
  // const __ = (text) => text;
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/list/free-spins", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <h3 className="tabName white">{__("Free Spins")}</h3>
      <div className="user_blocks">
        <div className="width_100">
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="single_block transaction_history">
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-pencil"></i>
                      {__("Title")}
                    </div>
                    <input disabled={true} type="text" className="form-controll input_box" value={item.title} />
                  </div>

                  <div className="item">
                    <div className="input_label">
                      <i className="fas fa-calendar-alt"></i>
                      {__("Expiry Date")}
                    </div>
                    <input disabled={true} type="text" className="form-controll input_box" value={item.expiry_date} />
                  </div>

                  <div className="item">
                    <div className="input_label">
                      <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm40.3-221.3l-72-20.2c-12.1-3.4-20.6-14.4-20.6-26.7 0-15.3 12.8-27.8 28.5-27.8h45c11.2 0 21.9 3.6 30.6 10.1 3.2 2.4 7.6 2 10.4-.8l11.3-11.5c3.4-3.4 3-9-.8-12-14.6-11.6-32.6-17.9-51.6-17.9H264v-40c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v40h-7.8c-33.3 0-60.5 26.8-60.5 59.8 0 26.6 18.1 50.2 43.9 57.5l72 20.2c12.1 3.4 20.6 14.4 20.6 26.7 0 15.3-12.8 27.8-28.5 27.8h-45c-11.2 0-21.9-3.6-30.6-10.1-3.2-2.4-7.6-2-10.4.8l-11.3 11.5c-3.4 3.4-3 9 .8 12 14.6 11.6 32.6 17.9 51.6 17.9h5.2v40c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-40h7.8c33.3 0 60.5-26.8 60.5-59.8-.1-26.6-18.1-50.2-44-57.5z" />
                      </svg>
                      {__("Spins")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.original_free_spins}
                    />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm40.3-221.3l-72-20.2c-12.1-3.4-20.6-14.4-20.6-26.7 0-15.3 12.8-27.8 28.5-27.8h45c11.2 0 21.9 3.6 30.6 10.1 3.2 2.4 7.6 2 10.4-.8l11.3-11.5c3.4-3.4 3-9-.8-12-14.6-11.6-32.6-17.9-51.6-17.9H264v-40c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v40h-7.8c-33.3 0-60.5 26.8-60.5 59.8 0 26.6 18.1 50.2 43.9 57.5l72 20.2c12.1 3.4 20.6 14.4 20.6 26.7 0 15.3-12.8 27.8-28.5 27.8h-45c-11.2 0-21.9-3.6-30.6-10.1-3.2-2.4-7.6-2-10.4.8l-11.3 11.5c-3.4 3.4-3 9 .8 12 14.6 11.6 32.6 17.9 51.6 17.9h5.2v40c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-40h7.8c33.3 0 60.5-26.8 60.5-59.8-.1-26.6-18.1-50.2-44-57.5z" />
                      </svg>
                      {__("Remaining")}
                    </div>
                    <input disabled={true} type="text" className="form-controll input_box" value={item.free_spins} />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-info-circle"></i>
                      {__("Games")}
                    </div>
                    {item.games.length ? (
                      <>
                        {item.games.map((game, i) => (
                          <div key={i}>
                            <Link className="white" to={"/play/" + game.slug + (props.isAuthenticated ? "" : "/demo")}>
                              {game.game_name}
                            </Link>
                          </div>
                        ))}
                      </>
                    ) : (
                      <strong>{__("Any supported game")}</strong>
                    )}
                  </div>
                </div>
              ))}

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
}
