import React, { useState, useRef, useEffect } from "react";
import { __ } from "services/Translator";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

export default function ProvidersFilter(props) {
  const history = useHistory();
  const location = useLocation();
  const [providersList, setProvidersList] = useState(false);

  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }

    setSelectedProviders(providersClone);
    if (providersClone.length === 1) {
      history.push("/provider/" + providersClone[0]);
    } else {
      history.push("/games/all");
    }
  };

  const closeProviderList = () => {
    setProvidersList(false);
  };

  useEffect(() => {
    var route = location.pathname;
    if (route.indexOf("/provider/") === 0) {
      var providerId = route.replace("/provider/", "");
      toggleSelectedProvider(providerId);
    }
  }, []);

  const scrollElement = useRef(null);

  const scrollLeft = () => {
    scrollElement.current.scrollLeft = scrollElement.current.scrollLeft + 500;
  };

  return (
    <div className="providers_filter">
      <div className="container-md">
        <div className="filter">
          <div className="filter_left">
            <Link onClick={() => setProvidersList(true)} className="common_btn_v1">
              <span className="text">View all</span>
              <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.0367 0.535004H0.610025C0.0692965 0.535004 -0.206169 1.18796 0.181523 1.57565L5.89488 7.28901C6.12953 7.52367 6.51723 7.52367 6.75198 7.28901L12.4653 1.57565C12.8529 1.18796 12.5775 0.535004 12.0367 0.535004Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div className="items" ref={scrollElement}>
            {providers.map((item, index) => (
              <Link
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn_v1  " +
                  (item.name.length > 12 ? "smallText " : "") +
                  (selectedProviders.indexOf(item.provider_id) !== -1 ? "active" : "inactive")
                }
              >
                {item.logo ? (
                  <img className={"img_logo " + item.provider_slug} src={item.logo} />
                ) : (
                  <div className={"text_logo " + item.provider_slug}>{item.name}</div>
                )}
              </Link>
            ))}
          </div>
          <div className="filter_right">
            <Link onClick={scrollLeft} className="">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M-1.17689e-06 0.732759L-5.96451e-08 14.4578C-6.77545e-09 15.1072 1.22488 15.4381 1.95215 14.9724L12.6699 8.10994C13.11 7.82809 13.11 7.36242 12.6699 7.08045L1.95215 0.217949C1.22488 -0.247598 -1.22976e-06 0.0832729 -1.17689e-06 0.732759Z"
                  fill="#4DE8FF"
                />
              </svg>
            </Link>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="clearfix"></div>
        <Modal className="providersmodal" show={providersList} onHide={closeProviderList} size="lg">
          <Modal.Body>
            <div onClick={closeProviderList} closeButton className="modal_close_custom">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.68958 12.2073L0.965439 4.78928C0.551645 4.39188 0.275784 3.79579 0.275784 3.1997C0.275784 2.60361 0.551645 2.00752 0.965439 1.61012C1.86199 0.7491 3.37923 0.7491 4.27578 1.61012L11.9999 9.02815L19.7241 1.61012C20.6206 0.7491 22.1379 0.7491 23.0344 1.61012C23.4482 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4482 4.39188 23.0344 4.78928L15.3103 12.2073L23.0344 19.6253C23.4482 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4482 22.4071 23.0344 22.8045C22.6206 23.2019 21.9999 23.4668 21.3792 23.4668C20.7585 23.4668 20.1379 23.2019 19.7241 22.8045L11.9999 15.3865L4.27578 22.8045C3.37923 23.6655 1.86199 23.6655 0.965439 22.8045C0.551645 22.4071 0.275784 21.811 0.275784 21.2149C0.275784 20.6188 0.551645 20.0227 0.965439 19.6253L8.68958 12.2073Z"
                  fill="#10294F"
                />
              </svg>
            </div>
            <div className="modal_heading">{__("All providers")}</div>
            <div className="items">
              <Link
                className={"common_btn_v1 " + (selectedProviders.length ? "inactive" : "active")}
                onClick={() => setSelectedProviders([])}
              >
                {__("All Providers")}
              </Link>

              {providers.map((item, index) => (
                <Link
                  onClick={() => toggleSelectedProvider(item.provider_id)}
                  key={index}
                  className={
                    "common_btn_v1  " +
                    (item.name.length > 12 ? "smallText " : "") +
                    (selectedProviders.indexOf(item.provider_id) !== -1 ? "active" : "inactive")
                  }
                >
                  {item.logo ? (
                    <img className={"img_logo " + item.provider_slug} src={item.logo} />
                  ) : (
                    <div className={"text_logo" + item.provider_slug}>{item.name}</div>
                  )}
                </Link>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
