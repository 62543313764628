import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import InputBox from "elements/InputBox";
import config from "configs/config";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";

function VaultModals(props) {
  // const __ = (text) => text;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const [vaults, setVaults] = useState([]);

  const [depositWallet, setDepositWallet] = useState("BTC");
  const [depositAmount, setDepositAmount] = useState("0.0");

  useEffect(() => {
    setDepositAmount("0.0");
  }, [depositWallet]);

  const setMaxDeposit = () => {
    var wallet = vaults.filter((item) => item.currency === depositWallet)[0];
    var amount = "";
    if (wallet) {
      amount = showBalanceAsFiat
        ? moneyFormatter
            .getFiatValue(
              wallet.balance,
              wallet.currency,
              fiatCurrencyToDisplay
            )
            .toFixed(2)
        : parseFloat(wallet.balance).toFixed(8);
    }
    setDepositAmount(amount);
  };

  const [withdrawalWallet, setWithdrawalWallet] = useState("BTC");
  const [withdrawalAmount, setWithdrawalAmount] = useState("0.0");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setWithdrawalAmount("0.0");
  }, [withdrawalWallet]);

  const setMaxWithdrawal = () => {
    var wallet = vaults.filter((item) => item.currency === withdrawalWallet)[0];
    var amount = "";
    if (wallet) {
      amount = showBalanceAsFiat
        ? moneyFormatter
            .getFiatValue(
              wallet.vault_balance,
              wallet.currency,
              fiatCurrencyToDisplay
            )
            .toFixed(2)
        : parseFloat(wallet.vault_balance).toFixed(8);
    }
    setWithdrawalAmount(amount);
  };

  const showVaultModal = props.showVaultModal;
  const setShowVaultModal = props.setShowVaultModal;

  const [showModal, setShowModal] = useState(false);

  const getVaults = async () => {
    var response = await api.get("/vaults");
    if (response.status === 200) {
      setVaults(response.data);
    } else {
      setVaults([]);
    }
  };

  useEffect(() => {
    if (props.showVaultModal) {
      getVaults();
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.showVaultModal]);

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setDepositWallet(props.userBalance.wallets[0].currency);
      setWithdrawalWallet(props.userBalance.wallets[0].currency);
    }
  }, [props.userBalance]);

  const closeVaultModal = () => {
    setShowVaultModal(false);
  };

  const showBalanceAsFiat = !!ls.get("showBalanceAsFiat");
  const fiatCurrencyToDisplay = ls.get("fiatCurrencyToDisplay") ?? "USD";

  const [withdrawalMessage, setWithdrawalMessage] = useState(null);
  const [withdrawalMessageType, setWithdrawalMessageType] = useState("success");

  const deposit = async () => {
    var response = await api.post("/vault/deposit", {
      selectedWallet: depositWallet,
      amount: depositAmount,
      isAmountInFiat: showBalanceAsFiat,
      fiatCurrency: fiatCurrencyToDisplay,
    });
    if (response.status === 200) {
      alert("Vault deposit successful");
      setDepositAmount("0.0");
      getVaults();
    } else {
      alert(response.data.message);
    }
  };
  const withdraw = async () => {
    var response = await api.post("/vault/withdraw", {
      selectedWallet: withdrawalWallet,
      amount: withdrawalAmount,
      isAmountInFiat: showBalanceAsFiat,
      fiatCurrency: fiatCurrencyToDisplay,
      password: password,
    });
    if (response.status === 200) {
      alert("Vault withdrawal successful");
      setWithdrawalAmount("0.0");
      setPassword("");
      getVaults();
    } else {
      alert(response.data.message);
    }
  };

  // wallet tabs
  const [activeTab, setActiveTab] = useState("deposit");
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  const formatDepositWalletBalance = (walletCurrency) => {
    var wallet = vaults.filter((item) => item.currency === walletCurrency)[0];
    if (!wallet) {
      return "";
    }
    return showBalanceAsFiat
      ? moneyFormatter.convertCurrencyToFiat(
          wallet.balance,
          wallet.currency,
          fiatCurrencyToDisplay
        )
      : parseFloat(wallet.balance).toFixed(8);
  };

  const formatWithdrawalWalletBalance = (walletCurrency) => {
    var wallet = vaults.filter((item) => item.currency === walletCurrency)[0];
    if (!wallet) {
      return "";
    }
    return showBalanceAsFiat
      ? moneyFormatter.convertCurrencyToFiat(
          wallet.vault_balance,
          wallet.currency,
          fiatCurrencyToDisplay
        )
      : parseFloat(wallet.vault_balance).toFixed(8);
  };

  return (
    <>
      <Modal size="lg" show={showVaultModal} onHide={closeVaultModal}>
        <Modal.Body className="cashier_modal">
          <div onClick={closeVaultModal} className="modal_close_reg small">
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="modal_header_v1">
            <div className="modal_headline">
              <svg
                width="21"
                height="29"
                viewBox="0 0 21 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2736 28.625C15.9421 28.6303 20.5417 24.0394 20.547 18.3709C20.5499 15.2492 19.132 12.2959 16.6941 10.346V6.79551C16.6941 3.24951 13.8196 0.375 10.2736 0.375C6.7277 0.375 3.85325 3.24951 3.85325 6.79546V10.346C-0.573483 13.8866 -1.2918 20.3454 2.2488 24.7721C4.19864 27.21 7.15197 28.6279 10.2736 28.625ZM6.42141 6.79546C6.42141 4.66791 8.1461 2.94316 10.2737 2.94316C12.4013 2.94316 14.126 4.66786 14.126 6.79546V8.8359C11.6568 7.82753 8.89068 7.82753 6.42146 8.8359V6.79546H6.42141ZM10.2736 10.6477C14.5287 10.6477 17.9782 14.0971 17.9782 18.3522C17.9782 22.6073 14.5287 26.0568 10.2736 26.0568C6.01856 26.0568 2.56911 22.6074 2.56911 18.3522C2.5734 14.0989 6.02034 10.652 10.2736 10.6477Z"
                  fill="white"
                />
                <path
                  d="M8.99107 19.2807V22.2046C8.99107 22.9138 9.56595 23.4886 10.2752 23.4886C10.9844 23.4886 11.5592 22.9137 11.5592 22.2046V19.2807C12.7876 18.5715 13.2085 17.0008 12.4993 15.7725C11.7901 14.5441 10.2194 14.1233 8.99107 14.8325C7.76272 15.5417 7.34185 17.1123 8.05105 18.3406C8.27648 18.7311 8.60068 19.0553 8.99107 19.2807Z"
                  fill="white"
                />
              </svg>{" "}
              {__("Vault")}
            </div>
            <div className="login_mode in_deposit_modal">
              <Link
                to="#"
                onClick={() => toggleTab("deposit")}
                className={
                  activeTab === "deposit" ? "mode_btn active" : "mode_btn"
                }
              >
                {__("Deposit")}
              </Link>
              <Link
                to="#"
                onClick={() => toggleTab("withdraw")}
                className={
                  activeTab === "withdraw" ? "mode_btn active" : "mode_btn"
                }
              >
                {__("Withdraw")}
              </Link>
            </div>
          </div>

          {activeTab === "deposit" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <Dropdown className="currency_select_menu in_vault">
                    <Dropdown.Toggle
                      variant="success"
                      className="right select_crypto"
                      id="dropdown-basic1"
                      style={{ paddingTop: "0px" }}
                    >
                      <span className="amount">
                        {formatDepositWalletBalance(depositWallet)}
                      </span>{" "}
                      <span className="vaultCryptoIcon currency_name">
                        {moneyFormatter.currencyIcon(depositWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!vaults.length &&
                        vaults.map((item, index) => {
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div
                                className="wallets_balance"
                                onClick={() => setDepositWallet(item.currency)}
                              >
                                <span className="amount">
                                  {formatDepositWalletBalance(item.currency)}
                                </span>{" "}
                                {moneyFormatter.currencyIcon(item.currency)}
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="input_item">
                  <div className="input_label">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                    <span className="available_amount"></span>
                  </div>
                  <div className="input-group in_addon_area">
                    <InputBox
                      type="text"
                      value={depositAmount}
                      onChange={(value) => setDepositAmount(value)}
                      className="form-control name_value"
                    />
                    <div className="currency_name vaultCryptoIcon">
                      {moneyFormatter.currencyIcon(depositWallet)}
                    </div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={setMaxDeposit}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="login_button">
                  <Link
                    onClick={deposit}
                    className={
                      depositAmount < 0
                        ? "common_btn_v1 inactive margin_auto"
                        : "common_btn_v1 with_shadow margin_auto"
                    }
                    to="#"
                  >
                    {__("Deposit to Vault")}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeTab === "withdraw" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <Dropdown className="currency_select_menu in_vault">
                    <Dropdown.Toggle
                      variant="success"
                      className="right select_crypto"
                      id="dropdown-basic1"
                      style={{ paddingTop: "0px" }}
                    >
                      <span className="amount">
                        {formatWithdrawalWalletBalance(withdrawalWallet)}
                      </span>{" "}
                      <span className="vaultCryptoIcon currency_name">
                        {moneyFormatter.currencyIcon(withdrawalWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!vaults.length &&
                        vaults.map((item, index) => {
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div
                                className="wallets_balance"
                                onClick={() =>
                                  setWithdrawalWallet(item.currency)
                                }
                              >
                                <span className="amount">
                                  {formatWithdrawalWalletBalance(item.currency)}
                                </span>{" "}
                                {moneyFormatter.currencyIcon(item.currency)}
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>
                <div className="input_item">
                  <div className="input_label">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                    <span className="available_amount"></span>
                  </div>
                  <div className="input-group in_addon_area">
                    <InputBox
                      type="text"
                      value={withdrawalAmount}
                      onChange={(value) => setWithdrawalAmount(value)}
                      className="form-control name_value"
                    />
                    <div className="currency_name vaultCryptoIcon">
                      {moneyFormatter.currencyIcon(depositWallet)}
                    </div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={setMaxWithdrawal}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="input_item walletAddress">
                  <div className="input_label">
                    {__("Password")}
                    <span className="requried_icon">*</span>
                  </div>
                  <InputBox
                    type="password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                    className="form-control name_value"
                  />
                </div>
                <br />

                <div className="login_button">
                  <Link
                    onClick={withdraw}
                    className={
                      withdrawalAmount < 0
                        ? "common_btn_v1 inactive margin_auto"
                        : "common_btn_v1 with_shadow margin_auto"
                    }
                    to="#"
                  >
                    {__("Withdraw from Vault")}
                  </Link>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VaultModals;
