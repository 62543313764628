import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PromotionBanner from "elements/PromotionBanner";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";
import ShowMoreText from "react-show-more-text";

export default function Promotions(props) {
  // const __ = (text) => text;
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const isAuthenticated = props.isAuthenticated;
  const [promoPage, setPromoPage] = useState({});
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPromoPage(response.data.page);
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    promotionsClone[index].expanded = !promotionsClone[index].expanded;
    setPromotions(promotionsClone);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Blizz Casino | Get Bonuses on your first 2 Deposits, see what else is available here!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "Blizz Casino! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
      </Helmet>

      <div className="promotions">
        <section className="promotions_banner_v1 d-none">
          <div className="banner_img">
            <img
              className="promotions_banner_img"
              src={
                require("assets/images/v1/banners/promotions-banner-v1.png")
                  .default
              }
              alt="Promotions Banner"
            />
          </div>
        </section>
        <div className="promotion_banner_area">
          <div className="container-md">
            <div className="row">
              <div className="col-md-4">
                <img
                  alt=""
                  className="promotion_box_image"
                  src={
                    require("assets/images/v1/promotions/promotion-box-icon.svg")
                      .default
                  }
                />
              </div>
              <div className="col-md-4">
                <div className="single_small_banner_item">
                  <img
                    alt=""
                    className="single_small_banner_img"
                    src={
                      require("assets/images/v1/promotions/small_item_bg.png")
                        .default
                    }
                  />
                  <div className="item_content_area">
                    <div className="item_content_left">
                      <div className="promotion_sub_heading">
                        {__("Tasty Tuesday")}
                      </div>
                      <div className="promotion_heading">
                        {__("75% Bonus on Deposit")}
                      </div>

                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Deposit Now")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                    <div className="item_content_right">
                      <img
                        alt=""
                        className="tasty_tuesday_icon"
                        src={
                          require("assets/images/v1/promotions/tasty-tuesday.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="single_small_banner_item">
                  <img
                    alt=""
                    className="single_small_banner_img"
                    src={
                      require("assets/images/v1/promotions/small_item_bg.png")
                        .default
                    }
                  />
                  <div className="item_content_area">
                    <div className="item_content_left">
                      <div className="promotion_sub_heading">
                        {__("Freespins Thursday")}
                      </div>
                      <div className="promotion_heading">
                        {__("Deposit each Thursday")}
                      </div>
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Deposit Now")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                    <div className="item_content_right">
                      <img
                        alt=""
                        className="tasty_tuesday_icon"
                        src={
                          require("assets/images/v1/promotions/freespins.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single_small_banner_item">
                  <img
                    alt=""
                    className="single_small_banner_img"
                    src={
                      require("assets/images/v1/promotions/small_item_bg.png")
                        .default
                    }
                  />
                  <div className="item_content_area">
                    <div className="item_content_left">
                      <div className="promotion_sub_heading">
                        {__("1st Deposit")}
                      </div>
                      <div className="promotion_heading">
                        {__("100% up to 1 BTC!")}
                      </div>
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Deposit Now")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                    <div className="item_content_right">
                      <img
                        alt=""
                        className="tasty_tuesday_icon"
                        src={
                          require("assets/images/v1/promotions/first-deposit.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="single_small_banner_item">
                  <img
                    alt=""
                    className="single_small_banner_img"
                    src={
                      require("assets/images/v1/promotions/small_item_bg.png")
                        .default
                    }
                  />
                  <div className="item_content_area">
                    <div className="item_content_left">
                      <div className="promotion_sub_heading">
                        {__("2nd Deposit")}
                      </div>
                      <div className="promotion_heading">
                        {__("50% up to 1.2 BTC!")}
                      </div>
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Deposit Now")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                    <div className="item_content_right">
                      <img
                        alt=""
                        className="tasty_tuesday_icon"
                        src={
                          require("assets/images/v1/promotions/second-deposit.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="promotions_heading v1">{__("Promotions")}</div>
        <div className="container-md">
          <div className="promotions_list_v1">
            <div className="row">
              <div className="col-md-6">
                <div className="single_promotion_v1">
                  <div className="single_promotion_content">
                    <div className="promotions_img">
                      <img
                        className="promotions_banner_img"
                        src={
                          require("assets/images/v1/promotions/weekly-cashback.svg")
                            .default
                        }
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="promotions_text_section">
                      <div className="title">{__("Weekly Cashback")}</div>
                      <div className="title colored">{__("10% Cashback")}</div>
                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Read more"
                        less="Read less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        // width={280}
                        truncatedEndingComponent={"... "}
                      >
                        <div className="paragraph">
                          {__(
                            " We like to take care of our players here at Blizz Casino, that's why we have so many great games to choose from, multiple welcome bonuses, reloads and races. But we felt there was something missing. And then it hit us - not literally, but kind of came to us in a dream - why should a"
                          )}
                        </div>
                      </ShowMoreText>

                      <div className="more_links">
                        {/* <Link className="more" to="#">
                          {__("Read more")}
                        </Link> */}
                        <Link className="tag" to="/bonus-terms">
                          {__("Terms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="single_promotion_link">
                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        to="/games/all"
                      >
                        {__("Play Now")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single_promotion_v1">
                  <div className="single_promotion_content">
                    <div className="promotions_img">
                      <img
                        className="promotions_banner_img"
                        src={
                          require("assets/images/v1/promotions/first-deposit.svg")
                            .default
                        }
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="promotions_text_section">
                      <div className="title">{__("1st Deposit")}</div>
                      <div className="title colored">
                        {__("100% up to 1 BTC!")}
                      </div>
                      <div className="paragraph">
                        {__(
                          " At Blizz Casino, we welcome all new players and want them to feel at home. For this reason, we are giving all"
                        )}
                      </div>
                      <div className="more_links">
                        {/* <Link className="more" to="#">
                          {__("Read more")}
                        </Link> */}
                        <Link className="tag" to="/bonus-terms">
                          {__("Terms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="single_promotion_link">
                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => props.setOpenWalletModalTab("deposit")}
                      >
                        {__("Deposit Now")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single_promotion_v1">
                  <div className="single_promotion_content">
                    <div className="promotions_img">
                      <img
                        className="promotions_banner_img"
                        src={
                          require("assets/images/v1/promotions/second-deposit.svg")
                            .default
                        }
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="promotions_text_section">
                      <div className="title">{__("2nd Deposit")}</div>
                      <div className="title colored">
                        {__("50% up to 1.2BTC")}
                      </div>
                      <div className="paragraph">
                        {__(
                          " For your second deposit, the Blizz Casino team will give you a 50% Bonus of up to 1.2BTC."
                        )}
                      </div>
                      <div className="more_links">
                        {/* <Link className="more" to="#">
                          {__("Read more")}
                        </Link> */}
                        <Link className="tag" to="/bonus-terms">
                          {__("Terms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="single_promotion_link">
                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => props.setOpenWalletModalTab("deposit")}
                      >
                        {__("Deposit Now")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single_promotion_v1">
                  <div className="single_promotion_content">
                    <div className="promotions_img">
                      <img
                        className="promotions_banner_img"
                        src={
                          require("assets/images/v1/promotions/tasty-tuesday.svg")
                            .default
                        }
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="promotions_text_section">
                      <div className="title">{__("Tasty Tuesday")}</div>
                      <div className="title colored">
                        {__("75% Bonus on Deposit")}
                      </div>
                      {/* <p className="paragraph">
                        {__(
                          "Tuesday. Not the most exciting day of the week. Only 2 days into the week itself, the weekend still further away than the previous weekend.."
                        )}
                      </p> */}

                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Read more"
                        less="Read less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        // width={280}
                        truncatedEndingComponent={"... "}
                      >
                        <p className="paragraph">
                          {__(
                            "Tuesday. Not the most exciting day of the week. Only 2 days into the week itself, the weekend still further away than the previous weekend and even the halfway point of the week is some way off."
                          )}
                        </p>

                        <p className="paragraph">
                          {__(
                            "It doesn't have to be like that though, especially here at Blizz."
                          )}
                        </p>

                        <p className="paragraph">
                          {__(
                            "Make the most of Tasty Tuesday by claiming a 75% bonus when you deposit between 00.00 and 23.59 CET."
                          )}
                        </p>

                        <p className="paragraph">
                          {__(
                            "The maximum you can get in bonus funds is $2k, simply deposit and contact live chat when the funds are in your account, and the bonus will be added."
                          )}
                        </p>
                        <p className="paragraph">
                          {__(
                            "Please note that the bonus needs to be turned over 40 times before a withdrawal can be requested, the deposit needs to be in your account in full when you contact live support otherwise you won't be entitled to it and other bonus terms apply which can be found at https://blizz.io/en/bonus-terms"
                          )}
                        </p>
                      </ShowMoreText>

                      <div className="more_links">
                        {/* <Link className="more" to="#">
                          {__("Read more")}
                        </Link> */}
                        <Link className="tag" to="/bonus-terms">
                          {__("Terms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="single_promotion_link">
                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => props.setOpenWalletModalTab("deposit")}
                      >
                        {__("Deposit Now")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single_promotion_v1 full_width">
                  <div className="promotions_img">
                    <img
                      className="promotions_banner_img"
                      src={
                        require("assets/images/v1/promotions/freespins.svg")
                          .default
                      }
                      alt="Promotions Banner"
                    />
                  </div>

                  <div className="text_section">
                    <div className="title">{__("Freespins Thursday")}</div>
                    <div className="title colored">
                      {__("Depends on your 1st Deposit each Thursday")}
                    </div>
                    <p className="paragraph">
                      {__(
                        "You asked and we listened. Some of you have been requesting free spins promos for a while now, so we are very happy to oblige with our brand new Freespins Thursday promotion."
                      )}
                    </p>
                    <div className="more_links">
                      {/* <Link className="more" to="#">
                        {__("Read more")}
                      </Link> */}
                    </div>
                    <div className="single_promotion_link">
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Deposit Now")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="links">
                    <div className="more_links justify-content-end margin_top_bottom_15">
                      <Link className="tag" to="/bonus-terms">
                        {__("Terms")}
                      </Link>
                    </div>
                    <br />
                    <p className="paragraph">
                      {__(
                        "The number you get depends on your 1st deposit each Thursday:"
                      )}
                    </p>
                    <div className="pakage_lists">
                      <ul>
                        <li>{__("$10 - $49.99 gets you 5 Free Spins")}</li>
                        <li>{__("$50 - $99.99 gets you 10 Free Spins")}</li>
                        <li>{__("$100 - $199.99 gets you 20 Free Spins")}</li>
                        <li>{__("$200 - $499.99 gets you 50 Free Spins")}</li>
                        <li>
                          {__(
                            "$$500 or more and a whopping 150 Free Spins will be yours"
                          )}
                        </li>
                      </ul>
                    </div>
                    <p className="paragraph">
                      {__(
                        "Any one of those spins could be a nice amount, winnings from Freespins need to be turned over 50 times before a withdrawal can be requested, other bonus terms apply which can be found at https://blizz.io/en/bonus-terms"
                      )}
                    </p>
                    <div className="more_links">
                      {/* <Link className="more" to="#">
                        {__("Read more")}
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single_promotion_v1 full_width">
                  <div className="promotions_img">
                    <img
                      className="promotions_banner_img"
                      src={
                        require("assets/images/v1/promotions/monthly-raffle.svg")
                          .default
                      }
                      alt="Promotions Banner"
                    />
                  </div>

                  <div className="text_section">
                    <div className="title">{__("Monthly Raffle")}</div>
                    <div className="title colored">
                      {__("Blizz $10k Raffle")}
                    </div>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more="Read more"
                      less="Read less"
                      className="content-css"
                      anchorClass="show-more-less-clickable"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      <p className="paragraph">
                        {__(
                          "We are delighted to introduce another great way to pay back to our valued players – the Blizz $10K Raffle!"
                        )}{" "}
                      </p>
                      <p className="paragraph">
                        {__(
                          "Every month, we will give away prizes to 100 lucky players including a top prize of $2,500."
                        )}
                      </p>
                      <p className="paragraph">
                        {__(
                          "All you need to do in order to enter is play as you normally would. For every $10 you deposit throughout the month, you will get 1 Raffle Ticket for the big draw which takes place the 1st working day of the month for play during the month before."
                        )}
                      </p>
                    </ShowMoreText>

                    {/* <div className="more_links">
                      <Link className="more" to="/monthly-raffle-lottery">
                        {__("Read more")}
                      </Link>
                    </div> */}
                    <div className="single_promotion_link">
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          to="/games/all"
                        >
                          {__("Game Play")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="links">
                    <div className="more_links justify-content-end margin_top_bottom_15">
                      <Link className="tag" to="/bonus-terms">
                        {__("Terms")}
                      </Link>
                    </div>
                    <div className="pakage_lists">
                      <p className="paragraph">
                        {__("Prize breakdowns are as follows:")}
                      </p>
                      <ul>
                        <li>{__("1st 		$2,500")}</li>
                        <li>{__("2nd 		$1,700")}</li>
                        <li>{__("3rd 		$1,200")}</li>
                        <li>{__("4th-10th 		$200")}</li>
                        <li>{__("11th – 20th 	$100")}</li>
                        <li>{__("21st – 40th 	$50")}</li>
                        <li>{__("41st – 100th 	$20")}</li>
                      </ul>
                    </div>
                    <div className="more_links">
                      {/* <Link className="more" to="#">
                        {__("Read more")}
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 offset-md-3">
                <div className="single_promotion_v1">
                  <div className="single_promotion_content">
                    <div className="promotions_img">
                      <img
                        className="promotions_banner_img"
                        src={
                          require("assets/images/v1/promotions/pragmatic.svg")
                            .default
                        }
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="promotions_text_section">
                      <div className="title">{__("Pragmatic")}</div>
                      <div className="title colored">{__("Drops & Wins")}</div>
                      <div className="paragraph bold">
                        {__(
                          "Cash prizes all day long – It only takes a spin to win!"
                        )}
                      </div>
                      <div className="paragraph">
                        {__(
                          "Win your share of a massive €1,000,000 in cash prizes a month with Pragmatic Play's Drops and Wins 2022! there's a monthly prize pool of €500,000 for slots and another €500,000 for live games."
                        )}
                      </div>
                      <div className="more_links">
                        {/* <Link className="more" to="#">
                          {__("Read more")}
                        </Link> */}
                        <Link className="tag" to="/bonus-terms">
                          {__("Terms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="single_promotion_link">
                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        to="/games/all"
                      >
                        {__("Game Play")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="promotion_bottom_area d-none">
          <div className="container-md position_relative">
            <div className="promotion_slider">
              <div className="category_games_header">
                <div className="headline_left">
                  <h4 className="game_headline">{__("Other Promotions")}</h4>
                </div>
                <div className="slider_controlls">
                  <Button onClick={prevLoad} className="prev">
                    <i className="fas fa-chevron-left"></i>
                  </Button>
                  <Button onClick={nextLoad} className="prev next">
                    <i className="fas fa-chevron-right"></i>
                  </Button>
                </div>
              </div>
              <Flickity
                flickityRef={(carouselRef) => {
                  flkty.current = carouselRef;
                }}
                options={{
                  groupCells: true,
                  cellAlign: "left",
                  prevNextButtons: false,
                  pageDots: false,
                }}
                reloadOnUpdate
                className="custom_slider"
              >
                {promotions
                  .filter((item) => item.priority === 2)
                  .map((promotion, index) => (
                    <div className="promotion_slide" key={index}>
                      <div className="promotion_image">
                        <img src={promotion.image} />
                      </div>
                      <div className="promiton_detadils">
                        <div className="text">{promotion.title}</div>
                        <div className="icon">
                          <Link to="#">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </Flickity>
            </div>
            <br />
            <div className="promotion_bottom_banner">
              <img
                className="banner_bottom_bg d-none d-md-block"
                src={
                  require("assets/images/banners/promotion-bottom.png").default
                }
              />
              <img
                className="banner_bottom_bg d-block d-md-none"
                src={
                  require("assets/images/banners/promotion-bottom-mobile.png")
                    .default
                }
              />
              <div className="banner_text_section">
                <div className="banner_heading">
                  {__("Free VIP Weekly Bonuses Cash, Spins & more.")}
                </div>
                <div className="banner_dashed"></div>
                <div className="paragraph white text-center no_margin">
                  {__(
                    "Enjoy BIG bonuses, VIP goodies and awards that the 'regular brick' casinos give to their valued VIPs? Blizz Casino has got this and more for all our platinum and diamond VIPs"
                  )}
                  !
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <PromotionBanner pageName="promotion" {...props} /> */}
      <div className="promotions d-none">
        <section className="header_banner_area promotion_banner">
          <div className="single_banner_area">
            <img className="banner_bg desktop_banner" src={promoPage?.banner} />

            <img className="banner_bg tab_banner" src={promoPage?.banner_tab} />

            <img
              className="banner_bg mobile_banner"
              src={promoPage?.banner_mobile}
            />
            <div className="banner_musk"></div>
          </div>
        </section>
        <div className="promotions_heading">{__("Promotions")}</div>
        <div className="container-md">
          <div className="new_promotions_list">
            {promotions
              .filter((item) => item.priority === 1)
              .map((promotion, index) => (
                <div className="single_item">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="promotions_content_area">
                        <div className="row align-items-center">
                          <div className="col-md-4 col-lg-12 col-xl-5">
                            <div className="promotions_icon">
                              <img src={promotion.image} />
                            </div>
                          </div>
                          <div className="col-md-8 col-lg-12 col-xl-7">
                            <div className="promotion_title">
                              {promotion.subtitle}
                            </div>
                            <div className="promotion_title cashback">
                              {promotion.title}
                            </div>
                            <div className="promotion_link">
                              {/* <Link to="#">{__("Get The Deal!")}</Link> */}
                              {props.isAuthenticated ? (
                                <Link
                                  className=""
                                  to="#"
                                  onClick={() =>
                                    props.setOpenWalletModalTab("deposit")
                                  }
                                >
                                  {__("Get the Deal!")}
                                </Link>
                              ) : (
                                <Link
                                  className=""
                                  onClick={() => {
                                    props.setModalSignUpPropsData(true);
                                    setTimeout(function () {
                                      props.setModalSignUpPropsData(false);
                                    }, 500);
                                  }}
                                >
                                  {__("Get the Deal!")}
                                </Link>
                              )}
                            </div>
                            <div className="promotion_label">
                              <Link to="/bonus-terms">{__("Terms")}</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="promotions_text_area">
                        <div className="promotion_title">
                          {promotion.subtitle}
                        </div>
                        <div
                          className="promotion_title paragraph"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(promotion.details),
                          }}
                        ></div>
                        <div className="more">
                          {/* <Link to="#">{__("Read more")}</Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/first-deposit.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">
                          {__("1st Deposit")}
                        </div>
                        <div className="promotion_title cashback">
                          {__("100% up to 1 BTC")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">{__("1st Deposit")}</div>
                    <div className="promotion_title paragraph">
                      At Blizz Casino, we welcome all new players and want them
                      to feel at home. For this reason, we are giving all new
                      players a bonus of 100% on their first deposit up to 1BTC.
                      Double up your play-time to start your casino adventure in
                      the best way possible! Minimum Deposit 10USD
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/2nd-deposit.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">
                          {__("2nd Deposit")}
                        </div>
                        <div className="promotion_title cashback">
                          {__("50% up to 1.2 BTC")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">{__("2nd Deposit")}</div>
                    <div className="promotion_title paragraph">
                      For your second deposit, the Blizz Casino team will give
                      you a 50% Bonus of up to 1.2BTC. Minimum Deposit 10USD
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/tasty-tuesday.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">
                          {__("Tasty Tuesday")}
                        </div>
                        <div className="promotion_title cashback">
                          {__("75% Bonus on Deposit")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">{__("Tasty Tuesday")}</div>
                    <div className="promotion_title paragraph">
                      Tuesday. Not the most exciting day of the week. Only 2
                      days into the week itself, the weekend still further away
                      than the previous weekend and even the halfway point of
                      the week is some way off. It doesn’t have to be like that
                      though, especially here at Blizz.Make the most of Tasty
                      Tuesday by claiming a 75% bonus when you deposit between
                      00.00 and 23.59 CET.The maximum you can get in bonus funds
                      is $2k, simply deposit and contact live chat when the
                      funds are in your account, and the bonus will be added.
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/freespin-thursday.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">
                          {__("Freespins Thursday")}
                        </div>
                        <div className="promotion_title cashback">
                          {__("Deposit on a Thursday")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">
                      {__("Freespins Thursday")}
                    </div>
                    <div className="promotion_title paragraph">
                      You asked and we listened. Some of you have been
                      requesting free spins promos for a while now, so we are
                      very happy to oblige with our brand new{" "}
                      {__("Freespins Thursday")}
                      promotion. The number you get depends on your 1st deposit
                      each Thursday:Any one of those spins could be a nice
                      amount, winnings from Freespins need to be turned over 50
                      times before a withdrawal can be requested, other bonus
                      terms apply which can be found at
                      https://blizz.io/en/bonus-terms
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/monthly-raffle.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">
                          {__("Monthly Raffle")}
                        </div>
                        <div className="promotion_title cashback">
                          {__("Blizz $10k Raffle")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">
                      {__("Monthly Raffle")}
                    </div>
                    <div className="promotion_title paragraph">
                      We are delighted to introduce another great way to pay
                      back to our valued players – the Blizz $10K Raffle! Every
                      month, we will give away prizes to 100 lucky players
                      including a top prize of $2,500. All you need to do in
                      order to enter is play as you normally would. For every
                      $50 you deposit throughout the month, you will get 1
                      Raffle
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single_item">
              <div className="row">
                <div className="col-lg-7">
                  <div className="promotions_content_area">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-lg-12 col-xl-5">
                        <div className="promotions_icon">
                          <img
                            src={
                              require("assets/images/new-design/promotions/pragmatic.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-7">
                        <div className="promotion_title">{__("Pragamtic")}</div>
                        <div className="promotion_title cashback">
                          {__("Drops & Wins")}
                        </div>
                        <div className="promotion_link">
                          <Link to="#">{__("Get The Deal!")}</Link>
                        </div>
                        <div className="promotion_label">
                          <Link to="#">{__("Terms")}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="promotions_text_area">
                    <div className="promotion_title">{__("Pragamtic")}</div>
                    <div className="promotion_title paragraph">
                      Cash prizes all day long – It only takes a spin to win!
                    </div>
                    <div className="promotion_title paragraph">
                      Win your share of a massive €1,000,000 in cash prizes a
                      month with Pragmatic Play's Drops and Wins 2022! there's a
                      monthly prize pool of €500,000 for slots and another
                      €500,000 for live games. Every week from Thursday to
                      Wednesday, you will have a chance to win Free Cash just by
                      playing one of the qualifying Pragmatic Play games (all
                      identified by a Drops and Wins logo).
                    </div>
                    <div className="more">
                      <Link to="#">{__("Read more")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="promotion_list d-none">
            {promotions
              .filter((item) => item.priority === 1)
              .map((promotion, index) => (
                <div className="single_promotion" key={index}>
                  <div className="propotion_left">
                    <div className="promotion_headline">
                      {promotion.subtitle}
                    </div>
                    <div
                      className="paragraph white"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(promotion.details),
                      }}
                    ></div>

                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn no_margin"
                        to="#"
                        onClick={() => props.setOpenWalletModalTab("deposit")}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn no_margin"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}

                    <br />
                  </div>

                  <div className="promotion_right">
                    <div className="coins">
                      <div className="dashed"></div>
                      <div className="coin_image">
                        <img src={promotion.image} />
                      </div>
                    </div>

                    <div className="text_area">
                      <div className="terms_btn">
                        <Link
                          className="common_btn small no_margin"
                          to="/bonus-terms"
                        >
                          {__("terms")}
                        </Link>
                      </div>
                      <div className="clearfix"></div>

                      <div className="text_heading">{promotion.title}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="promotion_bottom_area d-none">
          <div className="container-md position_relative">
            <div className="promotion_slider">
              <div className="category_games_header">
                <div className="headline_left">
                  <h4 className="game_headline">{__("Other Promotions")}</h4>
                </div>
                <div className="slider_controlls">
                  <Button onClick={prevLoad} className="prev">
                    <i className="fas fa-chevron-left"></i>
                  </Button>
                  <Button onClick={nextLoad} className="prev next">
                    <i className="fas fa-chevron-right"></i>
                  </Button>
                </div>
              </div>
              <Flickity
                flickityRef={(carouselRef) => {
                  flkty.current = carouselRef;
                }}
                options={{
                  groupCells: true,
                  cellAlign: "left",
                  prevNextButtons: false,
                  pageDots: false,
                }}
                reloadOnUpdate
                className="custom_slider"
              >
                {promotions
                  .filter((item) => item.priority === 2)
                  .map((promotion, index) => (
                    <div className="promotion_slide" key={index}>
                      <div className="promotion_image">
                        <img src={promotion.image} />
                      </div>
                      <div className="promiton_detadils">
                        <div className="text">{promotion.title}</div>
                        <div className="icon">
                          <Link to="#">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </Flickity>
            </div>
            <br />
            <div className="promotion_bottom_banner">
              <img
                className="banner_bottom_bg d-none d-md-block"
                src={
                  require("assets/images/banners/promotion-bottom.png").default
                }
              />
              <img
                className="banner_bottom_bg d-block d-md-none"
                src={
                  require("assets/images/banners/promotion-bottom-mobile.png")
                    .default
                }
              />
              <div className="banner_text_section">
                <div className="banner_heading">
                  {__("Free VIP Weekly Bonuses Cash, Spins & more.")}
                </div>
                <div className="banner_dashed"></div>
                <div className="paragraph white text-center no_margin">
                  {__(
                    "Enjoy BIG bonuses, VIP goodies and awards that the 'regular brick' casinos give to their valued VIPs? Blizz Casino has got this and more for all our platinum and diamond VIPs"
                  )}
                  !
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
