import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import moment from "moment";
import { Button, Modal, Table, ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function LeaderBoard(props) {
  const [activeRaces, setActiveRaces] = useState([]);

  const getActiveRaces = async (raceStatus) => {
    var response = await api.get("/race/list/active");
    if (response.status === 200) {
      setActiveRaces(response.data);
    }
  };

  useEffect(() => {
    getActiveRaces();
  }, []);

  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState("");
  const [leaderBoard, setLeaderBoard] = useState([]);

  const openLeaderBoard = async (campaign) => {
    setShowLeaderBoard(true);
    setCampaignDetails(campaign);
    updateLeaderBoard(campaign);
  };

  const closeLeaderBoard = () => {
    setShowLeaderBoard(false);
  };

  const RemainintTime = ({ timestamp }) => {
    const formatTimeStamp = (timestamp) => {
      var ret = "";
      ret += parseInt(timestamp / 3600) + " : ";
      ret +=
        parseInt((timestamp % 3600) / 60)
          .toString()
          .padStart(2, "0") + " : ";
      ret += parseInt(timestamp % 60)
        .toString()
        .padStart(2, "0");
      return ret;
    };

    var latestTimeStamp = timestamp;

    const [formatted, setFormatted] = useState(formatTimeStamp(timestamp));

    useEffect(() => {
      var interval = window.setInterval(() => {
        latestTimeStamp = latestTimeStamp - 1;
        if (latestTimeStamp <= 0) {
          window.clearInterval(interval);
          return 0;
        }
        setFormatted(formatTimeStamp(latestTimeStamp));
      }, 1000);
      return () => {
        window.clearInterval(interval);
      };
    }, [timestamp]);
    return <>{formatted}</>;
  };

  const updateLeaderBoard = async (campaign) => {
    if (campaign) {
      var response = await api.get("/race/leaderboard/" + campaign.id + "/" + 50);
      if (response.status === 200) {
        setLeaderBoard(response.data);
      }
    }
  };

  useEffect(() => {
    if (showLeaderBoard && campaignDetails) {
      var interval2 = window.setInterval(() => {
        updateLeaderBoard(campaignDetails);
      }, 10000);
    }
    return () => {
      try {
        window.clearInterval(interval2);
      } catch (ex) {}
    };
  }, [showLeaderBoard, campaignDetails]);

  const calculateGridColumnsForGameImage = (gamesCount) => {
    if (!gamesCount) {
      return "repeat(1, 1fr)";
    }
    if (gamesCount > 3) {
      gamesCount = 3;
    }
    return "repeat(" + gamesCount + ", 1fr)";
  };

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div className="leaderboard_v1">
      <div className="container-md">
        <section className="race_list_v1 leaderboard_v1 padding_top_25">
          {activeRaces.length ? (
            <>
              {activeRaces.map((item, index) => (
                <>
                  <div className="single_race_item padding_top_25">
                    {item.race_banner ? (
                      <>
                        <div className="leaderbord_content single_race_body">
                          <div className="leaderboard_games">
                            <div className="leaderboard_image">
                              <img className="" src={item.race_banner} alt="Blizz Casino " />
                            </div>
                          </div>
                          <div className="leaderboard_text">
                            <div className="info_btn d-none">
                              <OverlayTrigger overlay={<Tooltip>{renderHTML(item.lang?.description)}</Tooltip>}>
                                <svg
                                  width="31"
                                  height="30"
                                  viewBox="0 0 31 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.4994 0C7.21619 0 0.5 6.71619 0.5 14.9994C0.5 23.2825 7.21619 30 15.4994 30C23.7825 30 30.5 23.2825 30.5 14.9994C30.5 6.71619 23.7825 0 15.4994 0ZM18.6219 23.247C17.8498 23.5517 17.2352 23.7829 16.7743 23.9429C16.3146 24.1029 15.78 24.1829 15.1717 24.1829C14.2371 24.1829 13.5095 23.9543 12.9914 23.4984C12.4733 23.0425 12.2156 22.4648 12.2156 21.7625C12.2156 21.4895 12.2346 21.2102 12.2727 20.9257C12.3121 20.6413 12.3743 20.3213 12.4594 19.9619L13.4257 16.5486C13.5108 16.221 13.5844 15.9098 13.6429 15.6203C13.7013 15.3283 13.7292 15.0603 13.7292 14.8165C13.7292 14.3822 13.639 14.0775 13.46 13.906C13.2784 13.7346 12.9368 13.6508 12.4276 13.6508C12.1787 13.6508 11.9222 13.6876 11.6594 13.7651C11.399 13.8451 11.173 13.9175 10.9876 13.9886L11.2429 12.9371C11.8752 12.6794 12.481 12.4584 13.0587 12.2756C13.6365 12.0902 14.1825 11.9987 14.6968 11.9987C15.6251 11.9987 16.3413 12.2248 16.8454 12.6717C17.347 13.12 17.5997 13.7029 17.5997 14.419C17.5997 14.5676 17.5819 14.8292 17.5476 15.2025C17.5133 15.5771 17.4486 15.9187 17.3546 16.2324L16.3933 19.6356C16.3146 19.9086 16.2448 20.221 16.1813 20.5702C16.119 20.9194 16.0886 21.186 16.0886 21.3651C16.0886 21.8171 16.1889 22.1257 16.3921 22.2895C16.5927 22.4533 16.9444 22.5359 17.4422 22.5359C17.6771 22.5359 17.94 22.494 18.2371 22.4127C18.5317 22.3314 18.7451 22.259 18.8797 22.1968L18.6219 23.247ZM18.4517 9.43365C18.0035 9.85016 17.4638 10.0584 16.8327 10.0584C16.2029 10.0584 15.6594 9.85016 15.2073 9.43365C14.7578 9.01714 14.5305 8.51048 14.5305 7.91873C14.5305 7.32825 14.759 6.82032 15.2073 6.4C15.6594 5.97841 16.2029 5.76889 16.8327 5.76889C17.4638 5.76889 18.0048 5.97841 18.4517 6.4C18.9 6.82032 19.1248 7.32825 19.1248 7.91873C19.1248 8.51175 18.9 9.01714 18.4517 9.43365Z"
                                    fill="#4DE8FF"
                                  />
                                </svg>
                              </OverlayTrigger>
                            </div>
                            <div className="leaderboard_text_area">
                              <div className="headline">{item.lang?.title}</div>
                              <div className="title small bold">{item.lang?.campaign_information}</div>
                              {item.ending_in ? (
                                <>
                                  <div className="countdown">
                                    <div className="ending_text">{__("ENDING IN")}</div>
                                    <div className="ending_clock">
                                      <RemainintTime timestamp={item.ending_in} />
                                    </div>
                                    <div className="ending_text">
                                      {__("MIN BET")} : ${item.minimum_wager}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="title small">{__("The race has")}</div>
                                  <div className="title colored">{__("Finished")}</div>
                                  <div className="title uppercase small">
                                    {__("MIN BET")} : ${item.minimum_wager}
                                  </div>
                                </>
                              )}

                              <div className="leaderbook_link">
                                <Link
                                  className="common_btn_v1 margin_auto large with_shadow"
                                  onClick={() => {
                                    openLeaderBoard(item);
                                  }}
                                >
                                  {__("View Leaderboard")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="leaderbord_content single_race_body">
                          <div className="leaderboard_games">
                            <div className="leaderboard_image">
                              {item.race_banner ? (
                                <div className="race_banner">
                                  <img src={item.race_banner} className="race_banner_image" />
                                </div>
                              ) : (
                                <div
                                  className="race_banner"
                                  style={{
                                    gridTemplateColumns: calculateGridColumnsForGameImage(item.games?.length ?? 0),
                                  }}
                                >
                                  {item.games.map((game, key) => {
                                    if (key > 2) {
                                      return null;
                                    }
                                    return <img src={game.game_snapshot_url} className="race_banner_image" />;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="leaderboard_text">
                            <div className="info_btn">
                              <OverlayTrigger overlay={<Tooltip>{renderHTML(item.lang?.description)}</Tooltip>}>
                                <svg width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.4994 0C7.21619 0 0.5 6.71619 0.5 14.9994C0.5 23.2825 7.21619 30 15.4994 30C23.7825 30 30.5 23.2825 30.5 14.9994C30.5 6.71619 23.7825 0 15.4994 0ZM18.6219 23.247C17.8498 23.5517 17.2352 23.7829 16.7743 23.9429C16.3146 24.1029 15.78 24.1829 15.1717 24.1829C14.2371 24.1829 13.5095 23.9543 12.9914 23.4984C12.4733 23.0425 12.2156 22.4648 12.2156 21.7625C12.2156 21.4895 12.2346 21.2102 12.2727 20.9257C12.3121 20.6413 12.3743 20.3213 12.4594 19.9619L13.4257 16.5486C13.5108 16.221 13.5844 15.9098 13.6429 15.6203C13.7013 15.3283 13.7292 15.0603 13.7292 14.8165C13.7292 14.3822 13.639 14.0775 13.46 13.906C13.2784 13.7346 12.9368 13.6508 12.4276 13.6508C12.1787 13.6508 11.9222 13.6876 11.6594 13.7651C11.399 13.8451 11.173 13.9175 10.9876 13.9886L11.2429 12.9371C11.8752 12.6794 12.481 12.4584 13.0587 12.2756C13.6365 12.0902 14.1825 11.9987 14.6968 11.9987C15.6251 11.9987 16.3413 12.2248 16.8454 12.6717C17.347 13.12 17.5997 13.7029 17.5997 14.419C17.5997 14.5676 17.5819 14.8292 17.5476 15.2025C17.5133 15.5771 17.4486 15.9187 17.3546 16.2324L16.3933 19.6356C16.3146 19.9086 16.2448 20.221 16.1813 20.5702C16.119 20.9194 16.0886 21.186 16.0886 21.3651C16.0886 21.8171 16.1889 22.1257 16.3921 22.2895C16.5927 22.4533 16.9444 22.5359 17.4422 22.5359C17.6771 22.5359 17.94 22.494 18.2371 22.4127C18.5317 22.3314 18.7451 22.259 18.8797 22.1968L18.6219 23.247ZM18.4517 9.43365C18.0035 9.85016 17.4638 10.0584 16.8327 10.0584C16.2029 10.0584 15.6594 9.85016 15.2073 9.43365C14.7578 9.01714 14.5305 8.51048 14.5305 7.91873C14.5305 7.32825 14.759 6.82032 15.2073 6.4C15.6594 5.97841 16.2029 5.76889 16.8327 5.76889C17.4638 5.76889 18.0048 5.97841 18.4517 6.4C18.9 6.82032 19.1248 7.32825 19.1248 7.91873C19.1248 8.51175 18.9 9.01714 18.4517 9.43365Z"
                                    fill="#4DE8FF"
                                  />
                                </svg>
                              </OverlayTrigger>
                            </div>
                            <div className="leaderboard_text_area">
                              <div className="headline">{item.lang?.title}</div>
                              <div className="title small bold">{item.lang?.campaign_information}</div>
                              {item.ending_in ? (
                                <>
                                  <div className="countdown">
                                    <div className="ending_text">{__("ENDING IN")}</div>
                                    <div className="ending_clock">
                                      <RemainintTime timestamp={item.ending_in} />
                                    </div>
                                    <div className="ending_text">
                                      {__("MIN BET")} : ${item.minimum_wager}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="title small">{__("The race has")}</div>
                                  <div className="title colored">{__("Finished")}</div>
                                  <div className="title uppercase small">
                                    {__("MIN BET")} : ${item.minimum_wager}
                                  </div>
                                </>
                              )}

                              <div className="leaderbook_link">
                                <Link
                                  className="common_btn_v1 margin_auto large with_shadow"
                                  onClick={() => {
                                    openLeaderBoard(item);
                                  }}
                                >
                                  {__("View Leaderboard")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ))}
            </>
          ) : (
            <>{__("No Data Fonud")}</>
          )}

          <Modal className="providersmodal radious_all" size="lg" onHide={closeLeaderBoard} show={showLeaderBoard}>
            <Modal.Body className="no_padding">
              <div
                onClick={() => {
                  setShowLeaderBoard(false);
                }}
                className="modal_close_custom"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.68958 12.2073L0.965439 4.78928C0.551645 4.39188 0.275784 3.79579 0.275784 3.1997C0.275784 2.60361 0.551645 2.00752 0.965439 1.61012C1.86199 0.7491 3.37923 0.7491 4.27578 1.61012L11.9999 9.02815L19.7241 1.61012C20.6206 0.7491 22.1379 0.7491 23.0344 1.61012C23.4482 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4482 4.39188 23.0344 4.78928L15.3103 12.2073L23.0344 19.6253C23.4482 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4482 22.4071 23.0344 22.8045C22.6206 23.2019 21.9999 23.4668 21.3792 23.4668C20.7585 23.4668 20.1379 23.2019 19.7241 22.8045L11.9999 15.3865L4.27578 22.8045C3.37923 23.6655 1.86199 23.6655 0.965439 22.8045C0.551645 22.4071 0.275784 21.811 0.275784 21.2149C0.275784 20.6188 0.551645 20.0227 0.965439 19.6253L8.68958 12.2073Z"
                    fill="#10294F"
                  />
                </svg>
              </div>

              <div className="leaderboard_table table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <td>
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M25.8212 2.26777C25.2892 1.7025 24.5344 1.37661 23.7564 1.37661H21.2791C21.2891 0.931033 21.2958 0.472206 21.2958 0H5.31857C5.31857 0.472141 5.32519 0.931033 5.33519 1.37661H2.84136C2.06326 1.37661 1.30849 1.7025 0.776469 2.26777C0.237761 2.83642 -0.0414972 3.61444 0.00500251 4.40254C0.450581 11.8309 4.28447 16.4927 10.3627 17.1843L9.18564 21.2808C7.78578 21.2808 6.65193 22.4147 6.65193 23.8145V26.601H19.9657V23.8145C19.9657 22.4147 18.8319 21.2808 17.432 21.2808L16.2549 17.1843C22.3199 16.486 26.1504 11.8276 26.5927 4.40254C26.6392 3.61782 26.3599 2.83973 25.8212 2.26777ZM2.66179 4.2429C2.65847 4.16978 2.68841 4.1199 2.71497 4.09327C2.76485 4.04008 2.82473 4.04008 2.84136 4.04008H5.46158C5.85728 9.27052 7.01107 12.2365 8.13167 13.9124C3.86552 12.1234 2.84798 7.34527 2.66179 4.2429ZM18.4827 13.9024C19.6033 12.2232 20.7538 9.26052 21.1494 4.03677H23.7597C23.7796 4.03677 23.8362 4.03677 23.886 4.08996C23.9127 4.11659 23.9425 4.16646 23.9392 4.23959C23.7497 7.34195 22.7356 12.1068 18.4827 13.9024Z"
                            fill="white"
                          />
                        </svg>
                      </td>
                      <td>{__("Player")}</td>
                      <td>{__("Wagered")}</td>
                      <td>{__("Cash Reward")}</td>
                      <td>{__("Bonus")}</td>
                    </tr>
                  </thead>

                  <tbody>
                    {leaderBoard.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() =>
                              item.player_login
                                ? props.getPlayerStatData(item.player_login, "player_login")
                                : props.getPlayerStatData(item.player_anonymous_tmp_id, "player_anonymous_tmp_id")
                            }
                          >
                            {item.player_name}
                          </Link>
                        </td>
                        <td>${item.wagered}</td>
                        {campaignDetails.id === 27 && index > 25 ? (
                          //Special halloween race. Should show trick or treat for winners 26 onwards as they are free spins, not cash
                          <>
                            <td colSpan={2}>
                              {item.free_spins ? item.free_spins + " " + __("free spins") : __("Trick or Treat")}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>${item.cash_prize}</td>
                            <td>${item.bonus}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>
        </section>
        <div className="leaderbord_content d-none">
          <div className="leaderboard_games">
            <div className="leaderboard_image">
              <img
                className=""
                src={require("assets/images/v1/banners/leaderboard-game-img.png").default}
                alt="Blizz Casino "
              />
            </div>
          </div>
          <div className="leaderboard_text">
            <div className="leaderboard_text_area">
              <div className="headline">$6,666 Halloween Leaderboard</div>
              <div className="countdown">
                <div className="ending_text">ENDING IN</div>
                <div className="ending_clock">14 : 32 : 45</div>
                <div className="ending_text">MIN BET : 1$</div>
              </div>
              <div className="leaderbook_link">
                <Link className="common_btn_v1 margin_auto large with_shadow" to="#">
                  View Leaderboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
