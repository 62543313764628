import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";

import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";
import { isMobile } from "services/isMobile";
import swal from "sweetalert";
import { __ } from "services/Translator";

import { Accordion, Button, Modal } from "react-bootstrap";

export default function Verify(props) {
  // const __ = (text) => text;

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });
  const { updateUserObj } = React.useContext(AppContext);
  const [poib, setPoib] = useState();
  const [poif, setPoif] = useState();
  const [sof, setSof] = useState();
  const [poa, setPoa] = useState();
  const [otherDocs, setOtherDocs] = useState([]);
  const [triggerData, setTriggerData] = useState(
    (Math.random() + 1).toString(36).substring(7)
  );

  const [level1Verification, setLevel1Verification] = useState(null);

  const [level2submitted, setLevel2submitted] = useState(false);
  const [level2Verification, setLevel2Verification] = useState(false);

  const [level3submitted, setLevel3submitted] = useState(false);
  const [level3Verification, setLevel3Verification] = useState(false);

  const [level4submitted, setLevel4submitted] = useState(false);
  const [level4Verification, setLevel4Verification] = useState(false);
  const getIdUploads = async () => {
    var response = await api.get("/id-uploads");
    console.log("id-uploadsid-uploads", response);
    if (response.status === 200 && response.data) {
      setPoib(response.data.poib);
      setPoif(response.data.poif);
      setPoa(response.data.poa);
      setSof(response.data.sof);
      setOtherDocs(response.data.other);

      if (response.data.poif && response.data.poib) {
        if (
          response.data.poif.approved == "approved" &&
          response.data.poib.approved == "approved"
        ) {
          setLevel2Verification(true);
        } else if (
          response.data.poif.approved == "submitted" &&
          response.data.poib.approved == "submitted"
        ) {
          setLevel2submitted(true);
        }
      }

      if (response.data.poa) {
        if (response.data.poa.approved == "approved") {
          setLevel3Verification(true);
        } else if (response.data.poa.approved == "submitted") {
          setLevel3submitted(true);
        }
      }
      if (response.data.sof) {
        if (response.data.sof.approved == "approved") {
          setLevel4Verification(true);
        } else if (response.data.sof.approved == "submitted") {
          setLevel4submitted(true);
        }
      }
    }
  };

  useEffect(async () => {
    getIdUploads();
  }, [triggerData]);

  const [identityFrontFileName, setIdentityFrontFileName] = useState();
  const [identityFrontFile, setIdentityFrontFile] = useState();
  // console.log(identityFrontFile);
  const pickIdentityFront = (event) => {
    setIdentityFrontFileName(event.target.files[0].name);
    setIdentityFrontFile(event.target.files[0]);
  };

  const [identityBackFileName, setIdentityBackFileName] = useState();
  const [identityBackFile, setIdentityBackFile] = useState();
  // console.log(identityBackFile);
  const pickIdentityBack = (event) => {
    setIdentityBackFileName(event.target.files[0].name);
    setIdentityBackFile(event.target.files[0]);
  };

  const [uploadIFRes, setUploadIFRes] = useState(false);
  const uploadIdentityFrontFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poif");
    formData.append("file", identityFrontFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadIFRes(true);
      setIdentityFrontFile("");
      setTriggerData((Math.random() + 1).toString(36).substring(7));
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: response.data.message,
        icon: "error",
      });
      setUploadIFRes(false);
    }
  };

  const [uploadIBRes, setUploadIBRes] = useState(false);
  const uploadIdentityBackFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poib");
    formData.append("file", identityBackFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadIBRes(true);
      setIdentityBackFile("");
      setTriggerData((Math.random() + 1).toString(36).substring(7));
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: response.data.message,
        icon: "error",
      });
      setUploadIBRes(false);
    }
  };

  const uploadProofOfIdentity = () => {
    var valid = validateProofOfIdentity();
    if (valid) {
      uploadIdentityFrontFile();
      uploadIdentityBackFile();
      
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: __("Proof Of Identity both field are required"),
        icon: "error",
      });
    }
  };

  const validateProofOfIdentity = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (identityFrontFile == undefined) {
      valid = false;
    }

    if (identityBackFile == undefined) {
      valid = false;
    }
    return valid;
  };

  useEffect(async () => {
    if (uploadIBRes && uploadIFRes) {
      swal({
        className: "custom_swal",
        title: "Success!",
        text: __("Need to verify level 2 before uploading level 3."),
        icon: "success",
      });

      setUploadIBRes(false);
      setUploadIFRes(false);
    }
  }, [uploadIBRes, uploadIFRes]);

  const [otherDocFileName, setOtherDocFileName] = useState();
  const [otherDocFile, setOtherDocFile] = useState();
  const pickOtherDocFile = (event) => {
    setOtherDocFileName(event.target.files[0].name);
    setOtherDocFile(event.target.files[0]);
  };

  const uploadOtherDoc = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "other");
    formData.append("file", otherDocFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setOtherDocFileName("");
      setOtherDocFile(null);
      getIdUploads();
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: response.data.message,
        icon: "error",
      });
    }
  };

  const [fundFileName, setFundFileName] = useState();
  const [fundFile, setFundFile] = useState();
  const pickFundFile = (event) => {
    setFundFileName(event.target.files[0].name);
    setFundFile(event.target.files[0]);
  };

  const [addressFileName, setAddressFileName] = useState();
  const [addressFile, setAddressFile] = useState();
  const pickAddressFile = (event) => {
    setAddressFileName(event.target.files[0].name);
    setAddressFile(event.target.files[0]);
  };

  const [uploadFFRes, setUploadFFRes] = useState(false);
  const uploadFundFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "sof");
    formData.append("file", fundFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadFFRes(true);
      setFundFile("");
      setTriggerData((Math.random() + 1).toString(36).substring(7));
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: response.data.message,
        icon: "error",
      });
      setUploadFFRes(false);
    }
  };

  const [uploadAFRes, setUploadAFRes] = useState(false);
  const uploadAddressFile = async (ev) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("type", "poa");
    formData.append("file", addressFile);
    var response = await api.post("/id-uploads", formData);

    if (response.status == 200) {
      setUploadAFRes(true);
      setAddressFile("");
      setTriggerData((Math.random() + 1).toString(36).substring(7));
    } else {
      swal({
        className: "custom_swal",
        title: "error!",
        text: response.data.message,
        icon: "error",
      });
      setUploadAFRes(false);
    }
  };

  const uploadLevelThreeDoc = () => {
    var valid = validateLevelThreeDoc();
    if (valid) {
      if (level2Verification) {
        uploadAddressFile();
      } else {
        swal({
          className: "custom_swal",
          title: "Warning!",
          text: __("Need to verify level 2 before uploading level 3."),
          icon: "error",
        });
      }
    } else {
      swal({
        className: "custom_swal",
        title: "Warning!",
        text: __("Proof Of Address field is required"),
        icon: "error",
      });
    }
    window.scrollTo(0, 0);
  };

  const uploadLevelFourDoc = () => {
    var valid = validateLevelFourDoc();
    if (valid) {
      if (level3Verification) {
        uploadFundFile();
      } else {
        swal({
          className: "custom_swal",
          title: "Warning!",
          text: __("Need to verify level 3 before uploading level 4."),
          icon: "error",
        });
      }
    } else {
      swal({
        className: "custom_swal",
        title: "Warning!",
        text: __("Source Of Funds field is required"),
        icon: "error",
      });
    }
    // window.scrollTo(0, 0);
  };

  const validateLevelThreeDoc = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (addressFile == undefined) {
      valid = false;
    }
    return valid;
  };

  const validateLevelFourDoc = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (fundFile == undefined) {
      valid = false;
    }

    return valid;
  };

  useEffect(async () => {
    if (uploadAFRes) {
      swal({
        className: "custom_swal",
        title: "Success",
        text: __("Proof Of Address Successfully Uploaded"),
        icon: "success",
      });

      setUploadAFRes(false);
    }
  }, [uploadAFRes]);

  useEffect(async () => {
    if (uploadFFRes) {
      swal({
        className: "custom_swal",
        title: "Success",
        text: __("Source Of Funds Successfully Uploaded"),
        icon: "success",
      });

      setUploadFFRes(false);
    }
  }, [uploadFFRes]);

  //Level 1 functionality START'S here
  const [basicProfileErrors, setBasicProfileErrors] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    address: "",
    dateOfBirth: "",
    city: "",
    zip: "",
    occupation: "",
  });
  const [basicProfileData, setBasicProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
  });

  const updateLevelOneInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setBasicProfileData({ ...basicProfileData, [field]: value });
  };

  // const [mainProfileData, setMainProfileData] = useState();
  const [mainProfileData, setMainProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
  });
  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200 && response.data) {
      setBasicProfileData(response.data);
      setMainProfileData(response.data);
      if (
        response.data.firstName &&
        response.data.lastName &&
        response.data.bday &&
        response.data.bmonth &&
        response.data.byear &&
        response.data.playerCountry &&
        response.data.address &&
        response.data.city &&
        response.data.zip
      ) {
        setLevel1Verification(true);
      } else {
        setLevel1Verification(false);
      }
    }
  };

  const saveBasicProfileData = async (ev) => {
    setBasicProfileErrors({
      firstName: "",
      lastName: "",
      nickName: "",
      address: "",
      dateOfBirth: "",
      city: "",
      zip: "",
      occupation: "",
    });
    var response = await api.post("/profile/save-basic", basicProfileData);
    console.log(response);
    if (response.status == 200) {
      swal({
        className: "custom_swal",
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
    } else {
      if (response.data.errors) {
        setBasicProfileErrors(response.data.errors);
      } else {
        swal({
          className: "custom_swal",
          title: "Error",
          text: response.data.message,
          icon: "error",
        });
      }
    }
  };

  useEffect(async () => {
    getProfile();
  }, []);

  //Level 1 functionality END'S here

  const [allCountries, setAllCountries] = useState([]);
  const getAllCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setAllCountries(response.data);
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    getAllCountries();
  }, []);

  const [perviewImageModal, setPerviewImageModal] = useState(false);
  const [perviewImage, setPerviewImage] = useState([]);
  const showBigImage = (img) => {
    setPerviewImage(img);
    setPerviewImageModal(true);
  };
  const closePerviewImageModal = () => {
    setPerviewImageModal(false);
  };

  return (
    <>
      <div>
        <h3 className="tabName white">
          <svg
            width="25"
            height="31"
            viewBox="0 0 25 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.9834 5.60795L12.7823 0.957376C12.5017 0.840907 12.1863 0.84085 11.9058 0.957376L0.704698 5.60795C0.278085 5.78511 0 6.20161 0 6.66354V12.317C0 20.1013 4.70498 27.1035 11.9121 30.0453C12.189 30.1582 12.4991 30.1582 12.776 30.0453C19.9831 27.1036 24.6881 20.1014 24.6881 12.317V6.66354C24.6881 6.20161 24.4101 5.78511 23.9834 5.60795ZM22.4022 12.317C22.4022 18.9345 18.5161 25.0263 12.3441 27.7456C6.33697 25.099 2.28594 19.1138 2.28594 12.317V7.42658L12.3441 3.25052L22.4022 7.42658V12.317ZM11.0807 16.1696L15.9935 11.2569C16.4398 10.8106 17.1635 10.8105 17.6098 11.2569C18.0562 11.7033 18.0562 12.427 17.6098 12.8733L11.8889 18.5941C11.4425 19.0406 10.7188 19.0404 10.2726 18.5941L7.0783 15.3999C6.63191 14.9535 6.63191 14.2298 7.0783 13.7835C7.52468 13.3372 8.24835 13.3371 8.69468 13.7835L11.0807 16.1696Z"
              fill="white"
            />
          </svg>{" "}
          {__("Verification")}
        </h3>
        <Accordion
          className="chimp-accordions verify_area access_control_area"
          alwaysOpen
        >
          <Accordion.Item className="chimp-accordion" eventKey="0">
            <Accordion.Header>
              {__("Level 1")}&nbsp;{" "}
              {level1Verification ? (
                <i class="fas fa-check"></i>
              ) : (
                <span className={level1Verification ? "submitted" : ""}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.42033 10.1514L8.25141 7.37566C8.21839 6.81716 8.04504 5.91449 8.43303 5.46071C8.7283 5.11164 9.42744 5.0516 9.63954 5.5354C9.81989 6.10391 9.85977 6.71486 9.75511 7.30585L9.52904 10.1633C9.51933 10.4322 9.46577 10.697 9.37093 10.9452C9.33359 11.0255 9.27699 11.093 9.20725 11.1405C9.13751 11.1879 9.05729 11.2135 8.97528 11.2144C8.89326 11.2154 8.81257 11.1916 8.74195 11.1458C8.67133 11.0999 8.61347 11.0337 8.57463 10.9543C8.48526 10.6972 8.43319 10.4263 8.42033 10.1514V10.1514ZM9.00516 13.9653C8.80492 13.964 8.61263 13.879 8.46741 13.7274C8.32219 13.5758 8.23497 13.3691 8.22348 13.1493C8.21199 12.9295 8.2771 12.7131 8.40557 12.5443C8.53403 12.3754 8.7162 12.2666 8.91499 12.2402C9.0218 12.226 9.13011 12.236 9.23326 12.2696C9.33641 12.3032 9.4322 12.3597 9.51473 12.4355C9.59726 12.5114 9.66476 12.6051 9.71309 12.7108C9.76141 12.8164 9.78953 12.9319 9.7957 13.0499C9.80187 13.168 9.78597 13.2862 9.74897 13.3973C9.71198 13.5084 9.65467 13.6099 9.5806 13.6957C9.50652 13.7814 9.41724 13.8496 9.31828 13.896C9.21931 13.9424 9.11274 13.9659 9.00516 13.9653V13.9653Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M16.1078 16.6971H1.89259C1.5586 16.6914 1.23172 16.5902 0.944163 16.4033C0.656606 16.2165 0.4183 15.9505 0.252736 15.6315C0.0871719 15.3126 6.45267e-05 14.9517 3.5836e-08 14.5845C-6.44551e-05 14.2173 0.0869162 13.8564 0.252368 13.5374L7.36824 0.996936C7.54111 0.691446 7.78206 0.439537 8.06826 0.265075C8.35446 0.0906125 8.67644 -0.000626001 9.0037 3.23273e-06C9.33095 0.000632467 9.65264 0.0931086 9.93829 0.26867C10.2239 0.444232 10.4641 0.697066 10.636 1.00322L17.7404 13.5227C17.9086 13.8415 17.9981 14.2032 18 14.5717C18.0018 14.9403 17.916 15.303 17.751 15.6239C17.586 15.9447 17.3475 16.2126 17.0593 16.4008C16.771 16.5891 16.443 16.6912 16.1078 16.6971V16.6971ZM8.45346 1.72159L1.33823 14.262C1.28576 14.3685 1.25918 14.4881 1.26106 14.6092C1.26294 14.7303 1.29322 14.8488 1.34897 14.9533C1.40472 15.0577 1.48404 15.1446 1.57928 15.2055C1.67451 15.2664 1.78243 15.2992 1.89259 15.3008H16.1078C16.2192 15.299 16.3282 15.2652 16.4241 15.2029C16.5199 15.1406 16.5994 15.0519 16.6546 14.9456C16.7098 14.8392 16.7388 14.7189 16.7388 14.5965C16.7388 14.474 16.7098 14.3537 16.6546 14.2474C16.6546 14.2474 9.55012 1.72578 9.54694 1.72159C9.48847 1.62038 9.4077 1.53705 9.31212 1.47931C9.21655 1.42157 9.10926 1.39129 9.0002 1.39129C8.89115 1.39129 8.78386 1.42157 8.68828 1.47931C8.5927 1.53705 8.51193 1.62038 8.45346 1.72159V1.72159Z"
                      fill="#FF0000"
                    />
                  </svg>
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              <div className="row">
                <div className="col-md-6">
                  <div className="user_blocks">
                    <div className="single_block twoItems">
                      <div className="item">
                        <div className="input_label">
                          {__("First Name")}
                          <span className="requried_icon"> *</span>
                        </div>

                        <input
                          type="text"
                          name="firstName"
                          placeholder={__("First Name")}
                          disabled={mainProfileData.firstName ? true : false}
                          value={basicProfileData.firstName}
                          onChange={updateLevelOneInput}
                          className={
                            "form-controll input_box active " +
                            (basicProfileErrors.firstName ? "error" : "")
                          }
                        />
                        <div class="errorMsg">
                          {basicProfileErrors.firstName}
                        </div>
                      </div>
                      <div className="item">
                        <div className="input_label">
                          {__("Last Name")}
                          <span className="requried_icon"> *</span>
                        </div>

                        <input
                          type="text"
                          name="lastName"
                          disabled={mainProfileData.lastName ? true : false}
                          value={basicProfileData.lastName}
                          onChange={updateLevelOneInput}
                          placeholder={__("Last Name")}
                          className={
                            "form-controll input_box active " +
                            (basicProfileErrors.lastName ? "error" : "")
                          }
                        />
                        <div class="errorMsg">
                          {basicProfileErrors.lastName}
                        </div>
                      </div>
                    </div>

                    <div className="single_block threeItems">
                      <div className="item">
                        <div className="input_label">
                          {__("Date of Birth")}
                          <span className="requried_icon"> *</span>
                        </div>

                        <input
                          autocomplete="off"
                          className="form-controll input_box active"
                          type="number"
                          name="bday"
                          disabled={mainProfileData.bday ? true : false}
                          value={basicProfileData.bday}
                          onChange={updateLevelOneInput}
                          step="1"
                          min="1"
                          placeholder={__("Day")}
                          max="31"
                        />
                      </div>
                      <div className="item">
                        <div className="input_label">&nbsp;</div>
                        <input
                          autocomplete="off"
                          className="form-controll input_box active"
                          type="number"
                          name="bmonth"
                          disabled={mainProfileData.bmonth ? true : false}
                          value={basicProfileData.bmonth}
                          onChange={updateLevelOneInput}
                          step="1"
                          min="1"
                          max="12"
                          placeholder={__("Month")}
                        />
                      </div>
                      <div className="item">
                        <div className="input_label">&nbsp;</div>
                        <input
                          autocomplete="off"
                          className="form-controll input_box active"
                          type="number"
                          name="byear"
                          disabled={mainProfileData.byear ? true : false}
                          value={basicProfileData.byear}
                          onChange={updateLevelOneInput}
                          step="1"
                          min="1900"
                          placeholder={__("Year")}
                        />
                      </div>
                      <div class="errorMsg">
                        {basicProfileErrors.dateOfBirth}
                      </div>
                    </div>
                    <div className="single_block singleItem">
                      <div className="item">
                        <div className="input_label">
                          {__("Country")}
                          <span className="requried_icon"> *</span>
                        </div>
                        <select
                          name="playerCountry"
                          value={basicProfileData.playerCountry}
                          onChange={updateLevelOneInput}
                          className="form-controll input_box active select_box"
                          disabled={
                            mainProfileData.playerCountry ? true : false
                          }
                        >
                          <option>{__("Select Country")}</option>
                          {allCountries.map((item, index) => (
                            <option value={item.code}>{item.nicename}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="single_block singleItem">
                      <div className="item">
                        <div className="input_label">
                          {__("Residential Address")}{" "}
                          <span className="requried_icon"> *</span>
                        </div>
                        <input
                          type="text"
                          name="address"
                          value={basicProfileData.address}
                          disabled={mainProfileData.address ? true : false}
                          placeholder={__("Residential Address")}
                          onChange={updateLevelOneInput}
                          className={
                            "form-controll input_box active " +
                            (basicProfileErrors.address ? "error" : "")
                          }
                        />
                        <div class="errorMsg">{basicProfileErrors.address}</div>
                      </div>
                    </div>

                    <div className="single_block twoItems">
                      <div className="item">
                        <div className="input_label">
                          {__("City")} <span className="requried_icon"> *</span>
                        </div>
                        <input
                          type="text"
                          name="city"
                          value={basicProfileData.city}
                          disabled={mainProfileData.city ? true : false}
                          onChange={updateLevelOneInput}
                          placeholder={__("City")}
                          className={
                            "form-controll input_box active " +
                            (basicProfileErrors.city ? "error" : "")
                          }
                        />
                        <div class="errorMsg">{basicProfileErrors.city}</div>
                      </div>
                      <div className="item">
                        <div className="input_label">
                          {__("Postal Code")}{" "}
                          <span className="requried_icon"> *</span>
                        </div>

                        <input
                          type="text"
                          name="zip"
                          value={basicProfileData.zip}
                          onChange={updateLevelOneInput}
                          disabled={mainProfileData.zip ? true : false}
                          className={
                            "form-controll input_box active " +
                            (basicProfileErrors.zip ? "error" : "")
                          }
                          placeholder={__("Postal Code")}
                        />
                        <div class="errorMsg">{basicProfileErrors.zip}</div>
                      </div>
                    </div>

                    <div className="single_block singleItem">
                      <div className="item">
                        <div className="input_label">
                          {__("Occupation")} ({__("Your job/work")})
                          <span className="requried_icon"> *</span>
                        </div>
                        <input
                          className="form-controll input_box active"
                          type="text"
                          name="occupation"
                          disabled={mainProfileData.occupation ? true : false}
                          value={basicProfileData.occupation}
                          onChange={updateLevelOneInput}
                          placeholder={__("Occupation")}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <button
                        onClick={saveBasicProfileData}
                        className="common_btn_v1"
                      >
                        {__("Submit")}
                      </button>
                      <span style={{ color: "white" }}>
                        {__("All data is safely stored and encrypted.")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="1">
            <Accordion.Header>
              {__("Level 2")} &nbsp;{" "}
              {level2Verification ? (
                <i class="fas fa-check"></i>
              ) : (
                <span className={level2submitted ? "submitted" : ""}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.42033 10.1514L8.25141 7.37566C8.21839 6.81716 8.04504 5.91449 8.43303 5.46071C8.7283 5.11164 9.42744 5.0516 9.63954 5.5354C9.81989 6.10391 9.85977 6.71486 9.75511 7.30585L9.52904 10.1633C9.51933 10.4322 9.46577 10.697 9.37093 10.9452C9.33359 11.0255 9.27699 11.093 9.20725 11.1405C9.13751 11.1879 9.05729 11.2135 8.97528 11.2144C8.89326 11.2154 8.81257 11.1916 8.74195 11.1458C8.67133 11.0999 8.61347 11.0337 8.57463 10.9543C8.48526 10.6972 8.43319 10.4263 8.42033 10.1514V10.1514ZM9.00516 13.9653C8.80492 13.964 8.61263 13.879 8.46741 13.7274C8.32219 13.5758 8.23497 13.3691 8.22348 13.1493C8.21199 12.9295 8.2771 12.7131 8.40557 12.5443C8.53403 12.3754 8.7162 12.2666 8.91499 12.2402C9.0218 12.226 9.13011 12.236 9.23326 12.2696C9.33641 12.3032 9.4322 12.3597 9.51473 12.4355C9.59726 12.5114 9.66476 12.6051 9.71309 12.7108C9.76141 12.8164 9.78953 12.9319 9.7957 13.0499C9.80187 13.168 9.78597 13.2862 9.74897 13.3973C9.71198 13.5084 9.65467 13.6099 9.5806 13.6957C9.50652 13.7814 9.41724 13.8496 9.31828 13.896C9.21931 13.9424 9.11274 13.9659 9.00516 13.9653V13.9653Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M16.1078 16.6971H1.89259C1.5586 16.6914 1.23172 16.5902 0.944163 16.4033C0.656606 16.2165 0.4183 15.9505 0.252736 15.6315C0.0871719 15.3126 6.45267e-05 14.9517 3.5836e-08 14.5845C-6.44551e-05 14.2173 0.0869162 13.8564 0.252368 13.5374L7.36824 0.996936C7.54111 0.691446 7.78206 0.439537 8.06826 0.265075C8.35446 0.0906125 8.67644 -0.000626001 9.0037 3.23273e-06C9.33095 0.000632467 9.65264 0.0931086 9.93829 0.26867C10.2239 0.444232 10.4641 0.697066 10.636 1.00322L17.7404 13.5227C17.9086 13.8415 17.9981 14.2032 18 14.5717C18.0018 14.9403 17.916 15.303 17.751 15.6239C17.586 15.9447 17.3475 16.2126 17.0593 16.4008C16.771 16.5891 16.443 16.6912 16.1078 16.6971V16.6971ZM8.45346 1.72159L1.33823 14.262C1.28576 14.3685 1.25918 14.4881 1.26106 14.6092C1.26294 14.7303 1.29322 14.8488 1.34897 14.9533C1.40472 15.0577 1.48404 15.1446 1.57928 15.2055C1.67451 15.2664 1.78243 15.2992 1.89259 15.3008H16.1078C16.2192 15.299 16.3282 15.2652 16.4241 15.2029C16.5199 15.1406 16.5994 15.0519 16.6546 14.9456C16.7098 14.8392 16.7388 14.7189 16.7388 14.5965C16.7388 14.474 16.7098 14.3537 16.6546 14.2474C16.6546 14.2474 9.55012 1.72578 9.54694 1.72159C9.48847 1.62038 9.4077 1.53705 9.31212 1.47931C9.21655 1.42157 9.10926 1.39129 9.0002 1.39129C8.89115 1.39129 8.78386 1.42157 8.68828 1.47931C8.5927 1.53705 8.51193 1.62038 8.45346 1.72159V1.72159Z"
                      fill="#FF0000"
                    />
                  </svg>
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_area">
                    <div className="input_item">
                      <div className="name input_label">
                        Please upload your Proof of Identity. All documents must
                        be lying on a flat surface with all 4 corners inside the
                        frame, and all information should be clear and
                        identifiable. While photographs of ID cards and Passport
                        are acceptable, scanned documents are not.
                      </div>
                      <div className="name description">
                        Following file types are accepted: png, jpg, .pdf
                      </div>
                    </div>

                    <div className="user_blocks">
                      <div className="single_block twoItems">
                        <div className="input_item item">
                          <div className="name input_label">
                            Proof of Identity (Front)
                            <span className="requried_icon"> *</span>
                          </div>
                          <div className="form-control name_value overflow-hidden">
                            {identityFrontFileName}
                          </div>
                          <input
                            onChange={pickIdentityFront}
                            id="identity_front"
                            type="file"
                            hidden
                            accept=".jpg, .jpeg, .png, .pdf"
                            name="identity_front"
                            className="form-control name_value"
                          />
                          <label className="d-block" for="identity_front">
                            {!!identityFrontFileName || (
                              <i className="fal fa-file-upload in_input_icon"></i>
                            )}
                            {!!identityFrontFileName && (
                              <i className="fad fa-check-square in_input_icon upload_success"></i>
                            )}
                          </label>
                        </div>

                        <div className="input_item item">
                          <div className="name input_label">
                            Proof of Identity (Back)
                            <span className="requried_icon"> *</span>
                          </div>
                          <div className="form-control name_value overflow-hidden">
                            {identityBackFileName}
                          </div>
                          <input
                            onChange={pickIdentityBack}
                            id="identity_back"
                            type="file"
                            hidden
                            accept=".jpg, .jpeg, .png, .pdf"
                            name="identity_back"
                            className="form-control name_value"
                          />
                          <label className="d-block" for="identity_back">
                            {!!identityBackFileName || (
                              <i className="fal fa-file-upload in_input_icon"></i>
                            )}
                            {!!identityBackFileName && (
                              <i class="fad fa-check-square in_input_icon upload_success"></i>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {!level2Verification && (
                      <div className="login_button">
                        <button
                          onClick={uploadProofOfIdentity}
                          className="common_btn_v1"
                        >
                          {__("Upload")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <ul className="proof_view_area">
                    {poif && (
                      <li>
                        <img
                          onClick={() => showBigImage(poif.api_photo_url)}
                          src={poif.api_photo_url}
                        />
                      </li>
                    )}
                    {poib && (
                      <li>
                        <img
                          onClick={() => showBigImage(poib.api_photo_url)}
                          src={poib.api_photo_url}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="2">
            <Accordion.Header>
              {__("Level 3")} &nbsp;{" "}
              {level3Verification ? (
                <i class="fas fa-check"></i>
              ) : (
                <span className={level3submitted ? "submitted" : ""}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.42033 10.1514L8.25141 7.37566C8.21839 6.81716 8.04504 5.91449 8.43303 5.46071C8.7283 5.11164 9.42744 5.0516 9.63954 5.5354C9.81989 6.10391 9.85977 6.71486 9.75511 7.30585L9.52904 10.1633C9.51933 10.4322 9.46577 10.697 9.37093 10.9452C9.33359 11.0255 9.27699 11.093 9.20725 11.1405C9.13751 11.1879 9.05729 11.2135 8.97528 11.2144C8.89326 11.2154 8.81257 11.1916 8.74195 11.1458C8.67133 11.0999 8.61347 11.0337 8.57463 10.9543C8.48526 10.6972 8.43319 10.4263 8.42033 10.1514V10.1514ZM9.00516 13.9653C8.80492 13.964 8.61263 13.879 8.46741 13.7274C8.32219 13.5758 8.23497 13.3691 8.22348 13.1493C8.21199 12.9295 8.2771 12.7131 8.40557 12.5443C8.53403 12.3754 8.7162 12.2666 8.91499 12.2402C9.0218 12.226 9.13011 12.236 9.23326 12.2696C9.33641 12.3032 9.4322 12.3597 9.51473 12.4355C9.59726 12.5114 9.66476 12.6051 9.71309 12.7108C9.76141 12.8164 9.78953 12.9319 9.7957 13.0499C9.80187 13.168 9.78597 13.2862 9.74897 13.3973C9.71198 13.5084 9.65467 13.6099 9.5806 13.6957C9.50652 13.7814 9.41724 13.8496 9.31828 13.896C9.21931 13.9424 9.11274 13.9659 9.00516 13.9653V13.9653Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M16.1078 16.6971H1.89259C1.5586 16.6914 1.23172 16.5902 0.944163 16.4033C0.656606 16.2165 0.4183 15.9505 0.252736 15.6315C0.0871719 15.3126 6.45267e-05 14.9517 3.5836e-08 14.5845C-6.44551e-05 14.2173 0.0869162 13.8564 0.252368 13.5374L7.36824 0.996936C7.54111 0.691446 7.78206 0.439537 8.06826 0.265075C8.35446 0.0906125 8.67644 -0.000626001 9.0037 3.23273e-06C9.33095 0.000632467 9.65264 0.0931086 9.93829 0.26867C10.2239 0.444232 10.4641 0.697066 10.636 1.00322L17.7404 13.5227C17.9086 13.8415 17.9981 14.2032 18 14.5717C18.0018 14.9403 17.916 15.303 17.751 15.6239C17.586 15.9447 17.3475 16.2126 17.0593 16.4008C16.771 16.5891 16.443 16.6912 16.1078 16.6971V16.6971ZM8.45346 1.72159L1.33823 14.262C1.28576 14.3685 1.25918 14.4881 1.26106 14.6092C1.26294 14.7303 1.29322 14.8488 1.34897 14.9533C1.40472 15.0577 1.48404 15.1446 1.57928 15.2055C1.67451 15.2664 1.78243 15.2992 1.89259 15.3008H16.1078C16.2192 15.299 16.3282 15.2652 16.4241 15.2029C16.5199 15.1406 16.5994 15.0519 16.6546 14.9456C16.7098 14.8392 16.7388 14.7189 16.7388 14.5965C16.7388 14.474 16.7098 14.3537 16.6546 14.2474C16.6546 14.2474 9.55012 1.72578 9.54694 1.72159C9.48847 1.62038 9.4077 1.53705 9.31212 1.47931C9.21655 1.42157 9.10926 1.39129 9.0002 1.39129C8.89115 1.39129 8.78386 1.42157 8.68828 1.47931C8.5927 1.53705 8.51193 1.62038 8.45346 1.72159V1.72159Z"
                      fill="#FF0000"
                    />
                  </svg>
                </span>
              )}
            </Accordion.Header>

            <Accordion.Body className="myaccount_input_area">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_area">
                    <div className="input_item item">
                      <div className="name input_label">
                        {__(
                          "Please upload supporting documentation for your Proof of Address. Documents lying a on a flat surface must show all 4 corners and all information should be clear and identifiable."
                        )}
                      </div>
                      <div className="name description">
                        {__("Following file types are accepted")}: png, jpg,
                        .pdf
                      </div>
                    </div>

                    <div className="user_blocks">
                      <div className="single_block twoItems">
                        <div className="input_item item">
                          <div className="name input_label">
                            {__("Proof of Address")}
                            <span className="requried_icon"> *</span>
                          </div>
                          <div className="form-control name_value overflow-hidden">
                            {addressFileName}
                          </div>
                          <input
                            onChange={pickAddressFile}
                            id="address_file"
                            type="file"
                            hidden
                            name="address_file"
                            className="form-control name_value"
                          />
                          <label className="d-block" for="address_file">
                            {!!addressFileName || (
                              <i className="fal fa-file-upload in_input_icon"></i>
                            )}
                            {!!addressFileName && (
                              <i class="fad fa-check-square in_input_icon upload_success"></i>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {!level3Verification && (
                      <div className="login_button">
                        <button
                          onClick={uploadLevelThreeDoc}
                          className="common_btn_v1"
                        >
                          {__("Upload")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <ul className="proof_view_area">
                    {poa && (
                      <li>
                        <img
                          onClick={() => showBigImage(poa.api_photo_url)}
                          src={poa.api_photo_url}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="3">
            <Accordion.Header>
              {__("Level 4")} &nbsp;{" "}
              {level4Verification ? (
                <i class="fas fa-check"></i>
              ) : (
                <span className={level4submitted ? "submitted" : ""}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.42033 10.1514L8.25141 7.37566C8.21839 6.81716 8.04504 5.91449 8.43303 5.46071C8.7283 5.11164 9.42744 5.0516 9.63954 5.5354C9.81989 6.10391 9.85977 6.71486 9.75511 7.30585L9.52904 10.1633C9.51933 10.4322 9.46577 10.697 9.37093 10.9452C9.33359 11.0255 9.27699 11.093 9.20725 11.1405C9.13751 11.1879 9.05729 11.2135 8.97528 11.2144C8.89326 11.2154 8.81257 11.1916 8.74195 11.1458C8.67133 11.0999 8.61347 11.0337 8.57463 10.9543C8.48526 10.6972 8.43319 10.4263 8.42033 10.1514V10.1514ZM9.00516 13.9653C8.80492 13.964 8.61263 13.879 8.46741 13.7274C8.32219 13.5758 8.23497 13.3691 8.22348 13.1493C8.21199 12.9295 8.2771 12.7131 8.40557 12.5443C8.53403 12.3754 8.7162 12.2666 8.91499 12.2402C9.0218 12.226 9.13011 12.236 9.23326 12.2696C9.33641 12.3032 9.4322 12.3597 9.51473 12.4355C9.59726 12.5114 9.66476 12.6051 9.71309 12.7108C9.76141 12.8164 9.78953 12.9319 9.7957 13.0499C9.80187 13.168 9.78597 13.2862 9.74897 13.3973C9.71198 13.5084 9.65467 13.6099 9.5806 13.6957C9.50652 13.7814 9.41724 13.8496 9.31828 13.896C9.21931 13.9424 9.11274 13.9659 9.00516 13.9653V13.9653Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M16.1078 16.6971H1.89259C1.5586 16.6914 1.23172 16.5902 0.944163 16.4033C0.656606 16.2165 0.4183 15.9505 0.252736 15.6315C0.0871719 15.3126 6.45267e-05 14.9517 3.5836e-08 14.5845C-6.44551e-05 14.2173 0.0869162 13.8564 0.252368 13.5374L7.36824 0.996936C7.54111 0.691446 7.78206 0.439537 8.06826 0.265075C8.35446 0.0906125 8.67644 -0.000626001 9.0037 3.23273e-06C9.33095 0.000632467 9.65264 0.0931086 9.93829 0.26867C10.2239 0.444232 10.4641 0.697066 10.636 1.00322L17.7404 13.5227C17.9086 13.8415 17.9981 14.2032 18 14.5717C18.0018 14.9403 17.916 15.303 17.751 15.6239C17.586 15.9447 17.3475 16.2126 17.0593 16.4008C16.771 16.5891 16.443 16.6912 16.1078 16.6971V16.6971ZM8.45346 1.72159L1.33823 14.262C1.28576 14.3685 1.25918 14.4881 1.26106 14.6092C1.26294 14.7303 1.29322 14.8488 1.34897 14.9533C1.40472 15.0577 1.48404 15.1446 1.57928 15.2055C1.67451 15.2664 1.78243 15.2992 1.89259 15.3008H16.1078C16.2192 15.299 16.3282 15.2652 16.4241 15.2029C16.5199 15.1406 16.5994 15.0519 16.6546 14.9456C16.7098 14.8392 16.7388 14.7189 16.7388 14.5965C16.7388 14.474 16.7098 14.3537 16.6546 14.2474C16.6546 14.2474 9.55012 1.72578 9.54694 1.72159C9.48847 1.62038 9.4077 1.53705 9.31212 1.47931C9.21655 1.42157 9.10926 1.39129 9.0002 1.39129C8.89115 1.39129 8.78386 1.42157 8.68828 1.47931C8.5927 1.53705 8.51193 1.62038 8.45346 1.72159V1.72159Z"
                      fill="#FF0000"
                    />
                  </svg>
                </span>
              )}
            </Accordion.Header>

            <Accordion.Body className="myaccount_input_area">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_area">
                    <div className="input_item item">
                      <div className="name input_label">
                        {__(
                          "Please upload supporting documentation for your Source of Funds. Documents lying a on a flat surface must show all 4 corners and all information should be clear and identifiable."
                        )}
                      </div>
                      <div className="name description">
                        {__("Following file types are accepted")}: png, jpg,
                        .pdf
                      </div>
                    </div>

                    <div className="user_blocks">
                      <div className="single_block twoItems">
                        <div className="input_item item">
                          <div className="name input_label">
                            {__("Source of Funds")}
                            <span className="requried_icon"> *</span>
                          </div>
                          <div className="form-control name_value overflow-hidden">
                            {fundFileName}
                          </div>
                          <input
                            onChange={pickFundFile}
                            id="fund_file"
                            type="file"
                            hidden
                            name="fund_file"
                            className="form-control name_value"
                          />
                          <label className="d-block" for="fund_file">
                            {!!fundFileName || (
                              <i className="fal fa-file-upload in_input_icon"></i>
                            )}
                            {!!fundFileName && (
                              <i class="fad fa-check-square in_input_icon upload_success"></i>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {!level4Verification && (
                      <div className="login_button">
                        <button
                          onClick={uploadLevelFourDoc}
                          className="common_btn_v1"
                        >
                          {__("Upload")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <ul className="proof_view_area">
                    {sof && (
                      <li>
                        <img
                          onClick={() => showBigImage(sof.api_photo_url)}
                          src={sof.api_photo_url}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="4">
            <Accordion.Header>
              {__("Other Verification Documents")}
            </Accordion.Header>

            <Accordion.Body className="myaccount_input_area">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_area">
                    <div className="input_item item">
                      <div className="name input_label">
                        {__(
                          "You can upload any other documents that does not fall under the above categories."
                        )}
                      </div>
                      <div className="name description">
                        {__("Following file types are accepted")}: png, jpg,
                        .pdf
                      </div>
                    </div>

                    <div className="user_blocks">
                      <div className="single_block twoItems">
                        <div className="input_item item">
                          <div className="name input_label">
                            {__("Other document")}
                            <span className="requried_icon"></span>
                          </div>
                          <div className="form-control name_value overflow-hidden">
                            {otherDocFileName}
                          </div>
                          <input
                            onChange={pickOtherDocFile}
                            id="other_doc_file"
                            type="file"
                            hidden
                            name="other_doc_file"
                            className="form-control name_value"
                          />
                          <label className="d-block" for="other_doc_file">
                            <i className="fal fa-file-upload in_input_icon"></i>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="login_button">
                      <button
                        onClick={uploadOtherDoc}
                        className="common_btn_v1"
                      >
                        {__("Upload")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <ul className="proof_view_area">
                    {otherDocs.map((item, index) => (
                      <li style={{ position: "relative" }}>
                        <img
                          onClick={() => showBigImage(item.api_photo_url)}
                          src={item.api_photo_url}
                        />
                        {item.approved === "approved" && (
                          <i
                            style={{ position: "absolute", top: 0, right: 0 }}
                            class="fad fa-check-square in_input_icon upload_success"
                          ></i>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Modal
        show={perviewImageModal}
        onHide={closePerviewImageModal}
        size={"lg"}
      >
        <Modal.Body className="login_modal forgot_pass">
          <div onClick={closePerviewImageModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>
          <img alt="" style={{ width: "100%" }} src={perviewImage} />
        </Modal.Body>
      </Modal>
    </>
  );
}
