import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";

export default function Notifications(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/player/notifications", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/notification/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  return (
    <>
      <h3 className="tabName white d-flex justify-content-between">
        <div className="">
          <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.5333 12.2041C20.5333 12.6091 20.8616 12.9374 21.2666 12.9374C21.6716 12.9374 21.9999 12.6091 21.9999 12.2041C21.9999 9.2659 20.8557 6.50354 18.7781 4.42591C18.4918 4.13957 18.0274 4.13952 17.741 4.42591C17.4546 4.7123 17.4546 5.1766 17.741 5.46299C19.5417 7.26361 20.5333 9.65764 20.5333 12.2041Z"
              fill="white"
            />
            <path
              d="M0.733331 12.9377C1.13833 12.9377 1.46666 12.6094 1.46666 12.2044C1.46666 9.658 2.45832 7.26396 4.25889 5.46334C4.54528 5.17695 4.54528 4.71265 4.25889 4.42626C3.97255 4.13987 3.50821 4.13987 3.22182 4.42626C1.14419 6.50389 0 9.2662 0 12.2044C0 12.6094 0.328337 12.9377 0.733331 12.9377Z"
              fill="white"
            />
            <path
              d="M18.5778 13.8355V11.4956C18.5778 8.08205 16.3088 5.18886 13.2 4.24389V3.18447C13.2 1.9714 12.2131 0.984482 11 0.984482C9.78695 0.984482 8.80003 1.9714 8.80003 3.18447V4.24389C5.6912 5.18886 3.42227 8.082 3.42227 11.4956V13.8355C3.42227 16.8339 2.27935 19.6771 0.20407 21.8413C0.000692692 22.0534 -0.0564583 22.3663 0.0588214 22.6366C0.174101 22.9068 0.439567 23.0822 0.733388 23.0822H7.40714C7.7478 24.7538 9.22932 26.0155 11 26.0155C12.7708 26.0155 14.2522 24.7538 14.5929 23.0822H21.2667C21.5605 23.0822 21.8259 22.9068 21.9412 22.6366C22.0565 22.3663 21.9993 22.0534 21.796 21.8413C19.7207 19.6771 18.5778 16.8339 18.5778 13.8355ZM10.2667 3.18447C10.2667 2.78012 10.5957 2.45114 11 2.45114C11.4044 2.45114 11.7334 2.78012 11.7334 3.18447V3.9534C11.492 3.93013 11.2474 3.91781 11 3.91781C10.7526 3.91781 10.5081 3.93013 10.2667 3.9534V3.18447ZM11 24.5489C10.044 24.5489 9.22888 23.9357 8.92616 23.0822H13.0739C12.7712 23.9357 11.956 24.5489 11 24.5489ZM2.31396 21.6155C3.98405 19.3768 4.88893 16.6676 4.88893 13.8355V11.4956C4.88893 8.1259 7.63037 5.38447 11 5.38447C14.3697 5.38447 17.1111 8.1259 17.1111 11.4956V13.8355C17.1111 16.6676 18.016 19.3768 19.6861 21.6155H2.31396Z"
              fill="white"
            />
          </svg>{" "}
          {__("Notifications")}
        </div>
        <div className="notification_badge">10</div>
      </h3>
      <div className="user_blocks">
        <div className="width_100 results_v1">
          {results.length ? (
            <>
              <div className="notification_lists">
                {results.map((val, index) => (
                  <div key={index} className="single_notification">
                    <div className="notification_text">
                      <div className="notification_sub">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(val.notification_str),
                          }}
                        ></p>
                        <span className="colored">{__("$ 6.666 to be won!")}</span>
                      </div>
                      <div className="notification_date">{val.notification_time}</div>
                    </div>
                    <div className="notification_action">
                      <Link className="btn">
                        <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="25px"
                          height="20px"
                          viewBox="0 0 41.915 41.916"
                          // style="enable-background:new 0 0 41.915 41.916;"
                          space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M11.214,20.956c0,3.091-2.509,5.589-5.607,5.589C2.51,26.544,0,24.046,0,20.956c0-3.082,2.511-5.585,5.607-5.585
			C8.705,15.371,11.214,17.874,11.214,20.956z"
                                fill="#040F46"
                              />
                              <path
                                d="M26.564,20.956c0,3.091-2.509,5.589-5.606,5.589c-3.097,0-5.607-2.498-5.607-5.589c0-3.082,2.511-5.585,5.607-5.585
			C24.056,15.371,26.564,17.874,26.564,20.956z"
                                fill="#040F46"
                              />
                              <path
                                d="M41.915,20.956c0,3.091-2.509,5.589-5.607,5.589c-3.097,0-5.606-2.498-5.606-5.589c0-3.082,2.511-5.585,5.606-5.585
			C39.406,15.371,41.915,17.874,41.915,20.956z"
                                fill="#040F46"
                              />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </Link>
                      <Link onClick={() => deleteItem(val.id)} className="btn">
                        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.03557 11.2929C7.38533 11.2929 7.66891 11.5765 7.66891 11.9263V19.9684C7.66891 20.3182 7.38533 20.6017 7.03557 20.6017C6.68581 20.6017 6.40224 20.3182 6.40224 19.9684V11.9263C6.40224 11.5765 6.68581 11.2929 7.03557 11.2929ZM10.3667 11.9263C10.3667 11.5765 10.6502 11.2929 11 11.2929C11.3498 11.2929 11.6333 11.5765 11.6333 11.9263V19.9684C11.6333 20.3182 11.3498 20.6017 11 20.6017C10.6502 20.6017 10.3667 20.3182 10.3667 19.9684V11.9263ZM14.3311 11.9263C14.3311 11.5765 14.6147 11.2929 14.9644 11.2929C15.3142 11.2929 15.5978 11.5765 15.5978 11.9263V19.9684C15.5978 20.3182 15.3142 20.6017 14.9644 20.6017C14.6147 20.6017 14.3311 20.3182 14.3311 19.9684V11.9263ZM2.27758 8.02785L3.25901 24.1239C3.29835 24.7692 3.83138 25.2707 4.47794 25.2707H17.522C18.1685 25.2707 18.7017 24.7692 18.7409 24.1239L19.7223 8.02785H2.27758ZM2.12289 3.10487H7.26864V2.10634C7.26864 1.34777 7.88733 0.729156 8.64582 0.729156H13.3542C14.1127 0.729156 14.7314 1.34777 14.7314 2.10634V3.10487H19.877C20.5497 3.10487 21.0983 3.65349 21.0983 4.32609V6.36543C21.0983 6.58345 20.9205 6.76126 20.7024 6.76126H1.29741C1.07939 6.76126 0.901581 6.58345 0.901581 6.36543V4.32609C0.901581 3.65341 1.45029 3.10487 2.12289 3.10487V3.10487Z"
                            fill="#040F46"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
}
