import React, { Component, useState, useCallback } from "react";
import { __ } from "services/Translator";
export default function Pagination(props) {
  const data = props.data;
  // const __ = (text) => text;
  const currentPage =
    props.data &&
    props.data.length &&
    props.data.filter((item) => item.type === "current_page").length
      ? props.data.filter((item) => item.type === "current_page")[0].page
      : 1;

  const lastPage =
    props.data && props.data.length
      ? props.data[props.data.length - 1].page
      : 1;

  const onPageChange = (page) => {
    if (props.onPageChange && page) {
      props.onPageChange(page);
    }
  };

  const incrementPage = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  if (!data || currentPage >= lastPage) {
    return null;
  }

  return (
    <div className="container d-flex justify-content-center">
      <a className="common_btn_v1 large with_shadow" onClick={incrementPage}>
        {__("Load More")}
      </a>
    </div>
  );
}
