import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "configs/config";
import api from "services/api";
import { __ } from "services/Translator";

const RegCampaigns = (props) => {
  const className = props.className;

  const [campaigns, setCampaigns] = useState([]);

  const getCampaigns = async () => {
    var response = await api.post("/get-registration-campaigns", {
      language: props.language,
      currency: props.currency,
    });
    if (response.status === 200) {
      setCampaigns(response.data);
    }
  };

  const onChange = (ev) => {
    var value = ev.target.value;
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onChangePromoCode = (ev) => {
    var value = ev.target.value;
    if (props.onChangePromoCode) {
      props.onChangePromoCode(value);
    }
  };

  const toggleExpanded = (index) => {
    setCampaigns(
      campaigns.map((campaign, key) => (key === index ? { ...campaign, expanded: !campaign.expanded } : campaign))
    );
  };

  useEffect(() => {
    getCampaigns();
  }, [props.country, props.currency]);

  return (
    <>
      <div className="form_area" style={{ display: "none" }}>
        <div className="reg_bonus_heading">{__("Choose Offer")}</div>
        <ul className="input_item">
          {campaigns.map((campaign, index) => (
            <li key={index}>
              <div className="reg_bonus_title">{campaign.title}</div>
              <div className="reg_bonus_radio_area">
                {campaign.campaign_type === "Voucher / Code Campaign" ? (
                  <input
                    checked={props.value === "promo_code" ? true : false}
                    type="radio"
                    value="promo_code"
                    id={campaign.id + "_id"}
                    onChange={onChange}
                    name="campaign"
                  />
                ) : (
                  <input
                    checked={props.value === campaign.id.toString() ? true : false}
                    type="radio"
                    value={campaign.id}
                    id={campaign.id + "_id"}
                    onChange={onChange}
                    name="campaign"
                  />
                )}
                <label htmlFor={campaign.id + "_id"} className="radio_area">
                  <div className="reg_bonus_banner">
                    <img alt="" src={campaign.campaign_image} />
                  </div>
                  <div className="radio_check_and_text_area">
                    <div className="radio_box">
                      <div className="radio_checked_box"></div>
                    </div>
                    <div className="radio_text">{__("Select Bonus Campaign")}</div>
                  </div>
                </label>
                {campaign.campaign_type == "Voucher / Code Campaign" && (
                  <div className="single_input">
                    <input
                      disabled={props.value !== "promo_code"}
                      type="text"
                      className="form-control name_value"
                      onChange={onChangePromoCode}
                      placeholder={__("Type Code")}
                      value={props.promoCode}
                    />
                    <div className="error_text"></div>
                    <i className="fas fa-check"></i>
                  </div>
                )}
              </div>
              <div className="reg_bonus_short_text">{campaign.campaign_information}</div>
              <div className={"reg_bonus_link_area " + (campaign.expanded ? "expanded" : "")}>
                <Link target="_blank" rel="nofollow noopener noreferrer" to="/content/bonus-terms">
                  {__("Bonus terms apply")}
                </Link>
                <Link target="_blank" rel="nofollow noopener noreferrer" to="/content/terms-conditions">
                  {__("General Terms & Conditions apply")}
                </Link>
              </div>
              <div className="reg_bonus_more_less_area">
                <a className="more_less" href="#" onClick={() => toggleExpanded(index)}>
                  {campaign.expanded ? (
                    <>
                      <span className="btn_text">{__("Show Less")}</span>
                      <span className="btn_icon">
                        <i className="fas fa-angle-up"></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="btn_text">{__("Show More")}</span>
                      <span className="btn_icon">
                        <i className="fas fa-angle-down"></i>
                      </span>
                    </>
                  )}
                </a>
              </div>
            </li>
          ))}
          <li className="no_bonus_radio">
            <div className="reg_bonus_radio_area">
              <input
                id="no_bonus"
                value="no_bonus"
                type="radio"
                onChange={onChange}
                checked={props.value === "no_bonus" ? true : false}
                name="campaign"
              />
              <label for="no_bonus" className="radio_area">
                <div className="radio_check_and_text_area">
                  <div className="radio_box">
                    <div className="radio_checked_box"></div>
                  </div>
                  <div className="radio_text">{__("No Bonus")}</div>
                </div>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RegCampaigns;
