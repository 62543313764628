import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import AppContext from "contexts/AppContext";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import ls from "services/ls";
import Translator, { __ } from "services/Translator";

export default function LangFixer(props) {
  const match = useRouteMatch("/:lang");
  const history = useHistory();
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      var languages = response.data;
      var languageCodes = response.data.map((item) => item.code);
      if (match && match.params && match.params.lang) {
        var lang = match.params.lang;
        var langIndex = languageCodes.indexOf(lang);
        if (langIndex != -1) {
          Translator.setSelectedLanguage(languages[langIndex]);
          window.location.reload();
        } else {
          history.push("/");
        }
      }
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);
  return <></>;
}
