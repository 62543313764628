import React, { useState, useEffect } from "react";
import api from "services/api";
import { Link } from "react-router-dom";
import { __ } from "services/Translator";
import {
  Badge,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import moment from "moment";
import DOMPurify from "dompurify";

export default function Gifts(props) {
  const isAuthenticated = props.isAuthenticated;
  const [dates, setDates] = useState([]);

  const getResults = async () => {
    var response = await api.get("/get-christmas-gifts-list");
    if (response.status === 200) {
      setDates(response.data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const [scale, setScale] = useState(1);

  const fixWidth = () => {
    var containerWidth = document.querySelector(".container").offsetWidth;
    setScale(containerWidth / 1102);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const showDetails = (index) => {
    var data = dates[index];
    console.log(data);
    setSelectedDate(data);
  };

  useEffect(() => {
    getResults();
    fixWidth();
    window.addEventListener("resize", () => {
      fixWidth();
    });
  }, []);

  return (
    <>
      <section className="about">
        <div className="container" style={{ height: scale * 803 + 100 }}>
          <div
            className="gifts_main"
            style={{ transform: "scale(" + scale + ")" }}
          >
            <img
              src={require("assets/images/gifts/background.png").default}
              className="background"
            />
            {dates.map((date, index) => {
              return (
                <div
                  onClick={() => {
                    if (date.class == "today") {
                      showDetails(index);
                    }
                  }}
                  className={"bulb bulb-" + date.date + " bulb-" + date.class}
                  key={index}
                >
                  <div className="text">{date.date}</div>
                  {date.class == "today" && (
                    <>
                      <Link
                        onClick={() => {
                          showDetails(index);
                        }}
                        className="common_btn_v1"
                      >
                        {__("Open")}
                      </Link>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Modal size="lg" show={selectedDate}>
        <Modal.Body className="gift_details_modal">
          <div>
            <Link
              to="#"
              className="modal_close"
              onClick={() => {
                setSelectedDate(null);
              }}
            >
              <svg
                width="19"
                height="19"
                className="close-button__IconClose-sc-1o0pd70-1 bMKQZK"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>{__("close")}</title>
                <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
              </svg>
            </Link>
            <h3 className="title">{selectedDate?.title}</h3>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(selectedDate?.description),
              }}
            ></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
