import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { __ } from "services/Translator";
export default function About(props) {
  const params = useParams();

  const topic = params.topic;
  const [topicTitle, setTopicTitle] = useState("About");

  return (
    <section className="about">
      <div className="container-md">
        <div className="about_content">
          <div className="about_menu">
            <div className="select_box  d-block d-lg-none">
              <DropdownButton className="select_btn" title={topicTitle}>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("About");
                    }}
                    to="/about/index"
                  >
                    {__("About")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bonuses & Promotions");
                    }}
                    to="/about/bonuses-promotions"
                  >
                    {__("Bonuses & Promotions")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Privacy Policy");
                    }}
                    to="/about/privacy-policy"
                  >
                    {__("Privacy Policy")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("VIP Program");
                    }}
                    to="/about/vip-program"
                  >
                    {__("VIP Program")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bitcoin Gambling");
                    }}
                    to="/about/bitcoin-gambling"
                  >
                    {__("Bitcoin Gambling")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Complaints");
                    }}
                    to="/about/complaints"
                  >
                    {__("Complaints")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Responsible Gaming");
                    }}
                    to="/about/responsible-gaming"
                  >
                    {__("Responsible Gaming")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Gaming License");
                    }}
                    to="/about/gaming-license"
                  >
                    {__("Gaming License")}
                  </Link>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <ul className="d-none d-lg-block">
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("About");
                  }}
                  className={topic === "index" ? "active" : ""}
                  to="/about/index"
                >
                  {__("About")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bonuses & Promotions");
                  }}
                  className={topic === "bonuses-promotions" ? "active" : ""}
                  to="/about/bonuses-promotions"
                >
                  {__("Bonuses & Promotions")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Privacy Policy");
                  }}
                  className={topic === "privacy-policy" ? "active" : ""}
                  to="/about/privacy-policy"
                >
                  {__("Privacy Policy")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("VIP Program");
                  }}
                  className={topic === "vip-program" ? "active" : ""}
                  to="/about/vip-program"
                >
                  {__("VIP Program")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bitcoin Gambling");
                  }}
                  className={topic === "bitcoin-gambling" ? "active" : ""}
                  to="/about/bitcoin-gambling"
                >
                  {__("Bitcoin Gambling")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Complaints");
                  }}
                  className={topic === "complaints" ? "active" : ""}
                  to="/about/complaints"
                >
                  {__("Complaints")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Responsible Gaming");
                  }}
                  className={topic === "responsible-gaming" ? "active" : ""}
                  to="/about/responsible-gaming"
                >
                  {__("Responsible Gaming")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Gaming License");
                  }}
                  className={topic === "gaming-license" ? "active" : ""}
                  to="/about/gaming-license"
                >
                  {__("Gaming License")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="about_article">
            {topic === "index" && (
              <>
                <h1 className="dark_blue">
                  <strong>{__("The Premier Bitcoin Casino")}</strong>
                </h1>
                <div className="paragraph">
                  casino is the go-to solution for Bitcoin gaming for a
                  growing number of people all around the world, and that’s no
                  coincidence – we have the latest, most advanced Bitcoin games
                  available, an innovative online gaming platform, and a website
                  designed to be user-friendly and easy to use. We strive to
                  offer our players the best gaming experience, with numerous
                  games to play and&nbsp;win,&nbsp;consistent bonuses, and a
                  friendly staff oriented towards player satisfaction.
                </div>
                <h3 className="dark_blue">Our Philosophy</h3>
                <div className="paragraph">
                  Here at casino, privacy is one of our top priorities, and
                  it has been that way since we launched in early 2014. Use of
                  Bitcoins as the currency of choice allows our players to
                  deposit and claim their winnings in an anonymous yet secure
                  manner. Our casino is fully licensed and regulated in Curacao,
                  which ensures that the gambling stays in the games, and not in
                  the rules or practices. Last but not least, we believe that
                  there’s no second chance at a good first impression, that’s
                  why we welcome all our new player with an industry-exclusive
                  110% deposit bonus. It’s just our way of saying “Welcome to
                  the&nbsp;&nbsp;Family!”
                </div>
                <h3 className="dark_blue">Win More at casino</h3>
                <div className="paragraph">
                  Just because we already have one of the largest game
                  collections out there doesn’t mean we’re going to stop here:
                  our team is always working on expanding our offer, so you can
                  be sure you’ll never get bored at casino. More
                  games&nbsp;equals&nbsp;more fun, and more chances to win.
                </div>
                <div className="paragraph">
                  For our loyal&nbsp;players&nbsp;we have set up an exclusive
                  VIP Program, which comes with a lot of great benefits such as
                  higher bonuses or free chips. We’re not stopping here: a new
                  VIP Program with significantly more consistent prizes such as
                  gift cards, various gadgets, and an&nbsp;all-expenses
                  paid&nbsp;trip to the Maldives is in the works right now, so
                  make sure you keep an eye on our site and you might just score
                  one of these great prizes.
                </div>
                <h3 className="dark_blue">Connect With us Today!</h3>
                <div className="paragraph">
                  Your feedback is valuable for us, so whether you want to share
                  your experience at our casino, make a suggestion, or just see
                  how we’re doing, we encourage you to do so. You can reach us
                  on Facebook, Twitter, or directly through the handy inquiry
                  form at the bottom of our website.
                </div>
              </>
            )}
            {topic === "bonuses-promotions" && (
              <>
                <h1 className="dark_blue">
                  <strong>Bonuses &amp; Promotions</strong>
                </h1>
                <h4 className="dark_blue">First Deposit Bonus</h4>
                <div className="paragraph">
                  We are proud to offer a special promotion for new players. We
                  will make your initial deposit worth twice as much! With just
                  a few clicks or taps, you can quickly and easily claim your
                  exclusive bonus.
                </div>
                <div className="paragraph">
                  First step: Register at casino.com. This only takes a few
                  moments.
                </div>
                <div className="paragraph">
                  Second step: Deposit bitcoins into your newly created player
                  account.
                </div>
                <div className="paragraph">
                  Third step: There is no third step! An awesome 110% deposit
                  bonus is added to your account upon depositing – it’s that
                  simple to get a bonus at Casino!
                </div>
                <div className="paragraph">
                  Upon making your first deposit, we will load your player
                  account with a 110% bonus worth up to 1 BTC / 36 BCH / 60 ETH
                  / 160 LTC / 4,000,000 DOGE. It really couldn’t be any simpler,
                  and in the matter of a few minutes you can double your money
                  and your play time.
                </div>
                <div className="paragraph">
                  A hypothetical example of a first deposit bonus being put into
                  action is if, for instance, you initially make a deposit of 10
                  mBTC. Your account will be credited an additional 11 mBTC and
                  you will have 21 mBTC with which to play.
                </div>
                <div className="paragraph">
                  <em>
                    It is important to take note of the fact that your bonus
                    monies will not be made available for withdrawal until you
                    wager the bonus’ value 50 times over. This bonus expires
                    after a 30 day period. Read more about our bonuses terms and
                    playing requirements&nbsp;
                    <Link to="https://www.casino.com/terms-and-conditions/bonus-terms">
                      here
                    </Link>
                    .
                  </em>
                </div>
                <h4 className="dark_blue">Second Deposit Bonus</h4>
                <div className="paragraph">
                  Now that you are familiar with the structure of our bonuses,
                  we are going to award you even more free money!
                </div>
                <div className="paragraph">
                  Once you’re ready to make your second deposit, we’ll be ready
                  to add your second bonus. Redeeming this bonus is as easy as
                  following the next steps: First step: Make your second deposit
                  at Casino. Second step: Start playing with your newly
                  added 50% deposit bonus. Yes, it was already added.
                </div>
                <div className="paragraph">
                  The Second Deposit Bonus is a 50% bonus up to 2.5 BTC / 90 BCH
                  / 150 ETH / 400 LTC / 10,000,000 DOGE / 100 USDT. This means
                  that if you make a deposit totalling 100 mBTC, your bankroll
                  will be boosted by an additional 50 mBTC.
                </div>
                <div className="paragraph">
                  <em>
                    It is important to take note of the fact that your bonus
                    monies will not be made available for withdrawal until you
                    wager the bonus’ value 50 times over. This bonus expires
                    after a 30 day period. Read more about our bonuses terms and
                    playing requirements&nbsp;
                    <Link to="https://www.casino.com/terms-and-conditions/bonus-terms">
                      here
                    </Link>
                    .
                  </em>
                </div>
                <h4 className="dark_blue">Third Deposit Bonus</h4>
                <div className="paragraph">
                  They say third time’s a charm, so why not make a third deposit
                  and get yet another awesome bonus?
                </div>
                <div className="paragraph">
                  casino wants to reward your loyalty by offering you a
                  bonus on your third deposit as well. Getting this bonus is
                  just as simple – just make your third deposit and the bonus
                  will be automatically added to your account.
                </div>
                <div className="paragraph">
                  Your deposit will be 75% matched up to 1.5 BTC / 54 BCH / 90
                  ETH / 240 LTC / 6,000,000 DOGE / 100 USDT. This means that if
                  you deposit 100 mBTC, your player bankroll will be increased
                  to 175 mBTC.
                </div>
                <div className="paragraph">
                  Use our whole range of welcome bonuses and you will be
                  considered a regular at casino, and we want to reward your
                  loyalty! The more frequently you deposit and play your
                  favorite online casino games at Casino, the faster you
                  will qualify for our VIP Program. Once you’re qualified, we
                  will reach out to you. For more information on our VIP
                  Program, click here.
                </div>
                <div className="paragraph">
                  <em>
                    It is important to take note of the fact that your bonus
                    monies will not be made available for withdrawal until you
                    wager the bonus’ value 50 times over. This bonus expires
                    after a 30 day period. Read more about our bonuses terms and
                    playing requirements&nbsp;
                    <Link to="https://www.casino.com/terms-and-conditions/bonus-terms">
                      here
                    </Link>
                    .
                  </em>
                </div>
              </>
            )}
            {topic === "privacy-policy" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Privacy Policy</strong>
                </h1>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <em>Effective from: August 15th 2014</em>
                </div>
                <div className="paragraph">
                  <em>_Last updated: May 24th 2021_</em>
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>1. GENERAL</strong>
                </div>
                <div className="paragraph">
                  As a data controller, we have a legal obligation under
                  gambling regulations to process personal data from players in
                  order to allow them to participate in games and provide them
                  with ancillary services. This Privacy Policy explains what
                  personal data we collect from players, why we collect it and
                  how we utilise it.
                </div>
                <div className="paragraph">
                  &nbsp;The website www.casino.com (‘Casino’, ‘Website’,
                  ‘Company’, ‘We’, ‘Us’, ‘Our’) is owned and operated by Dama
                  N.V., a company registered and established under the laws of
                  Curacao. Dama N.V. is licensed and regulated by Antillephone
                  N.V. (license no. 8048/JAZ2020-013). Dama N.V.’s registration
                  number is 152125 and its registered address is Julianaplein
                  36, Willemstad, Curaçao. Strukin Ltd. is a subsidiary of Dama
                  N.V., acting as an Agent on behalf of Dama N.V., registered in
                  Cyprus with registered address Pavlov Nirvana &amp; Aipeias,
                  4, ALPHA TOWER, Floor 1, Flat 11, 3021, Limassol, Cyprus, and
                  registration number ΗΕ 407624. Dama N.V. is officially
                  registered by the Government of Curaçao as an Information
                  Provider legally authorised to conduct online gaming
                  operations from Curaçao. Remote gaming licensing and
                  supervision is provided by Antillephone N.V.
                </div>
                <div className="paragraph">
                  By registering a Player Account with the Website you confirm
                  your consent with this Privacy Policy. If you do not agree
                  with the terms of this Privacy Policy and do not wish to
                  provide us with the personal information we require, please do
                  not use this website.
                </div>
                <div className="paragraph">
                  Please note that this Privacy Policy constitutes an agreement
                  between you and the Company. We may periodically make
                  modifications to this Policy. While we will do our best to
                  notify you of such changes, we recommend that you revisit this
                  Privacy Policy regularly. Your continued use of the Website
                  and/or its services will constitute your consent to the
                  Privacy Policy.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>2. INFORMATION WE COLLECT</strong>
                </div>
                <div className="paragraph">
                  The Personal Information which we may request to use and
                  process shall include, without limitation:
                </div>
                <div className="paragraph">
                  a) Any of the information that you provide to us when filling
                  in the forms on our account registration pages, as well as any
                  other data that you further submit via the Website or email
                  (e.g. first and last name, date of birth, email address, phone
                  number);
                </div>
                <div className="paragraph">
                  b) Correspondence made with us via the Website, email, web
                  chat or through other means of communication;
                </div>
                <div className="paragraph">
                  c) All Player Account transaction history, whether this takes
                  place via the Website(s) or via other means of communication;
                </div>
                <div className="paragraph">
                  d) Website logins and their details, including traffic data,
                  GeoIP location data, browser/device data, weblogs, activity
                  logs and other traffic information recorded in our system;
                </div>
                <div className="paragraph">
                  e) Documents and proofs reasonably requested by us to verify
                  your account, to process deposits or withdrawals and to
                  conduct anti-fraud checks (on our own initiative or as
                  required by applicable legislation). Such proofs may include
                  passport scans, payment slips, bank statements, etc.
                </div>
                <div className="paragraph">
                  f) Survey participations or any other customer assessments
                  that we may carry out from time to time.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>3. HOW WE USE YOUR INFORMATION</strong>
                </div>
                <div className="paragraph">
                  We process the Personal Information we collect from you in
                  order to deliver our services. In particular, we will use your
                  data for the following purposes:
                </div>
                <div className="paragraph">
                  a) Processing your bets and transactions. This includes your
                  use of credit card and online payment systems;
                </div>
                <div className="paragraph">
                  b) Providing you with gaming and other ancillary services that
                  you seek from our Website;
                </div>
                <div className="paragraph">
                  c) Rendering customer support, such as assistance with setting
                  up and managing your account;
                </div>
                <div className="paragraph">
                  d) Identifying and performing the necessary verification
                  checks;
                </div>
                <div className="paragraph">
                  e) Providing registered players with information about our
                  promotional offers, or providing promotional information from
                  our selected business partners, associates and affiliates
                  (only if players specifically consented to receiving such
                  marketing material);
                </div>
                <div className="paragraph">
                  f) Complying with legal responsibilities, including complying
                  with anti-money laundering (AML) and combating the financing
                  of terrorism (CFT) laws;
                </div>
                <div className="paragraph">
                  g) Monitoring and investigating transactions for the purposes
                  of preventing fraud, terms abuse, money laundering and other
                  illegal or irregular gaming activities;
                </div>
                <div className="paragraph">
                  h) Analysing customer trends through market study assessments
                  (participation in surveys is not obligatory and you can always
                  choose not to take part);
                </div>
                <div className="paragraph">
                  i) Conducting research and statistical analysis of aggregated
                  data.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>4. MARKETING COMMUNICATION</strong>
                </div>
                <div className="paragraph">
                  Unless you have elected not to receive promotional materials,
                  we may use your Personal Information, including your email
                  address and phone number, to send you marketing communications
                  regarding products, services and promotions. This may include
                  information about products and services from our business
                  partners, such as casino game providers.
                </div>
                <div className="paragraph">
                  Whenever you decide to stop receiving such marketing and
                  advertising material, you may opt out of this in your Player
                  Account settings or by contacting our customer support at
                  support@casino.com.
                </div>
                <div className="paragraph">
                  Additionally, note that by accepting any contest prize or
                  winnings from us, you consent to the use of your name and/or
                  nickname for advertising and promotional purposes without
                  additional compensation, except where prohibited by law.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>5. OBTAINING PERSONAL INFORMATION</strong>
                </div>
                <div className="paragraph">
                  We shall not collect any Personal Information about you
                  without your knowledge. We may, however, automatically collect
                  certain data about you where you would have provided such
                  information through the use of our services and through your
                  interactions with us.
                </div>
                <div className="paragraph">
                  We may also lawfully receive certain Personal Information from
                  online vendors and service providers, such as fraud prevention
                  companies. In addition, we retain the right to engage the
                  services of third-party providers to render technical support,
                  so as to process your online transactions and source gaming
                  content.
                </div>
                <div className="paragraph">
                  Please understand that we may be granted access to any
                  information you may provide to such vendors, service providers
                  and third-party e-commerce services. Rest assured that we will
                  use and safeguard any Personal Information so obtained, as set
                  out in this Policy. Any information that you provide will only
                  be disclosed to third parties outside the Company in
                  accordance with this Privacy Policy, and we shall take any
                  necessary steps to ensure that our agreements with third-party
                  service providers always protect your private information.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>6. DATA RECIPIENTS</strong>
                </div>
                <div className="paragraph">
                  We may pass information that you have given us to other
                  entities within our group of companies and to our business
                  partners. These companies include our parent companies, their
                  parent companies and all of the subsidiaries of these
                  respective companies, as well as other companies with whom we
                  carry out business and hold necessary agreements. Data
                  processing of your information may be undertaken by the
                  Company or by another entity in our group of companies, which
                  may use a third party to fulfill such data processing needs.
                </div>
                <div className="paragraph">
                  Employees of the Company, more specifically Data Protection
                  Officer, Money Laundering Officer, Payments &amp; Anti-Fraud
                  analysts, Customer Support agents, Customer Retention team
                  members, VIP player managers as well as other selected
                  employees, shall also have access to your Personal Information
                  for the purpose of executing their duties and providing you
                  with assistance.
                </div>
                <div className="paragraph">
                  Our employees who have access to, or are associated with the
                  processing of the player’s personal information, have signed
                  confidentiality agreements to respect the confidential nature
                  of the player’s information pursuant to applicable gaming,
                  data protection and privacy laws.
                </div>
                <div className="paragraph">
                  In order to provide you with an efficient service, we and/or
                  our service providers might require transferring your personal
                  data from one country to another in the European Union (EU)
                  and European Free Trade Association (EFTA) regions and also to
                  some data processors that may be based outside of the European
                  Economic Area (EEA). Therefore, by browsing the Website and
                  communicating electronically with us, you acknowledge and
                  agree to our (or our suppliers or sub-contractors) processing
                  of your data in these countries. We shall always use our best
                  efforts to ensure that your information and data is treated
                  securely and in accordance with this Privacy Policy.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>7. RELEASING DATA TO THIRD PARTIES</strong>
                </div>
                <div className="paragraph">
                  We do not sell or rent your personal data to third parties.
                </div>
                <div className="paragraph">
                  We may disclose your personal information if required by law,
                  regulation, or other legal subpoena or warrant. We may also
                  disclose your personal information to a regulatory or law
                  enforcement agency if we believe it to be necessary to protect
                  the legitimate interests of the Company, its customers or any
                  third party.
                </div>
                <div className="paragraph">
                  Personal data will only be disclosed to third parties in the
                  following cases:
                </div>
                <div className="paragraph">
                  a) Where we are required to do so by law;
                </div>
                <div className="paragraph">
                  b) If the Website needs to share data with its payment
                  processors to facilitate payment transactions in accordance
                  with their privacy policies. (In particular, most banking card
                  transactions are processed by Paysafe whose
                  <Link
                    to="https://www.paysafe.com/na-en/paysafegroup/privacy-policy/"
                    rel="noopener"
                  >
                    privacy policy
                  </Link>
                  applies to such transactions);
                </div>
                <div className="paragraph">
                  c) To comply with our legal and regulatory duties and
                  responsibilities to the relevant licensing and regulatory
                  authorities as well as all duties and responsibilities owed
                  under any other applicable legislation and to any other
                  applicable regulators in other jurisdictions;
                </div>
                <div className="paragraph">
                  d) When the Company believes that disclosure is necessary to
                  protect the Company’s or the player’s safety, or the safety of
                  others, investigate fraud, or respond to a government request;
                </div>
                <div className="paragraph">
                  e) If our marketing service providers require the data to
                  carry out their tasks;
                </div>
                <div className="paragraph">
                  f) To any other third party with the player’s prior consent to
                  do so.
                </div>
                <div className="paragraph">
                  We use third-party data processors to process limited personal
                  data on our behalf. Such service providers support the
                  Website, especially relating to hosting and operating the
                  websites, marketing, analytics, improving the websites, and
                  sending email newsletters. We shall ensure that the transfer
                  of the Personal Data to the recipient is compliant with
                  applicable Data Protection Legislation and that the same
                  obligations are imposed on the processor as is imposed on us
                  under the respective Services Agreement.
                </div>
                <div className="paragraph">
                  Our websites may also include social media features (e.g.
                  “share” or “like” buttons). Such features are provided by
                  third-party social media platforms such as Facebook. Where
                  data is collected this way, its processing is governed by the
                  privacy policy of the respective social media platforms.
                </div>
                <div className="paragraph">
                  In addition to the above, we may also release personal data if
                  we acquire any new businesses. Should the Company undergo any
                  changes to its structure such as a merger, acquisition by
                  another company or a partial acquisition, it is most likely
                  that our customers’ personal data will be included within the
                  sale or transfer. We will, as part of our Policy, inform our
                  players by email prior to affecting such transfer of personal
                  data.
                </div>
                <div className="paragraph">
                  Please note our content may link to third party websites to
                  provide relevant references. We are not responsible for such
                  external content, which may contain separate privacy policies
                  and data processing disclosures.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>8. DATA RETENTION</strong>
                </div>
                <div className="paragraph">
                  As stated under our Terms and Conditions both, you and the
                  Casino can decide to have your Player Account closed at any
                  time. Following closure of your account, we will retain your
                  personal data on record for as long as required by law. This
                  data shall only be used should it be required by competent
                  authorities in cases of enquiries regarding financial and
                  fiscal records, fraud, money laundering or investigations into
                  any other illegal activity.
                </div>
                <div className="paragraph">
                  You are to note that due to anti-money laundering regulations
                  in licensed gaming jurisdictions in the European Union, we are
                  obliged to retain personal data of players submitted during
                  registration and any data passed on during the operative
                  period of a Player Account for a minimum of five years from
                  last player transaction or account closure. Therefore,
                  requests for erasure prior to the lapse of this period cannot
                  be entertained.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>9. SECURITY OF YOUR DATA</strong>
                </div>
                <div className="paragraph">
                  We hereby acknowledge that in collecting and processing your
                  Personal Information for the purposes of managing your Player
                  Account, we are bound by strict legal provisions on the
                  protection of personal data.
                </div>
                <div className="paragraph">
                  Consequently, we endeavour to protect your personal
                  information and respect your privacy in accordance with best
                  business practices and applicable regulations. Being committed
                  to providing secure services to players, and we will take all
                  reasonable precautions to ensure that all the data that you
                  have submitted to us remains safe.
                </div>
                <div className="paragraph">
                  Player Accounts can only be accessed with the player’s unique
                  ID and password. You may also set up two-factor authentication
                  (2FA) as additional protection from unauthorized use of your
                  account. You are responsible for keeping your login
                  information confidential and making sure it cannot be accessed
                  by another person.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>10. CONTACTING US</strong>
                </div>
                <div className="paragraph">
                  You may always contact us in regards to this Policy should you
                  wish to:
                </div>
                <div className="paragraph">
                  a) Confirm the accuracy of the personal information we have
                  collected about you;
                </div>
                <div className="paragraph">
                  b) Enquire about our use of your personal information;
                </div>
                <div className="paragraph">
                  c) Prohibit future use of your data for direct marketing
                  purposes;
                </div>
                <div className="paragraph">
                  d) Update or rectify any information that you have provided us
                  (in such cases you shall provide any evidence we may
                  reasonably require to effect such changes). Note it is illegal
                  to provide us with false information about you and it is your
                  responsibility to ensure that we are always updated with your
                  correct data.
                </div>
                <div className="paragraph">
                  If you are located in the EYE, you can also contact our
                  representative in the EU about any data protection queries at
                  [office@liftadvisory.com.mt](mailto:office@liftadvisory.com.mt)
                  or using the postal address below. The EEA representative of
                  Dama N.V. acting as an Agent on behalf of Dama N.V.,
                  registered in Cyprus with registered address Pavlov Nirvana
                  &amp; Aipeias, 4, ALPHA TOWER, Floor 1, Flat 11, 3021,
                  Limassol, Cyprus.
                </div>
                <div className="paragraph">
                  We have appointed a data protection officer (“DPO”) who is
                  responsible for overseeing questions in relation to this
                  privacy policy.
                </div>
                <div className="paragraph">
                  In addition, as per Article 77 of the GDPR, you have the right
                  to lodge a complaint related to your data processing to a
                  supervisory authority, in particular in the Member State of
                  your habitual residence, place of work or place of an alleged
                  infringement.
                </div>
                <div className="paragraph"> </div>
                <div className="paragraph">
                  <strong>11. COOKIE POLICY</strong>
                </div>
                <div className="paragraph">
                  When you visit the Website, our system automatically collects
                  information about your visit, such as your browser, IP
                  address, and the referring website. This collection may be
                  done in conjunction with our platform providers and partners.
                  We may receive from them general demographic or usage data of
                  our Website visitors. We do not use automatically collected
                  information to identify you personally without receiving
                  additional consent.
                </div>
                <div className="paragraph">
                  To collect the information in question we use cookies and
                  similar tracking tools. Cookies are small text files that are
                  stored on your computer or equipment when you visit our web
                  pages. Some of the cookies are essential for the Website to
                  operate; others improve your Website experience and help us
                  deliver a better service. Below are the types of cookies we
                  use and their purposes.
                </div>
                <div className="paragraph">
                  Required cookies: enable the navigation and basic
                  functionality of the websites, e.g., access to member areas of
                  the Website.
                </div>
                <div className="paragraph">
                  Functional cookies: allow us to analyse your website usage and
                  your selections on the website (e.g. your session key,
                  language, or region), so we can save these settings and offer
                  you a more personalised experience.
                </div>
                <div className="paragraph">
                  Advertising cookies: allow us to gauge how effective our
                  content marketing is. These cookies are provided by our
                  partners to track website visits and new player registrations
                  from advertising. We do not share your personal information
                  (such as name or email) to affiliated partners except for site
                  visit data collected directly by such Advertising Cookies.
                  However your site visit data may be linked with other personal
                  information collected through other sources by the providers.
                  The latter external data processing is governed by the privacy
                  notices and policies of these third-party providers.
                </div>
                <div className="paragraph">
                  In addition to the above, we use a number of third party
                  service providers who also set cookies on this Website, in
                  order to deliver the services that they are providing to us.
                  Such services include, but are not limited to, helping us to
                  improve your experience by tracking your activity on the
                  Website, measuring the effectiveness of the Website and the
                  effectiveness of our marketing campaigns.
                </div>
                <div className="paragraph">
                  Most online browsers automatically accept cookies. If you
                  prefer, it is possible to block some or all cookies, or to
                  delete cookies that have already been set by modifying your
                  browser settings. However, we recommend that you do not block
                  or delete your cookies as this may restrict your use of our
                  Website.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>12. NETENT GAMES</strong>
                </div>
                <div className="paragraph">
                  &nbsp;When you play casino games developed by NetEnt, NetEnt’s
                  Privacy Policy also applies. This policy can be found
                  <Link
                    rel="nofollow"
                    to="https://www.netent.com/en/netent-privacy-policy-eu/"
                  >
                    here
                  </Link>
                  .
                </div>
              </div>
            )}

            {topic === "vip-program" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Are you Ready to Become a VIP?</strong>
                </h1>
                <div className="paragraph">
                  Whether you play high limits, you play a lot, or both, you can
                  be eligible for unlimited VIP rewards from casino. It
                  doesn't matter whether you win a huge jackpot, lose, or win a
                  small amount – you can still be eligible for rewards.
                </div>
                <div className="paragraph">
                  Our VIP hosts will reach out to you with offers and rewards
                  the moment you become a VIP in our casino. Rewards in the 
                  VIP Program include, but are not limited to, gift cards,
                  computers, electronics, vacations, and even cash.
                </div>
                <div className="paragraph">
                  You do not need to enroll; simply play at casino and look
                  forward to an email from our one of our VIP hostesses the
                  moment you have qualified. Have questions about the  VIP
                  Program? Email&nbsp;
                  <Link to="mailto:vip@casino.com.com">
                    vip@casino.com
                  </Link>
                </div>
              </div>
            )}
            {topic === "bitcoin-gambling" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Bitcoin Gambling</strong>
                </h1>
                <div className="paragraph">
                  Playing casino games for real money online is something that
                  has existed almost as long as the internet itself. Since its
                  inception, this industry has been in an almost constant state
                  of flux, with new technology, software, and sites changing the
                  landscape routinely.
                </div>
                <div className="paragraph">
                  In more recent years, Bitcoin and online casinos have begun to
                  join forces to create an entirely new sector of online
                  gambling—one that does away with traditional fiat currencies
                  entirely. This Bitcoin gambling development has offered
                  players something new not in the forms of games or graphics,
                  but in the way players fund accounts and withdraw winnings.
                  Like any technological advance, gambling with Bitcoins has
                  made the entire gaming experience much more seamless, quick,
                  and safe. While traditional methods of deposit are fine and
                  good, you will see quickly just how useful Bitcoins can be.
                </div>
                <h3 className="dark_blue">Bitcoin Gambling Explained</h3>
                <div className="paragraph">
                  Being that Bitcoins are somewhat new and foreign to most
                  people, the first thought on the minds of many is with regard
                  to the difficulty associated with learning how to use a system
                  of currency that is unlike anything they are accustomed to.
                  While this is a legitimate concern, the fact of the matter is
                  that Bitcoin gambling does not differ entirely too much from
                  the online gaming that has been around for well over a decade
                  now.
                </div>
                <div className="paragraph">
                  The biggest difference between Bitcoin gambling and gambling
                  online as it has been done for years is the speed at which
                  payments are processed. Because Bitcoins exist within a
                  peer-to-peer network where transfers happen between two people
                  rather than a person and a financial institution, the moving
                  of funds is more comparable to a cash transaction rather than
                  any transaction dealing with credit cards, debit cards, or
                  bank accounts. Unlike cash transactions, however, Bitcoin
                  payments are safe and verifiable.
                </div>
                <div className="paragraph">
                  Another great thing is that because you are not dealing with a
                  bank or any major financial company, the fees associated with
                  withdrawals and deposits are some of the lowest in the
                  industry. In addition to that, Bitcoin transactions are
                  encrypted such that you leave no paper trail. People can view
                  every Bitcoin transaction that occurs, but the names of those
                  involved in said transactions are never disclosed. This means,
                  in so many words, that Bitcoin gambling can afford you the
                  opportunity to play casino games for real money without ever
                  having to disclose any personal information.
                </div>
                <h3 className="dark_blue">
                  Depositing and Withdrawing at a Bitcoin Casino
                </h3>
                <div className="paragraph">
                  Bitcoins are so convenient in the realm of online casinos that
                  they allow you sign-up, deposit, and begin playing faster than
                  any other currency or method of deposit. Once you sign up at a
                  site that offers Bitcoin gambling, all you need to do is copy
                  the site’s Bitcoin address and utilize that to send whatever
                  amount of money you choose from your own Bitcoin wallet to
                  your newly created player account.
                </div>
                <div className="paragraph">
                  Withdrawing Bitcoins works in the exact same way. Once you
                  know how much cash you would like, you simply copy your
                  Bitcoin wallet’s address and in moments you will receive your
                  winnings.
                </div>
                <h3 className="dark_blue">Where Can I Gamble with Bitcoins?</h3>
                <div className="paragraph">
                  Now that you know a little bit more about how Bitcoin gambling
                  works and functions within the existent online casino
                  industry, you are likely wondering where you can give it a
                  try. Not only that, but as someone who is new to this method
                  of online casino play, you also want to know that you can
                  gamble at a site that is safe, reliable, and will offer a
                  great online casino experience. casino has all of that and
                  more and provides you with a great platform for playing your
                  favorite casino games online and for real money.
                </div>
                <div className="paragraph">
                  We have put in the time and effort to ensure that our offering
                  is amongst the best in the industry. This means that our
                  selection of games is expansive, the software that powers them
                  is the best available, and security is top-notch. With ample
                  experience hosting online casino games, Bitcoin gambling at
                   is comparable to the best fiat currency casinos on the
                  web today.
                </div>
                <div className="paragraph">
                   boasts a selection of games that includes all of the most
                  popular games as well as a selection of games that you may
                  have never seen before. From blackjack, to roulette, and even
                  poker— has it all. Live gaming is something to check out
                  too. Our live dealers are friendly, helpful, and professional,
                  and the gameplay experience is smooth and glitch-free. When it
                  comes down to it, Bitcoin gambling (and online gambling in
                  general) does not get much better than what you will
                  experience at .
                </div>
                <div className="paragraph">
                  Don’t just take our word for it, sign-up and try out some of
                  our games. Signing up for a new player account is something
                  that takes only a matter of minutes which means that you can
                  begin gambling with Bitcoins in no time at all. What’s more,
                  our offering of bonuses for new players and returning players
                  alike will rival anything you can find elsewhere on the web.
                </div>
              </div>
            )}

            {topic === "complaints" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Complaints</strong>
                </h1>
                <div className="paragraph">
                  When gambling at any online casino, you might experience some
                  issues. If that happens you are entitled to make a complaint
                  with official government regulatory bodies which issue
                  licences or an independent regulatory bodies, such as&nbsp;
                  <Link
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    to="http://www.askgamblers.com/casino-complaints/"
                  >
                    AskGamblers Casino Complaint Service
                  </Link>
                  .
                </div>
                <div className="paragraph">
                  If you are experiencing any issues with Casino Casino
                  regarding Payment, Bonuses, Software, Deposit, Accounts or
                  other, you are welcome to file a complaint with the&nbsp;
                  <Link
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    to="http://www.askgamblers.com/casino-complaints/"
                  >
                    AskGamblers Casino Complaint Service
                  </Link>
                  .
                </div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/738bb3782aa0cb44ad43b5df73f3cb3eb32b9424_askgamblers-certificate-of-trust.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  Latest Casino Bonuses has one of the largest casino forum
                  communities with over 80,000 members and 297 casino
                  representatives on board. If you experience any issues then
                  feel free to contact the reps directly, they will try resolve
                  your query or complaint fast and efficiently. If you need to
                  talk directly with a casino rep for casino please
                  visit&nbsp;
                  <Link
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    to="https://latestcasinobonuses.com/onlinecasinobonusforum/direct-casino-support/-casino-casino-com-support-and-signup-bonus-info/"
                  >
                    LCB's direct casino support
                  </Link>
                  &nbsp;thread.
                </div>
              </div>
            )}

            {topic === "responsible-gaming" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Responsible Gaming</strong>
                </h1>
                <div className="paragraph">
                  At casino we offer a platform and services that enable our
                  clients to enjoy gaming and betting. Our aim is to provide you
                  with a safe, secure, regulated environment which will give you
                  a pleasant and rewarding experience. We encourage our clients
                  to use our services responsibly and in a reasonable manner.
                </div>
                <div className="paragraph">
                  Gambling is a popular form of entertainment and has been for
                  many years. It is supposed to be fun and the majority of
                  people gamble responsibly, within their means and for the
                  right reasons. However, it is unfortunately possible to become
                  dependent on gaming and gambling activities. It is important
                  to be able to recognize the signs that may suggest you have a
                  gambling problem. If you are spending too much time gambling,
                  spending money you need for other purposes, or you find that
                  your life is negatively affected by your gambling, then you
                  should take action.
                </div>
                <div className="paragraph">
                  casino takes responsible gambling very seriously and if
                  you have any concerns about your gambling habits then we urge
                  you to seek advice from accredited organizations that can
                  help, or contact us to take advantage of the many options we
                  offer our customers to limit their gambling, gaming or
                  betting.
                </div>
                <div className="paragraph">
                  On request, we will close any account for a minimum period of
                  6 months during which time it will not be possible for the
                  account to be re-opened for any reason. You can close your
                  account under our responsible gaming self-exclusion policy at
                  any time by contacting our Customer Support team or by
                  emailing support@casino.com with the username or
                  registered email details of the account you wish to close.
                  Upon expiry of the self-exclusion period you may request to
                  re-open a closed account by contacting our Customer Support
                  team or by emailing support@casino.com with the details of
                  the account you wish to re-open. All requests to re-open a
                  closed account will be reviewed by the Operator. Accounts
                  closed as part of our self-exclusion policy cannot be
                  re-opened for any reason until the self-exclusion time period
                  has expired.
                </div>
                <div className="paragraph">
                  If you have requested us to close your account indefinitively
                  your account cannot be opened for any reason whatsoever. We
                  will use all reasonable endeavors to ensure compliance with
                  our responsible gaming self-exclusion policy. However, you
                  accept that we have no responsibility or liability whatsoever
                  if you continue to deposit and wager using additional not
                  previously disclosed accounts or if you open up a new account
                  with substantially the same personal registration information,
                  albeit inputted into the registration form in a different way.
                  Upon self-exclusion all future wagers, Bonus funds and entries
                  in any promotions will be forfeited. We will not be able to
                  reinstate these if the account is reopened after the
                  self-exclusion period. All remaining balances less any active
                  bonuses will be transferred to your credit card, transferred
                  to your bank account, or any available payment method at
                  casino’s discretion.
                </div>
                <div className="paragraph">
                  We want you to enjoy our services without negative
                  connotations and offer the following advice for avoiding
                  undesirables consequences in your gaming and betting:
                </div>
                <ul>
                  <li>
                    Treat your betting and gaming as a form of entertainment and
                    use money that you have set aside for your entertainment
                  </li>
                  <li>
                    Do not bet or wager amounts larger than you are comfortable
                    with
                  </li>
                  <li>
                    Do not bet or wager with money you cannot afford to lose
                  </li>
                  <li>Do not chase your losses</li>
                  <li>
                    Do not let your betting and/or gaming take up time that you
                    would usually spend on other activities
                  </li>
                </ul>
              </div>
            )}
            {topic === "gaming-license" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Gaming License</strong>
                </h1>
                <div className="paragraph">
                  <Link to="https://www.casino.com">casino.com</Link> is
                  owned and operated by Dama N.V., a company registered and
                  established under the laws of Curacao. Dama N.V. is licensed
                  and regulated by Antillephone N.V. (license no.
                  8048/JAZ2020-013). Dama N.V.’s registration number is 152125
                  and its registered address is Julianaplein 36, Willemstad,
                  Curaçao. Strukin Ltd. is a subsidiary of Dama N.V., acting as
                  an Agent on behalf of Dama N.V., registered in Cyprus with
                  registered address Pavlov Nirvana &amp; Aipeias, 4, ALPHA
                  TOWER, Floor 1, Flat 11, 3021, Limassol, Cyprus, and
                  registration number ΗΕ 407624. It is the player’s sole
                  responsibility to inquire about the existing laws and
                  regulations of the given jurisdiction for online gambling. If
                  you have any questions, please contact us at
                  <Link to="mailto:support@casino.com">
                    support@casino.com
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
