import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import CategoryGames from "elements/games_elements/CategoryGames";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Modal, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import VipLevelText from "elements/VipLevelText";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import { __ } from "services/Translator";
import SearchBox from "elements/SearchBox";
import jQuery from "jquery";

export default function GamePlay(props) {
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);

  const getGameList = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 12,
      game_types: ["popular"],
      page: 1,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  // const __ = (text) => text;

  useEffect(() => {
    getGameList();
  }, [gameSorting, props.selectedProviders]);

  const params = useParams();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const [launchUrl, setLaunchUrl] = useState("");
  const [launchOptions, setLaunchOptions] = useState({});
  const [gameDetails, setGameDetails] = useState("");
  const gamePlayMode = params.mode && params.mode === "demo" ? "demo" : "real";
  const [gameSessionId, setGameSessionId] = useState(null);

  const setGamePlayMode = (mode) => {
    if (mode === "demo") {
      history.replace("/play/" + params.slug + "/demo");
    } else {
      history.replace("/play/" + params.slug);
    }
  };

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });

    if (response.status === 200 && response.data) {
      if (response.data.launchUrl) {
        setLaunchUrl(response.data.launchUrl);
      } else if (response.data.launchOptions) {
        setLaunchUrl("");
        setTimeout(() => {
          gameLaunch(response.data.launchOptions);
        }, 1000);
      }
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
      setIsFavorite(response.data.is_favorite_game);
    } else {
      setGameDetails(response?.data?.details);
      props.showAlert("Warning!!", response.data.message, true, () => {
        // history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  useEffect(() => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  const [bonusUnsupportedWarningModal, setBonusUnsupportedWarningModal] = useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails?.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails?.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: "Warning!!",
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection

  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;

  const calclateMobileIframeStyle = () => {
    window.scrollTo(0, 1);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const [topWinners, setTopWinners] = useState([]);

  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners?limit=4");
    if (response.status === 200) {
      setTopWinners(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  useEffect(() => {
    getTopWinners();
  }, []);
  const [theaterMode, setTheaterMode] = useState(false);

  const [isFavorite, setIsFavorite] = useState(gameDetails?.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", {
      gameId: gameDetails?.id,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  const [searcbar, setSearcbar] = useState(false);

  const gameLaunch = (launchOptions) => {
    jQuery("div#game_play_window").html("");
    var gameLaunchOptions = { target_element: "game_play_window" };
    // launch_options that you've received in start demo or create session response.
    gameLaunchOptions["launch_options"] = launchOptions;
    var success = function (gameDispatcher) {
      console.log("gameDispatcher", gameDispatcher);
    };
    var error = function (error) {
      console.log("errorerror", error);
    };

    window.sg.launch(gameLaunchOptions, success, error);
  };

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      {!isMobileDeviceByViewPort && (
        <>
          <div className={"game_play " + (theaterMode ? "theaterModeEnabled" : "")}>
            <div className="container-md">
              <div className="gameplay_v1">
                <div className="gameplay_frame">
                  <div className="game_content">
                    {launchUrl !== "" ? (
                      <iframe
                        style={{ display: "block !important;" }}
                        id="game_play_window"
                        width="100%"
                        height="100%"
                        src={launchUrl}
                      ></iframe>
                    ) : (
                      <div
                        style={{
                          display: "block !important",
                          width: "100%",
                          height: "650px",
                        }}
                        id="game_play_window"
                      ></div>
                    )}
                  </div>
                  <div className="frame_bottom">
                    <div className="play_mode_switch">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitches"
                          defaultChecked={gamePlayMode == "real" ? true : false}
                          onChange={(ev) => {
                            var target = ev.target;
                            if (target.checked) {
                              setGamePlayMode("real");
                            } else {
                              setGamePlayMode("demo");
                            }
                          }}
                        />
                        <label class="custom-control-label" for="customSwitches">
                          {__("Real Play")}
                        </label>
                      </div>
                    </div>
                    <div className="game_data">
                      <div className="left_part">
                        <div className="title">{gameDetails?.game_name} </div>
                        <div className="provider"> {gameDetails?.provider?.name ?? ""}</div>
                      </div>
                      <div className="right_part">
                        <div className="title">
                          {isAuthenticated && (
                            <i onClick={toggleFavoriteGame} className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="game_action">
                      {searcbar && <SearchBox {...props} />}

                      <Button
                        onClick={() => {
                          if (searcbar) {
                            setSearcbar(false);
                          } else {
                            setSearcbar(true);
                          }
                        }}
                        className="action_btn"
                      >
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.43026 0.401901C11.4786 0.401901 14.7721 3.69543 14.7721 7.74377C14.7721 9.14039 14.38 10.4471 13.7003 11.5598C17.5535 14.5883 17.9792 15.0139 18.1227 15.1574L19.1937 16.2284C20.0977 17.1324 20.0977 18.6033 19.1937 19.5073C18.7417 19.9592 18.148 20.1852 17.5543 20.1852C16.9606 20.1852 16.3669 19.9592 15.9149 19.5073L14.8439 18.4363C14.7012 18.2935 14.2745 17.8667 11.2639 14.0028C10.1473 14.6892 8.83429 15.0856 7.43026 15.0856C6.32713 15.0856 5.26627 14.8468 4.27717 14.3758C3.8918 14.1923 3.72819 13.7311 3.9117 13.3457C4.09525 12.9604 4.55643 12.7966 4.94172 12.9803C5.72162 13.3517 6.55886 13.54 7.43026 13.54C10.6263 13.54 13.2265 10.9398 13.2265 7.74377C13.2265 4.54774 10.6263 1.94756 7.43026 1.94756C4.23424 1.94756 1.63406 4.54774 1.63406 7.74377C1.63406 8.79061 1.91587 9.81615 2.44909 10.7095C2.66783 11.076 2.54805 11.5505 2.18153 11.7692C1.81498 11.988 1.34058 11.8682 1.12183 11.5017C0.445726 10.3689 0.0883713 9.06945 0.0883713 7.74377C0.0884113 3.69543 3.38197 0.401901 7.43026 0.401901ZM15.9368 17.3433L17.0078 18.4143C17.3091 18.7157 17.7994 18.7157 18.1008 18.4143C18.4021 18.113 18.4021 17.6227 18.1008 17.3214L17.0298 16.2504C16.8383 16.0589 16.0236 15.352 12.7607 12.7872C12.6724 12.8805 12.5818 12.9716 12.4888 13.0601C15.0452 16.3409 15.747 17.1535 15.9368 17.3433ZM3.52946 6.05306C3.35955 6.44461 3.53923 6.89973 3.93079 7.06967C4.03095 7.11315 4.13535 7.1337 4.23802 7.1337C4.53664 7.1337 4.82093 6.95966 4.94736 6.66831C5.3769 5.67847 6.35147 5.03887 7.43026 5.03887C7.85709 5.03887 8.20309 4.69288 8.20309 4.26605C8.20309 3.83921 7.85709 3.49322 7.43026 3.49322C5.73538 3.49322 4.20421 4.49801 3.52946 6.05306Z"
                            fill="#10294F"
                          />
                        </svg>
                      </Button>
                      <Button onClick={toggleFullScreen} className="action_btn">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M19.6875 6.41666C20.2708 6.41666 20.7083 5.97916 20.7083 5.39583V1.3125C20.7083 1.16666 20.7083 1.02083 20.5625 0.874998C20.4166 0.729164 20.2708 0.437497 19.9791 0.291664C19.9791 0.291664 19.8333 0.291664 19.6875 0.291664H15.6041C15.0208 0.291664 14.5833 0.729164 14.5833 1.3125C14.5833 1.89583 15.0208 2.33333 15.6041 2.33333H17.2083L10.5 9.04166L3.79163 2.33333H5.39579C5.97913 2.33333 6.41663 1.89583 6.41663 1.3125C6.41663 0.729164 5.97913 0.291664 5.39579 0.291664H1.31246C1.16663 0.291664 1.02079 0.291664 0.874959 0.437497C0.729126 0.437497 0.437459 0.729164 0.437459 0.874998C0.291626 1.02083 0.291626 1.16666 0.291626 1.3125V5.39583C0.291626 5.97916 0.729126 6.41666 1.31246 6.41666C1.89579 6.41666 2.33329 5.97916 2.33329 5.39583V3.79166L9.04163 10.5L2.33329 17.2083V15.6042C2.33329 15.0208 1.89579 14.5833 1.31246 14.5833C0.729126 14.5833 0.291626 15.0208 0.291626 15.6042V19.6875C0.291626 19.8333 0.291626 19.9792 0.437459 20.125C0.583293 20.2708 0.729126 20.5625 1.02079 20.7083H1.31246H5.39579C5.97913 20.7083 6.41663 20.2708 6.41663 19.6875C6.41663 19.1042 5.97913 18.6667 5.39579 18.6667H3.79163L10.5 11.9583L17.2083 18.6667H15.6041C15.0208 18.6667 14.5833 19.1042 14.5833 19.6875C14.5833 20.2708 15.0208 20.7083 15.6041 20.7083H19.6875C19.8333 20.7083 19.9791 20.7083 20.125 20.5625C20.2708 20.4167 20.5625 20.2708 20.7083 19.9792C20.8541 19.8333 20.8541 19.6875 20.8541 19.5417V15.4583C20.8541 14.875 20.4166 14.4375 19.8333 14.4375C19.25 14.4375 18.8125 14.875 18.8125 15.4583V17.0625L11.9583 10.5L18.6666 3.79166V5.39583C18.6666 5.97916 19.1041 6.41666 19.6875 6.41666Z"
                            fill="#091655"
                          />
                        </svg>
                      </Button>
                      <Button
                        onClick={() => {
                          if (theaterMode) {
                            setTheaterMode(false);
                          } else {
                            setTheaterMode(true);
                          }
                        }}
                        className="action_btn"
                      >
                        <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.17489 8.85449L23.8483 8.85454L19.991 12.7119C19.7392 12.9637 19.5977 13.3053 19.5977 13.6614C19.5977 14.0176 19.7392 14.3592 19.991 14.611C20.2429 14.8629 20.5844 15.0044 20.9406 15.0044C21.2968 15.0044 21.6383 14.8629 21.8902 14.611L27.6786 8.82263C28.4142 8.08699 28.8902 7.44444 28.0398 6.56223L21.8901 0.412558C21.6377 0.163667 21.297 0.0247016 20.9425 0.025966C20.588 0.0272311 20.2484 0.168623 19.9977 0.419309C19.747 0.669994 19.6056 1.00963 19.6043 1.36415C19.6031 1.71868 19.742 2.05931 19.9909 2.31178L23.8482 6.1691L5.17489 6.16867L9.03221 2.31135C9.28406 2.0595 9.42554 1.71792 9.42554 1.36176C9.42554 1.00559 9.28406 0.664017 9.03221 0.412171C8.78036 0.160325 8.43879 0.0188383 8.08262 0.0188383C7.72646 0.0188383 7.38488 0.160325 7.13304 0.412171C5.09499 2.45022 2.936 4.50441 0.95929 6.5865C0.404377 7.17109 0.444593 7.88929 0.98341 8.46097L7.13308 14.6106C7.38493 14.8625 7.72651 15.004 8.08267 15.004C8.43884 15.004 8.78041 14.8625 9.03226 14.6106C9.28411 14.3588 9.42559 14.0172 9.42559 13.6611C9.42559 13.3049 9.28411 12.9633 9.03226 12.7115L5.17489 8.85449Z"
                            fill="#091655"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {gameDetails?.game_seo_content?.content && (
                <div className="provider_text_area game_page">
                  <div className="single_area">
                    {/* <h2 className="provider_heading">
                      {gameDetails?.game_name}
                    </h2> */}
                    <p className="provider_text">{renderHTML(gameDetails?.game_seo_content?.content)}</p>
                  </div>
                </div>
              )}
              <SingleCategoryGames categoryName="Popular" category="popular" perPage="6" />
            </div>
          </div>
        </>
      )}

      {isMobileDeviceByViewPort && (
        <>
          {launchUrl !== "" ? (
            <iframe className="mobile_game_frame" onLoad={iframeSrcChanged} src={launchUrl} />
          ) : (
            <div
              style={{
                display: "block !important",
              }}
              className="mobile_game_frame"
              id="game_play_window"
            ></div>
          )}
        </>
      )}

      <Modal className="gamePlayMsgModal" show={gameHubMessageModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i class="fas fa-exclamation-triangle"></i>
            <br />
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round" ? (
              <>
                {__(
                  "You have exceeded the maximum bet amount for cash money. Please lower the amount and try again. See our"
                )}
                <Link to="">{__("General Terms & Conditions")}</Link> {__("for more information.")}"
              </>
            ) : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round" ? (
              <>
                {__(
                  "You have exceeded the maximum bet amount for bonus money. Please lower the amount and try again. See our"
                )}
                <Link to="">{__("Bonus Terms")}</Link> {__("for more information.")}
              </>
            ) : gameHubMessageModal.message_type == "bonus_balance_not_support" ? (
              <>
                {__(
                  "Bonus play is not supported for this game. You do not have sfficient cash balance to play this game. See our"
                )}
                <Link to="">{__("Bonus Terms")}</Link>{" "}
                {__(
                  "for more information. Note: For Live Casino games and Sports Betting, Only cash wagers are accepted (no bonus wagers)"
                )}
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal className="gamePlayMsgModal" show={bonusUnsupportedWarningModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__("This game is not available for bonus money. Only cash money is accepted.")}
            <br />
            {__("Note: All Live Casino games are not available for bonus money wagering.")}
            <br />
            <Link to="/bonus">{__("Click here")}</Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
