import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { __ } from "services/Translator";
import moneyFormatter from "services/moneyFormatter";

import FlipNumbers from "react-flip-numbers";
import { randomInt } from "services/maths";

const JackpotTicker = (props) => {
  const [showWalletModal, setShowWalletModal] = useState(false);

  const [flipNumber, setFlipNumber] = useState(
    props.jackpotAmount ? props.jackpotAmount : props.totalJackpot
  );

  useEffect(() => {
    setFlipNumber(
      props.jackpotAmount ? props.jackpotAmount : props.totalJackpot
    );
  }, [props.totalJackpotReLoaded]);

  useEffect(() => {
    // console.log("flipNumberflipNumber", props.jackpotAmount);

    var flipTimeout = setTimeout(function () {
      setFlipNumber(flipNumber + randomInt(1, 4));
    }, randomInt(1000, 2000));

    return () => {
      window.clearTimeout(flipTimeout);
    };
  }, [flipNumber]);
  return (
    <>
      {!isNaN(flipNumber) && (
        <div
          className={
            "jactpor_ticker_area " + (props.jackpotAmount ? "gameTicker" : "")
          }
        >
          <FlipNumbers
            play
            color="#fff"
            background="#02346F"
            width={props.width ? props.width : 24}
            height={props.height ? props.height : 36}
            numbers={String(
              moneyFormatter.numberFormat(Math.round(flipNumber))
            )}
          />
        </div>
      )}
    </>
  );
};

export default JackpotTicker;
